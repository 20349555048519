import React from 'react';
import {
    MDBBtn,
    MDBTableHead,
    MDBTable,
    MDBTableBody,
    MDBModal,
    MDBChip,
    MDBModalBody,
    MDBModalHeader,
    MDBModalFooter, MDBIcon, MDBRow, MDBSpinner
} from "mdbreact";
import moment from "moment";
import './measure.css'
const Typesense = require('typesense');
const TYPESENSE_CONFIG = {
    nodes: [
        {
            host: process.env.REACT_APP_TYPESENSE_HOST,
            port: "443",
            protocol: "https",
        },
    ],
    apiKey: process.env.REACT_APP_TYPESENSE_ADMIN_API_KEY,
};
const typesense = new Typesense.Client(TYPESENSE_CONFIG);

class Measurement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            last: null,
            showModal: false,
            selectedRow: {},
            reloader: false,
            sortType: 'desc',
            orderType: '',
            notMore: false,
            measurementPerPage: 12,
            userType: "",
            measurements: [],
            searchwordA: '',
            algoriaPage: 1,
            algolia: false,
            orgList: [],
            showMenu: null,
            showMenuIndex: null,
        }
    }
    modalToggle = (row = {}) => {
        this.setState({
            selectedRow:row,
            showModal:!this.state.showModal
        })
    }

    componentDidMount() {
        this.getMeasurement(this.props,true);
        window.addEventListener('scroll', event => this.watchCurrentPosition(), true);
        //document.addEventListener("click",this.handleClickOutside,false);
    }
    handleClickOutside = event => {
        this.setState({showMenu: null,showMenuIndex: null});
    };
    componentWillReceiveProps(nextProps, nextContext) {
        // console.log('nextProps', nextProps);
        this.setState({
            last: null,
            measurements: [],
            notMore: false,
            searchwordA: '',
            algoriaPage: 1,
            algolia: false,
            sortType: 'desc',
            orderType: '',
        }, () => {
            this.getMeasurement(nextProps,true);
        })
    }

    /*const componentWillReceiveProps1 = ()=> {
        // if (nextProps.org) {
        //   console.log('estimates', 'componentWillReceiveProps')
        setLast(null);
        setMeasurements([]);
        getMeasurement(props, true);
    }*/
    componentWillUnmount() {
        window.removeEventListener('scroll', event => this.watchCurrentPosition(), true);
        // document.removeEventListener("click",event => this.handleClickOutside(),false);
    }
    watchCurrentPosition () {
        if(window.innerHeight + this.scrollTop() +20 >= document.body.clientHeight) {
            // console.log('reach end');
            this.onReachEnd();
        }
    }
    scrollTop(){
        return Math.max(
            window.pageYOffset,
            document.documentElement.scrollTop,
            document.body.scrollTop
        );
    }

    onReachEnd(){
        // console.log("LAST:" , this.state.last);
        // console.log('algolia', this.state.algolia);
        if(this.state.algolia){
            this.getMeasurementA(this.props, false, this.state.searchwordA)
        } else {
            // console.log('last reach', this.state.last);
            this.getMeasurement(this.props)
        }

    }
    getMeasurementA = async (props, newOrg, word) => {
        if (this.state.loading) {
            return;
        }

        let fromOrg = "";
        let toOrg = "";
        let typeUser ="";
        if (props.userOrg && props.userOrg.reseller && props.org && props.org.buyer) {
            console.log('logged seller')
            toOrg = props.org.id;
            fromOrg = props.user.organization;
            typeUser = 'seller';
        }
        else if (props.userOrg && props.userOrg.maker && props.org && props.org.reseller) {
            console.log('logged maker')
            toOrg = props.org.id;
            fromOrg = props.user.organization;
            typeUser = 'maker';
        }
        else if (props.userOrg && props.userOrg.reseller && props.org && props.org.maker) {
            console.log('props maker')
            toOrg = props.org.id;
            //fromOrg = props.user.organization;
            typeUser = 'propsMaker';
        }
        else if (props.userOrg && props.userOrg.buyer && props.org && props.org.reseller) {
            console.log('logged buyer')
            //fromOrg = props.org.id;
            toOrg = props.user.organization;
            typeUser = 'buyer';
        }
        else if (props.orgSeller && props.user && props.user.admin) {
            //console.log("props.orgSeller.id", props.orgSeller.id)
            fromOrg = props.orgSeller.id;
        }
        if (fromOrg == "" && toOrg == "")
        {
            return;
        }

        let tempResults = [];
        let filters;
        this.setState({algolia:true,loading:true});
        if(typeUser === 'buyer') {
            filters = `measureOrgs: [${toOrg}]`
        }
        if(typeUser === 'seller') {
            filters = `measureOrgs: [${fromOrg}] || (fromOrganization : ${fromOrg} && organization : ${toOrg})`
        }
        if(typeUser === 'propsMaker') {
            filters = `measureOrgs: [${toOrg}]`
        }
        if(typeUser === 'maker') {
            filters = `measureOrgs: [${fromOrg}] || (fromOrganization : ${fromOrg} && organization : ${toOrg})`
        }

        let searchParameters = {
            'q'         : word,
            'query_by'  : 'name,organization_name,created_user_lastName,created_user_firstName',
            'filter_by' : filters,
            'sort_by'   : 'created_date._seconds:desc',
            'page'      : this.state.algoriaPage,
            'per_page'  : this.state.measurementPerPage
        }
        await typesense.collections('measurement').documents().search(searchParameters).then((docs) => {
            docs.hits.forEach((data) => {
                tempResults.push(data.document);
            })
        });
        let nextPage = this.state.algoriaPage + 1;
        let newResults = this.state.measurements.concat(tempResults);
        this.setState({algoriaPage: nextPage,measurements:newResults,loading:false})

    }

    dateFormat = (date) => {
        const format = 'YYYY/MM/DD HH:mm:ss';
        if (moment(date).isValid()) {
            return moment(date).format(format);
        }
        return moment(date.toDate()).format(format);
    }

    getMeasurement = async (props, newOrg) => {
        //console.log('props', props);
        if (this.state.loading.loading) {
            return;
        }

        if (this.state.notMore) {
            return;
        }
        let fromOrg = "";
        let toOrg = "";
        let typeUser ="";
        if (props.userOrg && props.userOrg.reseller && props.org && props.org.buyer) {
            console.log('logged seller')
            toOrg = props.org.id;
            fromOrg = props.user.organization;
            typeUser = 'seller';
        }
        else if (props.userOrg && props.userOrg.maker && props.org && props.org.reseller) {
            console.log('logged maker')
            toOrg = props.org.id;
            fromOrg = props.user.organization;
            typeUser = 'maker';
        }
        else if (props.userOrg && props.userOrg.reseller && props.org && props.org.maker) {
            console.log('props maker')
            toOrg = props.org.id;
            //fromOrg = props.user.organization;
            typeUser = 'propsMaker';
        }
        else if (props.userOrg && props.userOrg.buyer && props.org && props.org.reseller) {
            console.log('logged buyer')
            //fromOrg = props.org.id;
            toOrg = props.user.organization;
            typeUser = 'buyer';
        }
        else if (props.orgSeller && props.user && props.user.admin) {
            //console.log("props.orgSeller.id", props.orgSeller.id)
            fromOrg = props.orgSeller.id;
        }
        if (fromOrg == "" && toOrg == "")
        {
            return;
        }
        console.log('fromOrg to', fromOrg ,toOrg);
        let first;
        let second;
        this.setState({loading:true, algolia: false});
        if(this.state.last){
            first = this.props.db.collection('measurement')
                //.where("organization", "==", fromOrg)
                .where('measureOrgs', 'array-contains-any',[fromOrg,toOrg])
                .orderBy('created_date','desc')
                .startAfter(this.state.last)
                .limit(this.state.measurementPerPage);
        }else{
            first = this.props.db.collection("measurement")
                //.where("organization", "==", fromOrg)
                .where('measureOrgs', 'array-contains-any',[fromOrg,toOrg])
                .orderBy('created_date','desc')
                .limit(this.state.measurementPerPage);
        }

        let o = await first.get();
        if (o.docs.length > 0) {
            this.setState({loading: true});
            let last = o.docs[o.docs.length - 1];
            // console.log('lastlast', last);
            this.setState({last:last})
            let measurement = this.state.measurements;
            if (newOrg) {
                measurement = [];
            }
            for (let i=0; i<o.docs.length; i++) {
                let doc = o.docs[i];
                let data = doc.data();
                data.id = doc.id;
                measurement.push(data)
            }
            let d = [];
            let d1 = [];
            let union= [];
            let sortedData= [];
            if(typeUser === 'seller') {
                d =  measurement.filter(item => { return item.fromOrganization === fromOrg  && item.organization === toOrg && item} )
                d1 =  measurement.filter(item => { return item.measureOrgs.includes(fromOrg) && item} )
                union = [...new Set([...d, ...d1])];

                sortedData = union.sort((d1, d2) => {
                    let a1 = this.dateFormat(d1.created_date);
                    let a2 = this.dateFormat(d2.created_date);
                    if (a1 < a2) {
                        return 1;
                    }
                    if (a1 > a2) {
                        return -1;
                    }
                    return 0;
                })
            }
            /*else if(typeUser === 'propsMaker') {
                d =  measurement.filter(item => { return item.fromOrganization === toOrg && item.organization === fromOrg && item} )
                d1 = []// measurement.filter(item => { return item.measureOrgs.includes(fromOrg) && item.organization === toOrg && item} )
                union = [...new Set([...d, ...d1])];
                console.log('d', d);
                console.log('d1', d1);
                console.log('union', union);
                sortedData = union.sort((d1, d2) => {
                    let a1 = this.dateFormat(d1.created_date);
                    let a2 = this.dateFormat(d2.created_date);
                    if (a1 < a2) {
                        return 1;
                    }
                    if (a1 > a2) {
                        return -1;
                    }
                    return 0;
                })
            }*/
            else if(typeUser === 'maker') {
                d =  measurement.filter(item => { return item.fromOrganization === fromOrg && item.organization === toOrg && item} )
                d1 =  measurement.filter(item => { return item.measureOrgs.includes(fromOrg) && item} )
                union = [...new Set([...d, ...d1])];
                 sortedData = [...new Set(union)].sort((b1, b2) => {
                     let a1 = this.dateFormat(b1.created_date);
                     let a2 = this.dateFormat(b2.created_date);
                     if (a1 < a2) {
                         return 1;
                     }
                     if (a1 > a2) {
                         return -1;
                     }
                     return 0;
                 })
            }
            if(['seller','maker'].includes(typeUser)) {
                this.setState({measurements:sortedData});
            }
            else { this.setState({measurements:measurement});}
            let wrapper1 = document.getElementById('wrapper1');
            let div1 = document.getElementById('div1');
            let wrapper2 = document.getElementsByClassName('table-responsive')[0];
            let div2 = document.getElementById('div2');

            if (wrapper1 && div1 && wrapper2 && div2) {
                div1.style.width = div2.offsetWidth+'px';

                wrapper1.onscroll = function() {
                    wrapper2.scrollLeft = wrapper1.scrollLeft;
                };
                wrapper2.onscroll = function() {
                    wrapper1.scrollLeft = wrapper2.scrollLeft;
                };
            }
            this.setState({loading: false});
        }

        else {
            this.setState({notMore: true, loading: false});
            if (newOrg === true) {
                this.setState({measurements: []})
            }
        }
    }

    navigateToNew = () => {
        this.props.cProps.history.push('/measurement/new');
    }

    navigateToPreview = (row) => {
        this.props.cProps.history.push('/measurement/preview/'+row.id);
    }

    navigateToEdit = (row) => {
        this.props.cProps.history.push('/measurement/edit/'+row.id);
    }

    navigateToLogs = (row) => {
        this.props.cProps.history.push('/measurement/logs/'+row.id);
    }

    deleteMeasurementData = async () => {
        try {
            const res = await this.props.db.collection('measurement').doc(this.state.selectedRow.id).delete();
            await this.props.db.collection('measurement_logs').doc(this.state.selectedRow.id).delete();
            this.modalToggle();
            this.setState({
                last: null,
                measurements: [],
                notMore: false,
                searchwordA: '',
                algoriaPage: 0,
                algolia: false,
                sortType: 'desc',
                orderType: '',
            }, () => {
                this.getMeasurement(this.props, true);
            })

            // this.setState({reloader: !this.state.reloader});
        } catch (e) {
            console.log('error -> ', e);
        }
    }

    handleSearchwordAChange = (e) => {
        this.setState({searchwordA: e.currentTarget.value})
    }

    handleSearchwordAKeyDown = (e) => {
        let val = this.state.searchwordA;
        if (e.key === 'Enter') {

            this.setState({
                measurements: [],
                algoriaPage: 1,
                last: null,
            }, ()=>{
                if (val) {
                    this.getMeasurementA(this.props, true, val);
                } else {
                    this.getMeasurement(this.props, true)
                }
            });

        }
    }
    selectOrderType = (e) => {
        console.log('selectOrderType',e);
    }

    clearFilter = () => {
        console.log('clearFilter');
        /* this.setState({last: null, notMore: false, orderType: '', sortType: 'desc', searchwordA: '', algolia: false, dbOrders: [], algoriaPage: 0}, () => {
             if (this.state.algolia) {
                 this.getOrdersA(this.props, true, this.state.searchwordA)
             } else {
                 this.getOrders(this.props, true);
             }

         })*/
    }
    showMenu = (itemId, index) => {
        let obj;
        obj = {
            showMenu: itemId,
            showMenuIndex: index
        }

        this.setState({showMenu: obj.showMenu, showMenuIndex: obj.showMenuIndex})
    }

    n =(n)=> {
        return n > 9 ? "" + n : "0" + n;
    }
    dateFormatter = (date) => {
        const format = 'YYYY/MM/DD';
        if (moment(date).isValid()) {
            let dDate = new Date(date._seconds * 1000 + date._nanoseconds / 1000000);
            date = dDate.getFullYear() + '/'
                + this.n(dDate.getMonth() + 1) + '/'
                + this.n(dDate.getDate());

            return date;
        }
        return moment(date.toDate()).format(format);
    }
     groupBy = (keys) => (array) => array.reduce((objectsByKeyValue, obj) => {
            const value = keys.map((key) => obj[key]).join("-");
            objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
            return objectsByKeyValue;
        }, {});

    migrationSubToTable = async () => {
        this.setState({loading:true});
        let snapshot = await this.props.db.collection('measurement').get();
        let mData = [];
        //let dt = {id: snapshot.id, ...snapshot.data()};
        for (let i=0; i<snapshot.docs.length; i++) {
            let doc = snapshot.docs[i];
            //let data = {};
            //data.id = doc.id;
            /*if( //['R5W72BIly5Bjnu7vBZV0','D6arkkG3ZR46G5rOCbVU'].includes(doc.id)
                ['R5W72BIly5Bjnu7vBZV0','D6arkkG3ZR46G5rOCbVU'].includes(doc.id)
            ) */
            mData.push(doc.id)
        }
        //mData = mData.filter(e=> <e className="id"></e>)
        console.log('mData', mData);
        const measureData = [];
        for (let ii = 0; ii < mData.length; ii++) {

            const snap = await this.props.db.collection('measurement').doc(mData[ii]).get();
            let tData;
            tData = {id: snap.id, process: [], ...snap.data()};

            let process = await this.props.db.collection('measurement').doc(mData[ii]).collection('process').get();
            let pData = [];
            if (process.docs.length > 0) {
                for (let i = 0; i < process.docs.length; i++) {
                    let doc = process.docs[i];
                    let data = doc.data();
                    data.id = doc.id;
                    data.items = []
                    pData.push(data)
                }
            }
            if (pData && pData.length > 0) {
                for (let i = 0; i < pData.length; i++) {
                    let items = await this.props.db.collection('measurement').doc(mData[ii]).collection('process').doc(pData[i].id).collection('items').get();
                    let itemData = [];
                    if (items.docs.length > 0) {
                        for (let i1 = 0; i1 < items.docs.length; i1++) {
                            let doc = items.docs[i1];
                            let data1 = doc.data();
                            data1.id = doc.id;
                            data1.processId = pData[i].id;
                            itemData.push(data1)
                        }
                    }
                    itemData.sort((d1, d2) => {
                        return d1.index - d2.index
                    });
                    if (itemData && itemData.length > 0) {
                        for (let j = 0; j < itemData.length; j++) {
                            if (pData[i].id === itemData[j].processId) {
                                pData[i].items.push(itemData[j])
                            }
                        }
                    }
                }
            }

            tData.process = pData;
            measureData.push(tData);
        }
        console.log('allData', measureData);

        let processRef;
        let items;
        for (let i = 0; i < measureData.length; i++) {
            if( measureData[i].process!==undefined) {
                for (let i1 = 0; i1 < measureData[i].process.length; i1++) {
                    //processRef = await this.props.db.collection('measurement').doc(measureData[i].id).collection('process').doc(measureData[i].process[i1].id);
                    /*await processRef.set({
                        index: i1 + 1,
                        process_name: measureData[i].process[i1].process_name,
                        analysis_product_name: measureData[i].process[i1].analysis_product_name,
                        /!*fieldComments: measureData[i].process[i1].fieldComments,
                        labComments: measureData[i].process[i1].labComments,
                        automaticComments: measureData[i].process[i1].automaticComments,*!/
                    }, {merge: true});*/
                    for (let j = 0; j < measureData[i].process[i1].items.length; j++) {
                        //items = await this.props.db.collection('measurement').doc(measureData[i].id).collection('process').doc(processRef.id).collection('items').doc(measureData[i].process[i1].items[j].id);
                        /* await items.set({
                             index: j,
                             item_name: measureData[i].process[i1].items[j].item_name,
                             tolerance_lvl_max: measureData[i].process[i1].items[j].tolerance_lvl_max,
                             tolerance_lvl_min: measureData[i].process[i1].items[j].tolerance_lvl_min,
                             unit: measureData[i].process[i1].items[j].unit,
                             range_settings: measureData[i].process[i1].items[j].range_settings,
                             graph_display: measureData[i].process[i1].items[j].graph_display,
                             /!*fieldMeasure: measureData[i].process[i1].items[j].fieldMeasure,
                             labMeasure: measureData[i].process[i1].items[j].labMeasure,
                             automaticMeasure: measureData[i].process[i1].items[j].automaticMeasure,*!/
                         }, {merge: true});*/

                        await this.props.db.collection('measurement').doc(measureData[i].id).collection('process').doc(measureData[i].process[i1].id).collection('items').doc(measureData[i].process[i1].items[j].id).update({
                            fieldMeasure: [],
                            labMeasure: [],
                            automaticMeasure: [],
                        });

                        /*if( measureData[i].process[i1].items[j].fieldMeasure.length > 0) {
                            for (let m = 0; m < measureData[i].process[i1].items[j].fieldMeasure.length; m++) {
                                let fieldMeasure =  await this.props.db.collection('measurement').doc(measureData[i].id).collection('process').doc(measureData[i].process[i1].id).collection('items').doc(measureData[i].process[i1].items[j].id).collection('fieldMeasure').doc();
                                await fieldMeasure.set({
                                    edit: measureData[i].process[i1].items[j].fieldMeasure[m].edit===undefined ? false : measureData[i].process[i1].items[j].fieldMeasure[m].edit,
                                    insertedDate: new Date(measureData[i].process[i1].items[j].fieldMeasure[m].insertedDate.seconds * 1000),//measureData[i].process[i1].items[j].fieldMeasure[m].insertedDate,
                                    value: measureData[i].process[i1].items[j].fieldMeasure[m].value,
                                }, {merge: true});
                            }
                        }
                        if( measureData[i].process[i1].items[j].labMeasure.length > 0) {
                            for (let m1 = 0; m1 < measureData[i].process[i1].items[j].labMeasure.length; m1++) {
                                let labMeasure = await this.props.db.collection('measurement').doc(measureData[i].id).collection('process').doc(measureData[i].process[i1].id).collection('items').doc(measureData[i].process[i1].items[j].id).collection('labMeasure').doc();
                                await labMeasure.set({
                                    edit: measureData[i].process[i1].items[j].labMeasure[m1].edit===undefined ? false : measureData[i].process[i1].items[j].labMeasure[m1].edit,
                                    insertedDate: new Date(measureData[i].process[i1].items[j].labMeasure[m1].insertedDate.seconds * 1000),//measureData[i].process[i1].items[j].fieldMeasure[m].insertedDate,
                                    value: measureData[i].process[i1].items[j].labMeasure[m1].value,
                                }, {merge: true});
                            }
                        }
                        if( measureData[i].process[i1].items[j].automaticMeasure.length > 0) {
                            for (let m2 = 0; m2 < measureData[i].process[i1].items[j].automaticMeasure.length; m2++) {
                                let automaticMeasure = await this.props.db.collection('measurement').doc(measureData[i].id).collection('process').doc(measureData[i].process[i1].id).collection('items').doc(measureData[i].process[i1].items[j].id).collection('automaticMeasure').doc();
                                await automaticMeasure.set({
                                    edit: measureData[i].process[i1].items[j].automaticMeasure[m2].edit===undefined ? false : measureData[i].process[i1].items[j].automaticMeasure[m2].edit,
                                    insertedDate: new Date(measureData[i].process[i1].items[j].automaticMeasure[m2].insertedDate.seconds * 1000),//measureData[i].process[i1].items[j].fieldMeasure[m].insertedDate,
                                    value: measureData[i].process[i1].items[j].automaticMeasure[m2].value,
                                }, {merge: true});
                            }
                        }*/
                    }

                    await this.props.db.collection('measurement').doc(measureData[i].id).collection('process').doc(measureData[i].process[i1].id).update({
                        fieldComments: [],
                        labComments: [],
                        automaticComments: [],
                    });
                    /*if( measureData[i].process[i1].fieldComments.length > 0) {
                        for (let c = 0; c < measureData[i].process[i1].fieldComments.length; c++) {
                            let fieldComments = await this.props.db.collection('measurement').doc(measureData[i].id).collection('process').doc(measureData[i].process[i1].id).collection('fieldComments').doc();
                            await fieldComments.set({
                                edit: measureData[i].process[i1].fieldComments[c].edit===undefined ? false : measureData[i].process[i1].fieldComments[c].edit,
                                insertedDate: new Date(measureData[i].process[i1].fieldComments[c].insertedDate.seconds * 1000),
                                comment: measureData[i].process[i1].fieldComments[c].comment,
                            }, {merge: true});
                        }
                    }
                    if( measureData[i].process[i1].labComments.length > 0) {
                        for (let c1 = 0; c1 < measureData[i].process[i1].labComments.length; c1++) {
                            let labComments = await this.props.db.collection('measurement').doc(measureData[i].id).collection('process').doc(measureData[i].process[i1].id).collection('labComments').doc();
                            await labComments.set({
                                edit: measureData[i].process[i1].labComments[c1].edit===undefined ? false : measureData[i].process[i1].labComments[c1].edit,
                                insertedDate: new Date(measureData[i].process[i1].labComments[c1].insertedDate.seconds * 1000),
                                comment: measureData[i].process[i1].labComments[c1].comment,
                            }, {merge: true});
                        }
                    }
                    if( measureData[i].process[i1].automaticComments.length > 0) {
                        for (let c2 = 0; c2 < measureData[i].process[i1].automaticComments.length; c2++) {
                            let automaticComments = await this.props.db.collection('measurement').doc(measureData[i].id).collection('process').doc(measureData[i].process[i1].id).collection('automaticComments').doc();
                            await automaticComments.set({
                                edit: measureData[i].process[i1].automaticComments[c2].edit===undefined ? false : measureData[i].process[i1].automaticComments[c2].edit,
                                insertedDate: new Date(measureData[i].process[i1].automaticComments[c2].insertedDate.seconds * 1000),
                                comment: measureData[i].process[i1].automaticComments[c2].comment,
                            }, {merge: true});
                        }
                    }*/
                    console.log('measureData[i]',measureData[i].id);
                }
            }
        }
        this.setState({loading:false});
    }
    migFromOrg = async () => {
        //zsASdUdIJDhCpLfL5iSK
        //let snapshot = await this.props.db.collection('measurement').doc('HpmA6BD09DkAsKePmBhf').get();
        let snapshot = await this.props.db.collection('measurement').get();
        let measureData = [];
        //let dt = {id: snapshot.id, ...snapshot.data()};
        for (let i=0; i<snapshot.docs.length; i++) {
            let doc = snapshot.docs[i];
            let data = doc.data();
            data.id = doc.id;
            measureData.push(data)
        }
        console.log('measureData', measureData);
        let measureData_result =  measureData.filter(item => item.maker === true)
        console.log('measureData_result', measureData_result);
        /*for (let i = 0; i < measureData_result.length; i++) {
            await this.props.db.collection('measurement').doc(measureData_result[i].id).delete();
            await this.props.db.collection('measurement_logs').doc(measureData_result[i].id).delete();
            console.log('measureData_result[i].id', measureData_result[i].id);
        }*/

        /*let orgData = [];
        let snapshotOrg = await this.props.db.collection('organizations').get();
        for (let i=0; i<snapshotOrg.docs.length; i++) {
            let doc = snapshotOrg.docs[i];
            let dataOrg = {}
            dataOrg.name = `${doc.data()['company_name']} ${doc.data()['name']}` ;
            dataOrg.id = doc.id;
            dataOrg.buyer = doc.data()['buyer'] === undefined ? false : doc.data()['buyer'];
            dataOrg.reseller = doc.data()['reseller'] === undefined ? false : doc.data()['reseller'];;
            dataOrg.maker = doc.data()['maker'] === undefined ? false : doc.data()['maker'];;
            orgData.push(dataOrg)
        }
        console.log('orgData', orgData);
        let userData = [];
        const groupByCUser = this.groupBy(["created_user"]);
        for (let [groupName, values] of Object.entries(groupByCUser(measureData))) {
            console.log(`${groupName}`);
            let user = await this.props.db.collection('users').doc(groupName).get();
            let d1 = {};
            d1.organization = user.data()['organization'];
            d1.id = user.id;
            d1.organizationName = orgData.filter(item => item.id === user.data()['organization'])[0].name;
            d1.maker = orgData.filter(item => item.id === user.data()['organization'])[0].maker;
            userData.push(d1);
        }
        console.log('userData', userData);
        for (let i=0; i< measureData.length; i++) {
            measureData[i].fromOrganization =  userData.filter(item => item.id === measureData[i].created_user)[0].organization;
            measureData[i].fromOrganizationName =  userData.filter(item => item.id === measureData[i].created_user)[0].organizationName;
            measureData[i].maker =  userData.filter(item => item.id === measureData[i].created_user)[0].maker;
        }
        console.log('after measureData', measureData);
        //"0BL0ynJuNFfvbaPnlDcZ"
        for (let i=0; i<measureData.length; i++) {
            await this.props.db.collection('measurement').doc(measureData[i].id).update(
                {
                    maker: measureData[i].maker,
                    //fromOrganization: measureData[i].fromOrganization ,
                    //fromOrganizationName: measureData[i].fromOrganizationName,
                });
            console.log('measureData[i].id', measureData[i].id);
        }*/
        /*let byUser = Object.groupBy(measureData, ({created_user}) => created_user);
        console.log('byUser', byUser);
        let userData = [];
        for (let i=0; i<byUser.length; i++) {
            let user = await this.props.db.collection('users').doc(byUser[i]).get();
            //let userData = user.data();
            userData.push(user.data());
        }
        console.log('userData', userData);*/
       /* let snapshotOrg = await this.props.db.collection('measurement').get();
        let measureData = [];*/


    }
    render() {
        let kaigawa = false;
        let urigawa = false;

        if ( (this.props.userOrg && this.props.userOrg.buyer && this.props.org && this.props.org.reseller)
            || (this.props.userOrg && this.props.userOrg.reseller && this.props.org && this.props.org.maker)
        )
        {
            kaigawa = true;
        }
        if ( (this.props.userOrg && this.props.userOrg.reseller && this.props.org && this.props.org.buyer)
            || (this.props.userOrg && this.props.userOrg.maker && this.props.org && this.props.org.reseller)
        ){
            urigawa = true;
        }

        let selectOption = <>
            <option value={null}>すべて</option>
        </>;

        if (urigawa) {
            selectOption = <>
                <option value={null}>すべて</option>
                <option value={4}>最新更新順</option>
                <option value={3}>受注済</option>
                <option value={2}>受注確認済</option>
                <option value={5}>未出荷あり</option>
                <option value={6}>出荷済</option>
            </>
        } else if (kaigawa) {
            selectOption = <>
                <option value={null}>すべて</option>
                <option value={4}>最新更新順</option>
                <option value={1}>上長承認待ち</option>
                <option value={2}>発注済</option>
                <option value={3}>却下</option>
                <option value={5}>未出荷あり</option>
                <option value={6}>出荷済</option>
            </>
        }
        const measurement = [];
        this.state.measurements.map(item => {
            let findItem = measurement.find((x) => x.id === item.id);
            if (!findItem) measurement.push(item);
        })

        return(
            <>
                <div>
                    {this.state.showMenu !== null && this.state.showMenuIndex !== null && <div style={{position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1}} onClick={() => this.handleClickOutside()}/>}
                    <h2 style={{fontFamily: 'Meiryo.ttf',textAlign:'left',color:'#212529',fontSize:'32px',fontWeight:'normal',opacity:1,letterSpacing:'0'}}>測定データ一覧</h2>
                    <MDBRow style={{display:'flex', justifyContent:'space-between',alignItems: 'center'}}>
                        <MDBRow size={"auto"} style={{display: 'flex', justifyContent: 'flex-start',alignItems:'center',marginLeft:15,}}>
                            <div className="input-group md-form form-sm form-1 pl-0" style={{width: 200}}>
                                <div className="input-group-prepend">
                                    <span className="input-group-text lighten-3" id="basic-text1">
                                      <MDBIcon className="text-white" icon="search" />
                                    </span>
                                </div>
                                <input
                                    className="form-control my-0 py-1"
                                    type="text"
                                    placeholder="文字列検索"
                                    aria-label="文字列検索"
                                    onChange={this.handleSearchwordAChange}
                                    onKeyDown={this.handleSearchwordAKeyDown}
                                    value={this.state.searchwordA}
                                />
                            </div>
                            {/*<div className="form-check form-check-inline" style={{marginLeft: 10}}>
                                <select className="browser-default custom-select" value={this.orderType} onChange={this.selectOrderType} style={{height:33}}>
                                    {selectOption}
                                </select>
                            </div>
                            <div className={'form-check form-check-inline'} style={{marginLeft: 0}}>
                                <MDBBtn
                                    color="default" style={{padding: 10}} flat={true} outline={true} onClick={this.clearFilter}>
                                    リセット
                                </MDBBtn>
                            </div>*/}
                        </MDBRow>
                        <MDBBtn size={'sm'} color="primary-color" style={{marginRight:15,marginLeft:15, backgroundColor: '#4285F4',borderRadius:'2px',fontSize:'14.4px',fontFamily:'Meiryo.ttf',color: "white"}} onClick={this.navigateToNew}>新規登録</MDBBtn>
                    </MDBRow>
                    <MDBTable responsive small id={"div2"}>
                        <MDBTableHead color="primary-color" textWhite>
                            <tr>
                                <th>初回登録年月日<br/>対象事業所<br/>登録事業所<br/>登録者名</th>
                               {/* <th>初回登録年月日<br/>登録事業所<br/>登録者名</th>*/}
                                <th>最終更新年月日<br/>更新事業所<br/>更新者名</th>
                                <th>開始年月日<br/>&nbsp;</th>
                                <th>測定名称<br/>&nbsp;</th>
                                <th>共有事業所<br/>&nbsp;</th>
                                <th></th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {measurement.map((item, index) => (
                                <tr key={index}>
                                    <td style={styles.tdStyle}>{item && item.created_date && this.dateFormatter(item.created_date)}<br/>{item && item.organization_name}<br/>{item.fromOrganizationName}<br/>{item.created_user_lastName}&nbsp;{item.created_user_firstName}</td>
                                    {/*<td style={styles.tdStyle}>{item && item.created_date && this.dateFormatter(item.created_date)}<br/>{(this.props.userOrg && this.props.userOrg.buyer && this.props.org && this.props.org.reseller) ? item.fromOrganizationName :  item.organization_name}<br/>{item.created_user_lastName}&nbsp;{item.created_user_firstName}</td>*/}
                                    <td style={styles.tdStyle}>
                                        {item.fieldUpdate && item.fieldUpdate.user && item.fieldUpdate.org && <div ><span className={'measureBlueBtn'} style={{color:'white',paddingTop:3,paddingBottom:3,paddingLeft:15,paddingRight:15,borderRadius:5}}>現地測定</span><br/>{ this.dateFormatter(item.fieldUpdate.updated_date)}<br/>{item.fieldUpdate.org_name}<br/>{item.fieldUpdate.last_name}&nbsp;{item.fieldUpdate.first_name}</div>}
                                        {item.labUpdate && item.labUpdate.user && item.labUpdate.org && <div><br/><span className={'measureRedBtn'} style={{color:'white',paddingTop:3,paddingBottom:3,paddingLeft:15,paddingRight:15,borderRadius:5}}>ラボ測定</span><br/>{this.dateFormatter(item.labUpdate.updated_date)}<br/>{item.labUpdate.org_name}<br/>{item.labUpdate.last_name}&nbsp;{item.labUpdate.first_name}</div>}
                                        {item.automaticUpdate && item.automaticUpdate.user && item.automaticUpdate.org && <div><br/><span className={'measureOrangeBtn'} style={{color:'white',paddingTop:3,paddingBottom:3,paddingLeft:15,paddingRight:15,borderRadius:5}}>自動測定</span><br/>{this.dateFormatter(item.automaticUpdate.updated_date)}<br/>{item.automaticUpdate.org_name}<br/>{item.automaticUpdate.last_name}&nbsp;{item.automaticUpdate.first_name}</div>}
                                    </td>
                                    <td style={styles.tdStyle}>{item && item.start_date && this.dateFormatter(item.start_date)}</td>
                                    <td style={styles.tdStyle}><span style={{fontWeight:'bold'}}>{item.name}</span></td>
                                    <td style={styles.tdStyle}>{item && item.measureShare && item.measureShare.map((ele,index) => (<div key={index}>{ele.name}</div>  ))}</td>
                                    <td style={{...styles.tdStyle, minWidth: '200px',position:'relative', alignItems: 'center'}}>
                                        <div style={{alignItems:"center",display:'flex',justifyContent: 'flex-end',position:'relative'}}>
                                            <MDBChip color="" text="white" size="sm4" style={{backgroundColor: '#2BBBAD',textWrap:'noWrap', textAlign:'center',alignItems: 'center'}} onClick={() => this.navigateToPreview(item)}>測定値入力/プレビュー</MDBChip>
                                            <MDBBtn text="white" size="sm4" style={{backgroundColor: '#2D486A', padding: '0.3rem 1rem',textWrap:'noWrap',textAlign:'center',alignItems: 'center'}} onClick={() => this.navigateToEdit(item)}>管理項目編集</MDBBtn>
                                            <div className={'menuButtonView'}>
                                                <div className={'menuButton'} onClick={(e) => {
                                                    e.stopPropagation();
                                                    this.showMenu(item.id, index);
                                                }}>
                                                    <MDBIcon icon={'ellipsis-v'}/>
                                                </div>
                                                {this.state.showMenu === item.id && this.state.showMenuIndex === index && <div className={'menuArea'} style={{marginTop:35,width: '330px'}}>
                                                    <div onClick={() => { this.setState({showMenu: null, showMenuIndex: null}); this.modalToggle(item)}}>
                                                        この管理項目データと測定データをすべて削除
                                                    </div>
                                                </div>
                                                }
                                                {/*  <MDBIcon className='ms-1' icon='trash' size='lg' style={{ color: '#888888', padding: '0.3rem 1rem' ,cursor:'pointer'}}  onClick={() => this.modalToggle(item)}/>*/}
                                                {/*<MDBBtn text="white" size="sm4" style={{backgroundColor: '#2D486A', padding: '0.3rem 1rem'}} onClick={() => navigateToLogs(item)}>変更履歴</MDBBtn>*/}
                                            </div>
                                        </div>

                                    </td>
                                </tr>
                            ))}
                        </MDBTableBody>
                    </MDBTable>
                    {this.state.loading && <div className="d-flex justify-content-center mt-5"><MDBSpinner /></div>}
                    <MDBModal isOpen={this.state.showModal} toggle={this.modalToggle}>
                        <MDBModalHeader toggle={this.modalToggle}>
                            測定データ削除
                        </MDBModalHeader>
                        <MDBModalBody>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span>以下の項目を削除します。よろしいですか?</span><br/>
                                <span>開始年月日 : { this.state.selectedRow && this.state.selectedRow.start_date && moment(this.state.selectedRow.start_date.toDate()).format('YYYY-MM-DD') }</span>
                                <span>分析名称 :  { this.state.selectedRow.name }</span>
                                <div style={{display:'flex',flexDirection:'row'}}><div>共有事業所 : </div>
                                    <div>{this.state.selectedRow && this.state.selectedRow.measureShare && this.state.selectedRow.measureShare.map((ele,index) => (<div key={index}>{ele.name}</div>  ))}</div>

                                </div>
                                <span>メーカー : { this.state.selectedRow.organization_name }</span>
                            </div>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn onClick={this.modalToggle} color="info">キャンセル</MDBBtn>
                            <MDBBtn onClick={this.deleteMeasurementData} color="danger">削除</MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>
                </div>
            </>
        )

    }

}

const styles = {
    tdStyle: {
        fontFamily: 'Meiryo.ttf',
        //textAlign:'center',
        alignItems:'center',
        verticalAlign: 'middle',
        color:'#212529',
        fontSize: '14px',
    }
}

export default Measurement;
