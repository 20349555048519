import 'devextreme/dist/css/dx.light.css';
import React, {useEffect} from "react";
import {render} from 'react-dom'
import {checkRevision} from './revision_check'
import RevisionUpMessage from './revision_up_message'
import firebase from './firebase'
import { BrowserRouter, Route, Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { MDBSpinner, MDBInput, MDBNavbar, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBIcon, MDBSideNavItem, MDBSideNavCat, MDBSideNavNav, MDBSideNav, MDBContainer } from "mdbreact";
import { ToastContainer } from 'react-toastify'
import CompanyLogin from './components/CompanyLogin/CompanyLogin';
import UserLogin from './components/UserLogin/UserLogin';
import P4Header from './components/P4Header/P4Header';
import P4Footer from './components/P4Footer/P4Footer.js';
import SignInScreen from './components/SignInScreen/SignInScreenP4';
import TopPage from './components/TopPage/TopPage.js';
import Items from './components/Item/ItemsTableList';
import ItemsV2 from './components/Item/ItemsTableListV2';
import Item from './components/Item/Item';
import Cart from './components/Cart/Cart';
import Favorite from './components/Cart/Favorite';
import OtasukeCart from './components/Cart/OtasukeCart';
import OtasukeCartDetail from "./components/Cart/OtasukeCartDetail";
import OtasukeCartList from "./components/Cart/OtasukeCartList";
import OtasukeCartHistoryList from "./components/Cart/OtasukeCartHistoryList";
import Order from './components/Order/Order';
import OrderTableList from './components/Order/OrderTableList';
import Estimates from './components/Estimates/Estimates';
import EstimatesTableList from './components/Estimates/EstimatesTableList';
import Estimates2 from './components/Estimates2/Estimates2';
import Estimates2TableList from './components/Estimates2/Estimates2TableList';
import Bills from './components/Bills/Bills';
import DeliveryNotes from './components/DeliveryNotes/DeliveryNotes';
import BillsTableList from './components/Bills/BillsTableList';
import P4Bills from './components/P4Bills/P4Bills';
import P4BillsTableList from './components/P4Bills/P4BillsTableList';
import SDS from './components/SDS/SDS';
import SDSTableList from './components/SDS/SDSTableList';
import Catalog from './components/Catalog/Catalog';
import CatalogTableList from './components/Catalog/CatalogTableList';
import Manual from './components/Manual/Manual';
import ManualTableList from './components/Manual/ManualTableList';
import Report from './components/Report/Report';
import ReportTableList from './components/Report/ReportTableList';
import ReportTableListV2 from './components/Report/ReportTableListV2';
import CompanyInfo from './components/CompanyInfo/CompanyInfo';
import ScrollToTop from './components/ScrollToTop';
import firestore from "firebase/firestore"
import firestorage from "firebase/storage"
import companyInfo from "./components/CompanyInfo/CompanyInfo";

import EmailSettings from "./components/EmailSettings/EmailSettings";
import FavoriteCart from "./components/Cart/FavoriteCart";
import DeliveryAddressList from "./components/DeliveryAddress/DeliveryAddressList";
import DeliveryAddress from "./components/DeliveryAddress/DeliveryAddress";
import DeliveryAddressAdd from "./components/DeliveryAddress/DeliveryAddressAdd";
import Support from "./components/Support/Support";
import SupportCategory from "./components/Support/SupportCategory";
import SupportFilter from "./components/Support/SupportFilter";
import SupportDetail from "./components/Support/SupportDetail";
import SupportUpdate from "./components/Support/SupportUpdate";
import SupportUpdateDetail from "./components/Support/SupportUpdateDetail";
import SupportUpdateGroup from "./components/Support/SupportUpdateGroup";
import SupportUpdateGroupYear from "./components/Support/SupportUpdateGroupYear";
import SDSManagementTableList from "./components/SDS/SDSManagementTableList";
import SDSManagementHistory from "./components/SDS/SDSManagementHistory";
import SDSManagementDate from "./components/SDS/SDSManagementDate";
import Statistic from "./components/Statistic";
import Measurement from "./components/Measurement";
import CreateNewMeasurement from "./components/Measurement/CreateNewMeasurement";
import PreviewMeasurement from "./components/Measurement/PreviewMeasurement";
import MeasurementLogs from "./components/Measurement/MeasurementLogs";
import OrderTableListV2 from "./components/Order/OrderTableListV2";
import SDSManagementRisk from "./components/SDS/SDSManagementRisk";
import DeliveryNotesTableList from "./components/DeliveryNotes/DeliveryNotesTableList";

export const db = firebase.firestore();
export const storage = firebase.storage();

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      breakWidth: 1300,
      windowWidth: 0,
      loading: true,
      user: null,
      login: false,
      userRef: null,
      targetOrgRef: null,
      targetOrgSellerRef: null,
      organization: null,

      unreadOrder: 0,
      unreadBill: 0,
      unreadDeliveryNotes: 0,
      unreadEstimates2: 0,
      unreadEstimates: 0,
      unreadReport: 0,
      unreadOCH: 0,
      unreadOrderWithOrg: {},
      unreadBillWithOrg: {},
      unreadDeliveryNotesWithOrg: {},
      unreadEstimates2WithOrg: {},
      unreadEstimatesWithOrg: {},
      unreadReportWithOrg: {},
      unreadOCHWithOrg: {},
      notShippedOrder: 0,
      notShippedOrderWithOrg: {},

      unreadOrderSeller: 0,
      unreadOrderSellerWithOrg: {},
      unreadOrderBuyer: 0,
      unreadOrderBuyerWithOrg: {},

      notShippedOrderSeller: 0,
      notShippedOrderSellerWithOrg: {},
      notShippedOrderBuyer: 0,
      notShippedOrderBuyerWithOrg: {},

      unreadBillSeller: 0,
      unreadBillSellerWithOrg: {},
      unreadBillBuyer: 0,
      unreadBillBuyerWithOrg: {},

      unreadDeliveryNotesSeller: 0,
      unreadDeliveryNotesSellerWithOrg: {},
      unreadDeliveryNotesBuyer: 0,
      unreadDeliveryNotesBuyerWithOrg: {},

      unreadReportSeller: 0,
      unreadReportSellerWithOrg: {},
      unreadReportBuyer: 0,
      unreadReportBuyerWithOrg: {},

      unreadEstimatesSeller: 0,
      unreadEstimatesSellerWithOrg: {},
      unreadEstimatesBuyer: 0,
      unreadEstimatesBuyerWithOrg: {},

      unreadEstimates2Seller: 0,
      unreadEstimates2SellerWithOrg: {},
      unreadEstimates2Buyer: 0,
      unreadEstimates2BuyerWithOrg: {},

      sdsSellerWaitingCountWithOrg: {},
      sdsBuyerWaitingCountWithOrg: {},
      sdsWaitingCount: 0,
      sdsSellerWaitingCount: 0,
      sdsBuyerWaitingCount: 0,
      sdsWaitingCountWithOrg: {},

      sdsNormalCount: 0,
      sdsSellerNormalCount: 0,
      sdsBuyerNormalCount: 0,
      sdsNormalCountWithOrg: {},
      sdsSellerNormalCountWithOrg: {},
      sdsBuyerNormalCountWithOrg: {},

      sdsWarningCount: 0,
      sdsSellerWarningCount: 0,
      sdsBuyerWarningCount: 0,
      sdsWarningCountWithOrg: {},
      sdsSellerWarningCountWithOrg: {},
      sdsBuyerWarningCountWithOrg: {},

      sdsAlertCount: 0,
      sdsSellerAlertCount: 0,
      sdsBuyerAlertCount: 0,
      sdsAlertCountWithOrg: {},
      sdsSellerAlertCountWithOrg: {},
      sdsBuyerAlertCountWithOrg: {},

      support: {
        categoryTitle: {
          order: '発注',
          orderReceived: '受注',
          quotation: '見積書/請求書/報告書/工事見積書',
          'sds-manual-catalog': 'SDS/リスクアセスメント管理/取扱説明書/カタログ',
          setting: '設定',
          'otasuke-cart': 'お助けカート',
          'favorite-set': 'お気に入りセット登録',
          'delivery-address-list': '納品場所の追加と管理',
          analytics: 'データ分析',
          'update': 'アップデート情報'
        },
        categoryBreadcrumbArray : {
          order: ['ホーム', '発注'],
          orderReceived: ['ホーム', '受注'],
          quotation: ['ホーム', '見積書/請求書/報告書/工事見積書'],
          'sds-manual-catalog': ['ホーム', 'SDS/リスクアセスメント管理/取扱説明書/カタログ'],
          setting: ['ホーム', '設定'],
          'otasuke-cart': ['ホーム', 'お助けカート'],
          'favorite-set': ['ホーム'],
          'delivery-address-list': ['ホーム'],
          analytics: ['ホーム', 'データ分析'],
          update: ['ホーム']
        },
        order: [
          {
            id: 1,
            title: '発注をする',
            link: '/support/order/1',
            data: <div>
              <p>サイドメニューから<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 商品購入 </span>→<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 商品一覧 </span>を開きます。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2F1-1.png?alt=media&token=7cf18c01-080c-4bbd-b489-b6abc406ad1c'}/>
              <div  id={'search'}/>
              <br/><br/>
              <p>発注する商品の<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 再発注 </span>ボタンをクリックします。</p>
              <p>※全文検索、商品名検索、メーカー名検索のいずれかで商品を検索することができます。</p>
              <img style={{width: '100%', maxWidth: 800, border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2F1%E5%95%86%E5%93%81%E4%B8%80%E8%A6%A7.jpg?alt=media&token=dcc3cedf-00a3-4a5e-ae2d-4df58afa2561'}/>
              <br/><br/>
              <div style={{backgroundColor: '#fffaeb', borderLeft: '10px solid #FFD54F', padding: 15}}>
                <h4>注意</h4>
                <br/>
                <p>購入数量にかかわらず、<span style={{color: '#ff0000'}}>一度だけ</span><span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 再発注 </span>ボタンをクリックします。</p>
                <p>数量の変更はこの後の手順で行います。</p>
              </div>
              <br/>
              <p>カート選択のポップアップ画面が表示されます。商品を入れるカートを選択します。</p>
              <p>お助けカートからカートに商品を投入することもできます。</p>
              <p><Link to={"/support/otasuke-cart/1"} target="_blank">お助けカートを使ってカートを作成する</Link></p>
              <img style={{width: '100%', maxWidth: 500, border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2F3-1-1024x958.png?alt=media&token=d85ba8a0-59c6-49f7-99a5-cca893a61323'}/>
              <br/><br/>
              <p>商品を全てカートへ投入したら、サイドメニューから<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> カート </span>を開きます</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2F4-1024x585.png?alt=media&token=0dfcc087-40ca-42d4-b9bd-808ad60cf5d2'}/>
              <br/><br/>
              <p>選んだ商品の内容や価格が表示されます。</p>
              <p>数量の変更は数量欄に希望の数を半角数字で直接入力するか、＋/－ボタンをクリックします。</p>
              <img style={{width: '100%', maxWidth: 905, border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2F%E3%82%AB%E3%83%BC%E3%83%88_1-2048x979.png?alt=media&token=e9b01081-2905-4fe7-b305-b8626da12f2a'}/>
              <br/><br/>
              <p><span style={{color: 'red'}}>相手先営業担当者は必ず選択してください。</span>相手先業務担当者は任意で選択します。</p>
              <p>各項目を入力し、最後に<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 上長承認依頼 </span>をクリックします。</p>
              <p>上長に「発注内容上長承認依頼」を通知するメールが届きます。（受信設定をONにしている場合）</p>
              <p>上長が承認すると、相手先営業担当者と相手先業務担当者に通知メールが届きます。（受信設定をONにしている場合）</p>
              <div style={{backgroundColor: '#fffaeb', borderLeft: '10px solid #FFD54F', padding: 15}}>
                <h4>POINT</h4>
                <br/>
                <p>選択した相手先営業担当者と相手先業務担当者は、次回以降も既選択状態でプルダウンメニューに表示されます。</p>
                <p>変更することも可能です。</p>
              </div>
              <br/>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2F1%E7%99%BA%E6%B3%A8%E3%82%92%E3%81%99%E3%82%8B.jpg?alt=media&token=5e0ee6f0-0914-48a0-9d5d-ba702e10d846'}/>
              <br/><br/>
              <p>カートに数量0の商品が含まれている場合、上長承認依頼ボタンをクリックすると以下のポップアップガイダンスが表示されます。</p>
              <p><span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 削除して上長承認依頼する </span>をクリックすると数量0の商品は削除されます</p>
              <p>削除された商品は発注書には表示されません</p>
              <img style={{width: '100%', maxWidth: 499, border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2Fimage.png?alt=media&token=df1b7cc5-4f1f-4ff4-97f8-28fa5022f849'}/>
              <br/><br/>
              <div style={{backgroundColor: '#fffaeb', borderLeft: '10px solid #FFD54F', padding: 15}}>
                <h4>注意</h4>
                <br/>
                <p>上長が承認するまではカートは空になりません。</p>
              </div>
            </div>
          },
          {
            id: 2,
            title: '発注書一覧の見方',
            link: '/support/order/2',
            data: <div>
              <p>サイドメニューから<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 発注書一覧 </span>を開きます。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2F1発注書一覧-1.jpg?alt=media&token=612f00f0-eb2f-44d0-8b34-2f285d5479eb'}/>
              <br/><br/>
              <p>発注書一覧が表示されます。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2F2発注書一覧-1.jpg?alt=media&token=1180f1b9-d24d-4532-bbcc-83036fd35892'}/>
              <br/><br/>
              <p>① 既読にする</p>
              <p>チェックボックスにチェックを入れ「既読にする」をクリックすると、太字が解除されます。同時にバッジ(⑦)が減少します。</p>
              <br/>
              <p>② 発注書受取者 / 受注確認者</p>
              <p>ラベルの上に購入時のカートで指定した発注書受取者が表示されます。</p>
              <p>ラベルの下に受注を確認したユーザーの名前が表示されます。</p>
              <p>受注が確認されると、ラベルが<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 未確認 </span>から<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 確認済 </span>に変わります。</p>
              <br/>
              <p>③ 状態</p>
              <p>上長の承認/却下の状況に応じて以下のラベルが表示されます</p>
              <p><span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 発注済 </span>、<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 却下 </span>ラベルの上には承認/却下をした上長の名前が表示されます。</p>
              <p>・承認待</p>
              <p>・発注済</p>
              <p>・却下</p>
              <br/>
              <p>④ 上長確認</p>
              <p>上長が発注書を開くと、ラベルが<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 未確認 </span>から<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 確認済 </span>に変わります。</p>
              <br/>
              <p>⑤ 操作</p>
              <p>上長権限のユーザーがログインすると、承認待ちの発注に<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 承認 </span>、<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 却下 </span>ボタンが表示されます。</p>
              <br/>
              <p>⑥ 出荷ステータス</p>
              <p>ランプの色で出荷完了ステータスを表します。</p>
              <p>[発注書一覧]</p>
              <p><span style={{color: 'green'}}>●</span>緑のランプ：すべての商品が出荷済みの状態</p>
              <p><span style={{color: 'red'}}>●</span>赤のランプ：未出荷の商品が含まれている状態</p>
              <p>[発注書詳細]</p>
              <p><span style={{color: 'green'}}>●</span>緑のランプ：商品が出荷済みの状態</p>
              <p><span style={{color: 'red'}}>●</span>赤のランプ：商品が未出荷の状態</p>
              <br/>
              <p>※販売側が”出荷日/送り状番号”を送信すると、ランプが赤から緑に変わります。</p>
              <br/>
              <p>⑦ 未読</p>
              <p>販売側が以下のアクションをしたとき、バッジが点灯します。同時に対象の注文が太字になります。</p>
              <p>・受注の確認をしたとき</p>
              <p>・出荷日/送り状番号を送信したとき</p>
              <br/>
              <p>⑧ 未完</p>
              <p>未出荷の商品が含まれている発注書の数が表示されます。</p>
            </div>
          },
          {
            id: 3,
            title: '発注を承認/却下する（上長権限のみ）',
            link: '/support/order/3',
            data: <div>
              <p>上長権限者は以下の画面から承認/却下をすることができます。</p>
              <ul>
                <li>発注書一覧</li>
                <li>発注書詳細</li>
                <li>カート画面（※）</li>
              </ul>
              <div style={{backgroundColor: '#fffaeb', borderLeft: '10px solid #FFD54F', padding: 15}}>
                <h4>POINT</h4>
                <br/>
                <p>上長権限者はカート画面の上長承認依頼ボタンをクリックすると、承認/却下ボタンが表示されます。</p>
                <p>発注書一覧・発注書詳細に遷移せずに、カート画面で承認/却下を行うことができます。</p>
                <p style={{color: '#ff0000'}}>発注書一覧の上長確認ステータスラベルは、承認後に発注書を開くまで確認済になりません。</p>
                <img style={{width: '100%', maxWidth: 850, border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2Fimage.png?alt=media&token=b62880b1-4898-4d74-90c3-3fe8711bc6bc'}/>
              </div>
              <br/>
              <p>サイドメニューから<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 発注書一覧 </span>を開きます。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2F1発注書一覧-1%20(1).jpg?alt=media&token=58c7cf90-8f3e-413e-966b-1806ee5ba847'}/>
              <br/><br/>
              <p><span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 表示 </span>をクリックして発注書を開きます。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2F3発注書一覧.jpg?alt=media&token=174d56c3-b1c5-46dd-8a76-6bac19ac82a2'}/>
              <br/><br/><br/><br/>
              <h3>■承認するとき</h3>
              <br/>
              <p><span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 承認（発注確定）確認 </span>をクリックします。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2F%E7%99%BA%E6%B3%A8%E6%9B%B8_%E4%B8%8A%E9%95%B7.png?alt=media&token=7b1e4e59-a538-4b7d-96e7-e6882f9b4894'}/>
              <br/><br/>
              <p>ポップアップ画面が出たら、<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 承認(発注確定)する </span>をクリックします。</p>
              <img style={{width: '100%', maxWidth: 499, border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2F10-2.png?alt=media&token=bf3d0f8f-cc71-4f59-afd1-4df6d32f0fca'}/>
              <br/><br/>
              <p>発注者に「承認」のメール通知が届きます。（受信設定をONにしている場合）</p>
              <p>相手先（販売側）に注文情報が送信されます</p>
              <p>ステータスラベルが<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 承認待 </span>から<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 発注済 </span>に変わります。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2F4%E7%99%BA%E6%B3%A8%E6%9B%B8%E4%B8%80%E8%A6%A7.jpg?alt=media&token=670fdcee-dabf-44be-9ed9-a3d67e4bfb8f'}/>
              <br/><br/>
              <h3>■ 却下するとき</h3>
              <br/>
              <p><span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 却下（担当者に差戻）確認 </span>をクリックします。</p>
              <img style={{width: '100%'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2F%E7%99%BA%E6%B3%A8%E6%9B%B8_%E4%B8%8A%E9%95%B7%E5%8D%B4%E4%B8%8B.png?alt=media&token=5ef5a88f-f523-4c02-b5d8-7c73714c6c74'}/>
              <br/><br/>
              <p>ポップアップ画面が出たら、<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 却下（担当者に差戻）する </span>をクリックします。</p>
              <img style={{width: '100%', maxWidth: 501, border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2F11-2.png?alt=media&token=9e0bd372-3f56-4cfd-acba-450238402ff9'}/>
              <br/><br/>
              <p>発注者に「却下」のメール通知が届きます。（受信設定をONにしている場合）</p>
              <p>ステータスラベルが<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 承認待 </span>から<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 却下 </span>に変わります。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2F5発注書一覧.jpg?alt=media&token=1ecab89a-25c5-4ef7-9ea2-f368cd5e9d3f'}/>
              <br/><br/>
              <p>却下された発注はカートにそのまま残り、<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 却下（差戻） </span>ラベルが表示されます。</p>
              <img style={{width: '100%', maxWidth: 840, border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2Fcart_rejected-1680x2048.png?alt=media&token=4799a7e8-229e-4c6c-b644-0a952d322950'}/>
              <br/><br/>
              <div style={{backgroundColor: '#fffaeb', borderLeft: '10px solid #FFD54F', padding: 15}}>
                <h4>POINT</h4>
                <br/>
                <p>発注書一覧画面から承認/却下することもできます。</p>
                <p>発注書一覧から承認した場合、発注書を開かなくても上長確認ステータスラベルが確認済になります。</p>
                <p>発注書一覧から却下した場合、上長が発注書詳細を開くまで確認済になりません。</p>
                <img style={{width: '100%', maxWidth: 900, border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2F6発注書一覧.jpg?alt=media&token=37608c57-8994-4f7a-b7be-936da25e4bca'}/>
              </div>
            </div>
          },
          {
            id: 4,
            title: '却下された発注を修正する',
            link: '/support/order/4',
            data: <div>
              <p>サイドメニューから却下された発注商品が入っているカートを開きます。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2F1%E3%82%B5%E3%82%A4%E3%83%88%E3%82%99%E3%83%A1%E3%83%8B%E3%83%A5%E3%83%BC.jpg?alt=media&token=c83feaf4-6e23-44fc-922d-be50b6cb6a96'}/>
              <br/><br/>
              <p>各項目を編集します。商品一覧からカートに商品を追加することもできます。</p>
              <p>カートの編集が終わったら<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 上長承認依頼 </span>をクリックします</p>
              <p>上長に「発注内容上長承認依頼」のメール通知が届きます。（受信設定をONにしている場合</p>
              <img style={{width: '100%', maxWidth: 865, border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2F112-3-865x1024.png?alt=media&token=997e0041-5c1d-45ba-bf4e-01ab9c6f79a9'}/>
              <br/><br/>
              <p>ボタンが<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 上長承認依頼 </span>から<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 承認依頼中 </span>に変わります。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2F113.png?alt=media&token=8811623d-996e-4c19-ace8-2850d2e1aea3'}/>
              <br/><br/>
              <p>上長が承認すると発注が確定し、注文情報が相手先に送信されます。</p>
            </div>
          },
          {
            id: 6,
            title: '分納された注文を確認する',
            link: '/support/order/6',
            data: <div>
              <div style={{background: '#fffaeb', border: '3px solid #FFD54F', }}>
                <h4 style={{padding: 15, backgroundColor: '#FFD54F'}}>分納について</h4>
                <div style={{padding: 15}}>
                  <p>販売側は注文を商品ごとに複数に分けて発送処理を行うことができます。</p>
                  <p>販売側が分納手続きを開始すると、<span style={{color: 'red'}}>発注者（カート作成者）にメール通知が届きます</span>。</p>
                  <p>分納手続きがすべて確定すると、<span style={{color: 'red'}}>発注者（カート作成者）にメール通知が届きます</span>。</p>
                </div>
              </div>
              <br/><br/>
              <h4>発注書一覧の見方</h4>
              <br/>
              <p>①販売側が分納を開始すると、発注書内のすべての分納が確定するまで <img style={{height: 20}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2ForderReceived%2F%E3%82%AF%E3%82%99%E3%83%AB%E3%83%BC%E3%83%95%E3%82%9A-900.png?alt=media&token=8478eff5-4209-486a-8820-10600fa2b47f'}/> が表示されます。</p>
              <p>例：<img style={{height: 20}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2ForderReceived%2F%E3%82%AF%E3%82%99%E3%83%AB%E3%83%BC%E3%83%95%E3%82%9A-899.png?alt=media&token=906d0f54-cf19-4dd6-b5c8-777e08e46058'}/>　発注書内に分納行が3行あり、内2行が分納確定している状態</p>
              <br/>
              <p>②すべての商品行の分納が確定すると、<img style={{height: 20}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2ForderReceived%2F%E3%82%AF%E3%82%99%E3%83%AB%E3%83%BC%E3%83%95%E3%82%9A-901.png?alt=media&token=0c96a55e-f893-4a5d-a667-e9bea81189dc'}/>が表示されます。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2ForderReceived%2Fimage.png?alt=media&token=c49dcdc3-c86b-4cbd-945b-c280311e25d1'}/>
              <br/><br/><br/>
              <h4>発注書の見方</h4>
              <br/>
              <p>①販売側が分納が確定するまで、分納手続き中の商品行に<img style={{height: 33}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2ForderReceived%2Fimage-2.png?alt=media&token=11ce0811-b024-4250-9290-f94387577749'}/>が表示されます。</p>
              <br/>
              <p>②販売側が分納が確定した行は<img style={{height: 31}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2ForderReceived%2Fimage-4.png?alt=media&token=e1d6c40a-4c1c-4138-80c9-d9c4d7142c21'}/>が表示されます。</p>
              <p>※<img style={{height: 31}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2ForderReceived%2Fimage-4.png?alt=media&token=e1d6c40a-4c1c-4138-80c9-d9c4d7142c21'}/>が表示されていても出荷前のときは、販売側はさらに分納手続きをすることができます。</p>
              <br/>
              <p>③分納手続き中に発注書を表示・印刷をすると、 <img style={{height: 22}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2ForderReceived%2F%E5%88%86%E7%B4%8D%E7%A2%BA%E5%AE%9A.png?alt=media&token=5e9027a1-6fcc-49a8-8343-d7d24f7f1e3a'}/> が表示されます。</p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;分納確定後に発注書を表示・印刷をすると、 <img style={{height: 22}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2ForderReceived%2F%E5%88%86%E7%B4%8D%E7%A2%BA%E5%AE%9A.png?alt=media&token=5e9027a1-6fcc-49a8-8343-d7d24f7f1e3a'}/> が表示されます。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2ForderReceived%2Fimage-3.png?alt=media&token=f5596ec1-7c83-4fc4-bfc5-1b78c9bc29cf'}/>
              <br/><br/>
            </div>
          },
          {
            id: 7,
            title: '発注書をダウンロードする',
            link: '/support/order/7',
            data: <div>
              <p>サイドメニューから<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 発注書一覧 </span> を開きます。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2F0_%E7%99%BA%E6%B3%A8%E6%9B%B8%E3%82%92%E3%82%BF%E3%82%99%E3%82%A6%E3%83%B3%E3%83%AD%E3%83%BC%E3%83%88%E3%82%99%E3%81%99%E3%82%8B-scaled.jpg?alt=media&token=650d7149-f0b1-409c-8f8d-b4e931122818'}/>
              <br/><br/>
              <p>ダウンロードする発注書にチェックを入れて、<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 選択したPDF一括ダウンロード </span>ボタンをクリックします。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2F1_%E7%99%BA%E6%B3%A8%E6%9B%B8%E3%82%92%E3%82%BF%E3%82%99%E3%82%A6%E3%83%B3%E3%83%AD%E3%83%BC%E3%83%88%E3%82%99%E3%81%99%E3%82%8B-2-scaled.jpg?alt=media&token=64445095-3aaf-45d4-bde9-4b90c9606e82'}/>
            </div>
          },
          {
            id: 5,
            title: 'お助けカートを使ってカートを作成する',
            link: '/support/otasuke-cart/1',
            gap: true,
            data: <div>
              <div style={{background: '#fffaeb', border: '3px solid #FFD54F', }}>
                <h4 style={{padding: 15, backgroundColor: '#FFD54F'}}>お助けカートとは</h4>
                <div style={{padding: 15}}>
                  <p>販売側が仕入側のカート作成を補助する機能です。</p>
                  <p>販売側が商品を選び「お助けカート」を作成し、仕入側に送信することができます。</p>
                  <p>仕入側はお助けカートをそのままカートに投入することで、商品を探す手間を省くことができます。</p>
                </div>
              </div>
              <br/>
              <p>販売側からお助けカートが届くと、メールで通知が届き、ヘッダーとサイドメニューにバッジが点灯します。</p>
              <p>サイドメニューの <span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}>お助けカート</span> → <span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}>お助けカート一覧</span> を開きます。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2F1%E3%81%8A%E5%8A%A9%E3%81%91%E3%82%AB%E3%83%BC%E3%83%88-1.jpg?alt=media&token=abd0b5a7-ad50-4529-9ce0-b7082c5806c1'}/>
              <br/><br/>
              <p>新規で届いたお助けカートは太字で表示されます。 <span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}>表示</span> をクリックして、お助けカートを開きます。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2F2%E3%81%8A%E5%8A%A9%E3%81%91%E3%82%AB%E3%83%BC%E3%83%88-1.jpg?alt=media&token=b966bfcf-c9ac-455a-b550-c2bb5f2af780'}/>
              <br/><br/>
              <p>お助けカートが表示されます。内容を確認し、 <span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}>カートに追加する</span> をクリックします。</p>
              <p style={{color: '#ff0000'}}>数量等の変更はカートに投入した後に行うことができます。</p>
              <img style={{width: '100%', maxWidth: 796, border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2F3%E3%81%8A%E5%8A%A9%E3%81%91%E3%82%AB%E3%83%BC%E3%83%88.jpg?alt=media&token=6a712e4c-1768-4461-b55e-a328bf458826'}/>
              <br/><br/>
              <div style={{background: '#fffaeb', border: '3px solid #FFD54F', }}>
                <h4 style={{padding: 15, backgroundColor: '#FFD54F'}}> POINT</h4>
                <div style={{padding: 15}}>
                  <p> <span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}>この納品場所をアドレス帳に追加する</span> をクリックすると、販売側が設定した納品場所をアドレス帳に登録することができます。</p>
                  <img style={{width: '100%', maxWidth: 800, border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2F4%E3%81%8A%E5%8A%A9%E3%81%91%E3%82%AB%E3%83%BC%E3%83%88.jpg?alt=media&token=29f6eb15-e466-482e-8a0c-ad6fba5b74d2'}/>
                </div>
              </div>
              <br/>
              <p>カート選択のポップアップが開きます。 <span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}>選択</span> をクリックすると、カートにお助けカートの内容が追加されます。</p>
              <p>※選択したカートにすでに商品が入っている場合、すべて破棄しないとお助けカートの内容を追加することができません。</p>
              <img style={{width: '100%', maxWidth: 542, border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2F5%E3%81%8A%E5%8A%A9%E3%81%91%E3%82%AB%E3%83%BC%E3%83%88.jpg?alt=media&token=be0ccce9-c66e-42f1-ae54-39134436da4a'}/>
            </div>
          }
        ],
        orderReceived: [
          {
            id: 1,
            title: '受注を確認する',
            link: '/support/orderReceived/1',
            data: <div>
              <div style={{backgroundColor: '#fffaeb', borderLeft: '10px solid #FFD54F', padding: 15}}>
                <h4>POINT</h4>
                <p>通知メール受信設定をONにしている場合、新規注文が入ると購入側が指定した担当者宛てに通知メールが届きます。</p>
                <p>同時に以下の箇所にバッジが点灯します。</p>
                <img style={{width: '100%', maxWidth: 900, border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2F%E6%B3%A8%E6%96%87%E6%9B%B8%E4%B8%80%E8%A6%A7_%E3%83%8F%E3%82%99%E3%83%83%E3%82%B7%E3%82%99%E3%81%82%E3%82%8A-2.png?alt=media&token=1aa408aa-bfe0-4ca7-9fc8-3cf9b6f42d1c'}/>
              </div>
              <br/>
              <p>サイドメニューから <span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 注文書一覧 </span> を選択します。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2F1受注を確認する.jpg?alt=media&token=dbee11bf-cec4-4a02-801b-a9ba0cbfe455'}/>
              <br/><br/>
              <p>注文書一覧の <span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 表示 </span> をクリックします。</p>
              <br/>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2F2受注を確認する.jpg?alt=media&token=6d3f9d97-cea5-4003-bef8-9a3c3f99e82d'}/>
              <br/><br/>
              <p>注文書が表示されます。<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 受注確認 </span>をクリックします。</p>
              <img style={{width: '100%', maxWidth: 586, border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2F注文書-1014x1024.png?alt=media&token=a9736b68-6084-43f9-aee3-b9a4c7469a4d'}/>
              <br/><br/>
              <p><span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 受注確認 </span>をクリックすると、注文書一覧の太字が解除されます。リロードをするとバッジが減少します。（①）</p>
              <br/>
              <p><span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 受注未確認 </span>ラベルが<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 受注確認済 </span>に変わります。（②）</p>
              <p>ラベルの下には<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 受注確認 </span>を押下したユーザーの名前が表示されます</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2F3受注を確認する.jpg?alt=media&token=3c0aba30-1136-4995-b884-606459b9a03f'}/>
              <br/><br/>
              <div style={{backgroundColor: '#fffaeb', borderLeft: '10px solid #FFD54F', padding: 15}}>
                <h4>POINT</h4>
                <p>既読にすると太字が解除されますが、受注確認を押下するまではバッジは減少しません。</p>
              </div>
            </div>
          },
          {
            id: 2,
            title: '納期/出荷日/送り状番号を回答する',
            link: '/support/orderReceived/2',
            data: <div>
              <p>注文書一覧の<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 表示 </span>をクリックして注文書を開きます。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2F1納期出荷日送り状番号を回答する-1.jpg?alt=media&token=cb2a2922-1d2b-4863-b4cd-9822a1629c05'}/>
              <br/><br/>
              <p>納期回答の入力フォームをクリックし、カレンダーから日付を選択して納期を入力します。</p>
              <p><span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 登録 </span>をクリックすると相手先（購入側）の発注書に納期が登録されます。</p>
              <p>※納期回答は何度でも変更することができます。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2F2納期出荷日送り状番号を回答する.jpg?alt=media&token=9a6bd483-cae6-48ea-a8fe-d9f928affa6a'}/>
              <br/><br/>
              <h4>■ 出荷日が決まったら</h4>
              <br/>
              <p>① 出荷日の入力フォームをクリックし、カレンダーから日付を選択して出荷日を入力します。</p>
              <p>② 送り状番号の入力フォームをクリックし、送り状番号を入力します。</p>
              <br/>
              <p><span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 送信 </span>をクリックすると相手先（購入側）の発注書に出荷日と送り状番号が登録されます。</p>
              <p>同時に相手先（購入側）にメールで通知されます。（※受信設定でONにしている場合）</p>
              <p>※出荷日／送り状番号は何度でも変更することができます。</p>
              <p>　ただし、送信をクリックするとその都度、相手先（購入側）にメールで通知されます。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2F3納期出荷日送り状番号を回答する.jpg?alt=media&token=2190ce12-4552-440c-a075-d2e6332a4bed'}/>
              <br/><br/>
              <p>出荷日を相手先（購入側）に送信すると、出荷処理ステータスの表示が赤から緑のランプに変わります。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2F4納期出荷日送り状番号を回答する.jpg?alt=media&token=35337f6b-d3e5-4512-b324-08a82f96938b'}/>
              <br/><br/>
            </div>
          },
          {
            id:4,
            title: '分納をする',
            link: '/support/orderReceived/4',
            data: <div>
              <div style={{background: '#fffaeb', border: '3px solid #FFD54F', }}>
                <h4 style={{padding: 15, backgroundColor: '#FFD54F'}}>分納について</h4>
                <div style={{padding: 15}}>
                  <p>販売側は注文を商品ごとに複数に分けて発送処理を行うことができます。</p>
                  <p>分納ができるのは<span style={{color: 'red'}}>出荷前（出荷日/送り状番号入力前）の商品行</span>のみです。</p>
                  <p>分納処理ができるのは<span style={{color: 'red'}}>上長権限者</span>もしくは<span style={{color: 'red'}}>発注書受取者に自身に指定されている場合</span>のみです。</p>
                </div>
              </div>
              <br/><br/>
              <h4>分納手続きを開始する</h4>
              <br/>
              <p>サイドメニューの注文書一覧から注文書を開きます。</p>
              <p>商品行の右端にあるメニューアイコン【<img src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2ForderReceived%2Fimage-1.png?alt=media&token=5bdfc0c9-9763-4c9a-acf0-52badedc5a07'}/>】をクリックし、<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 分納 </span>を選択します。</p>
              <p>分納手続き開始のポップアップが表示され、<span style={{color: 'red'}}>発注者発注者（カート作成者）にメール通知されます</span>。</p>
              <p>※分納を取りやめるときは、元の商品行のメニュー【<img src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2ForderReceived%2Fimage-1.png?alt=media&token=5bdfc0c9-9763-4c9a-acf0-52badedc5a07'}/>】から<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 分納をやめる </span>を選択します。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2ForderReceived%2Fimage-9.png?alt=media&token=d2a272aa-c81a-4ef8-b545-72d535a8e193'}/>
              <br/><br/>
              <p>分納手続きを開始すると商品行が分割され、販売側、購入側の分納手続き中の商品行に<img src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2ForderReceived%2Fimage-2.png?alt=media&token=11ce0811-b024-4250-9290-f94387577749'}/>が表示されます。（a）</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2ForderReceived%2Fimage-6.png?alt=media&token=74542308-4795-43ed-948b-ad631291bf99'}/>
              <br/><br/><br/>
              <h4>数量を入力する</h4>
              <br/>
              <p>分割された商品行に数量を入力します。</p>
              <p>※入力した数量が合計数量と異なるとき、修正するまで分納確定することができません。</p>
              <br/>
              <p>行を増やすときは、メニューアイコン【<img src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2ForderReceived%2Fimage-1.png?alt=media&token=5bdfc0c9-9763-4c9a-acf0-52badedc5a07'}/>】から<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 分納行を増やす </span>を選択します。</p>
              <p>間違って余分に分割した場合は、メニューアイコン【<img src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2ForderReceived%2Fimage-1.png?alt=media&token=5bdfc0c9-9763-4c9a-acf0-52badedc5a07'}/>】から<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> この分納行を削除 </span>を選択して商品行を削除します。</p>
              <img style={{width: '100%', maxWidth: 277, border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2ForderReceived%2Fimage-7.png?alt=media&token=86c424f2-493f-403f-a903-b72d16de3a8c'}/>
              <br/><br/>
              <div style={{backgroundColor: '#fffaeb', borderLeft: '10px solid #FFD54F', padding: 15}}>
                <h4>POINT</h4>
                <p>・出荷日/送り状番号の入力欄およびコメント欄は、<span style={{color: 'red'}}>分納手続きが確定するまで入力することができません</span>。</p>
                <p>・分納手続き中に注文書を表示・印刷をすると、<img style={{height: 23}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2ForderReceived%2F%E5%88%86%E7%B4%8D%E6%89%8B%E7%B6%9A%E4%B8%AD.png?alt=media&token=b0b218a6-032d-4f35-ab05-5d1a72270bf4'}/> が表示されます。</p>
              </div>
              <br/><br/>
              <h4>分納を確定する</h4>
              <br/>
              <p>数量の入力が終わったら、メニューアイコン【<img src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2ForderReceived%2Fimage-1.png?alt=media&token=5bdfc0c9-9763-4c9a-acf0-52badedc5a07'}/>】を開き<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 分納確定 </span>を選択します。</p>
              <p>分納確定は分割された商品行ごとに行います。</p>
              <p>分納確定した商品行には<img style={{height: 31}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2ForderReceived%2Fimage-4.png?alt=media&token=e1d6c40a-4c1c-4138-80c9-d9c4d7142c21'}/>が表示されます。（b）</p>
              <p>分割された行の分納手続きがすべて確定すると、<span style={{color: 'red'}}>発注者（カート作成者）に分納手続確定のメール通知が送信されます</span>。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2ForderReceived%2Fimage-12.png?alt=media&token=b7d32f1b-3ae1-4601-9ccd-7e76b8059fc5'}/>
              <br/><br/>
              <div style={{backgroundColor: '#fffaeb', borderLeft: '10px solid #FFD54F', padding: 15}}>
                <h4>POINT</h4>
                <p>分納確定後に注文書を表示・印刷をすると、 <img style={{height: 23}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2ForderReceived%2F%E5%88%86%E7%B4%8D%E7%A2%BA%E5%AE%9A.png?alt=media&token=5e9027a1-6fcc-49a8-8343-d7d24f7f1e3a'}/> が表示されます。</p>
              </div>
              <br/><br/>
              <h4>注文書一覧の見方</h4>
              <br/>
              <p>①分納を開始すると、注文書内のすべての分納が確定するまで <img style={{height: 20}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2ForderReceived%2F%E3%82%AF%E3%82%99%E3%83%AB%E3%83%BC%E3%83%95%E3%82%9A-900.png?alt=media&token=8478eff5-4209-486a-8820-10600fa2b47f'}/> が表示されます。</p>
              <p>例：<img style={{height: 20}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2ForderReceived%2F%E3%82%AF%E3%82%99%E3%83%AB%E3%83%BC%E3%83%95%E3%82%9A-899.png?alt=media&token=906d0f54-cf19-4dd6-b5c8-777e08e46058'}/>　注文書内に分納行が3行あり、内2行が分納確定している状態</p>
              <br/>
              <p>②すべての商品行の分納が確定すると、<img style={{height: 20}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2ForderReceived%2F%E3%82%AF%E3%82%99%E3%83%AB%E3%83%BC%E3%83%95%E3%82%9A-901.png?alt=media&token=0c96a55e-f893-4a5d-a667-e9bea81189dc'}/>が表示されます。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2ForderReceived%2Fimage-11.png?alt=media&token=675dbc5c-8875-4788-a51b-6a35f98c6b67'}/>
            </div>
          },
          {
            id:5,
            title: '注文書をダウンロードする',
            link: '/support/orderReceived/5',
            data: <div>
              <p>サイドメニューから<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 注文書一覧 </span>を開きます。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2F0_%E6%B3%A8%E6%96%87%E6%9B%B8%E3%82%92%E3%82%BF%E3%82%99%E3%82%A6%E3%83%B3%E3%83%AD%E3%83%BC%E3%83%88%E3%82%99%E3%81%99%E3%82%8B-scaled.jpg?alt=media&token=4f410f78-c22e-4e47-b7be-56bafca7e06c'}/>
              <br/><br/>
              <p>ダウンロードする注文書にチェックを入れて、<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 選択したPDF一括ダウンロード </span>ボタンをクリックします。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2F1_%E6%B3%A8%E6%96%87%E6%9B%B8%E3%82%92%E3%82%BF%E3%82%99%E3%82%A6%E3%83%B3%E3%83%AD%E3%83%BC%E3%83%88%E3%82%99%E3%81%99%E3%82%8B-scaled.jpg?alt=media&token=c6d510c5-6e61-4675-8d49-28d65c153742'}/>
            </div>
          },
          {
            id: 3,
            title: 'お助けカートを作成/送信する',
            link: '/support/otasuke-cart/2',
            gap: true,
            data: <div>
              <div style={{backgroundColor: '#fffaeb', borderLeft: '10px solid #FFD54F', padding: 15}}>
                <h4>お助けカートとは</h4>
                <br/>
                <p>販売側が仕入側のカート作成を補助する機能です。</p>
                <p>販売側が商品を選び「お助けカート」を作成し、仕入側に送信することができます。</p>
                <p>仕入側はお助けカートをそのままカートに投入することで、商品を探す手間を省くことができます。</p>
              </div>
              <br/>
              <p>サイドメニューから <span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}>商品購入</span> → <span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}>商品一覧</span> を開きます。</p>
              <p>各商品行の <span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}>お助けカートに入れる</span> をクリックします。</p>
              <p>※商品一覧のチェックボックスをクリックすると、まとめてお助けカートに入れることができます。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2Fs_side_items_2-2048x1136.jpg?alt=media&token=b1087067-835d-40ce-b8a1-ae379872be99'}/>
              <br/><br/>
              <p>お助けカート選択のポップアップ画面が表示されます。</p>
              <p><span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 選択 </span>ボタンをクリックします。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2Fs_popup_otasuke_1-1-2048x1135.jpg?alt=media&token=cc64241b-af8b-40b7-88f7-71ecc98092f5'}/>
              <br/><br/>
              <p>商品をお助けカートへ投入したら、サイドメニューからお助けカートを開きます。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2Fs_side_otasuke_2-2048x1184.jpg?alt=media&token=60c61626-f903-4274-aa36-bd17cbc570d9'}/>
              <br/><br/>
              <p>お助けカートの内容が表示されます。</p>
              <p>①<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> カート名称 </span>：お助けカートの名称を設定することができます。名称は送信先（仕入側）にも表示されます。</p>
              <p>②<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 商品を追加する </span>：お助けカート内から商品を追加することができます。</p>
              <p>③<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> お助けカート送信先 </span>：お助けカート送信先を選択します。</p>
              <p>④<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> このお助けカートを送信する </span>：お助けカート送信先で指定した相手先にお助けカートが届きます。同時にメールで通知が送信されます。</p>
              <img style={{width: '100%', maxWidth: 768, border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2Fs_otasuke_1-1-1536x2048.jpg?alt=media&token=52fd24bd-6990-407f-b55f-794b4d7b86e7'}/>
              <br/><br/>
              <div style={{backgroundColor: '#fffaeb', borderLeft: '10px solid #FFD54F', padding: 15}}>
                <h4>仕入側にお助けカートが届くと</h4>
                <br/>
                <p>お助けカートを送信すると、送信先の事業所内アカウントすべてがお助けカートを開き確認することができます</p>
                <p>仕入側はお助けカートを編集することはできませんが、お助けカートをカートに投入することによってカート内容を編集することができます。</p>
              </div>
            </div>
          }
        ],
        quotation: [
          // {
          //   id: 1,
          //   title: '見積書/請求書/報告書を登録する',
          //   link: '/support/quotation/1',
          //   data: <div>
          //     <p>※販社ユーザー、メーカーユーザー向け</p>
          //     <br/>
          //     <div style={{backgroundColor: '#fffaeb', borderLeft: '10px solid #FFD54F', padding: 15}}>
          //       <h4>POINT</h4>
          //       <br/>
          //       <p>ここでは見積書を例に説明していますが、請求書/報告書も同様の操作になります</p>
          //     </div>
          //     <br/>
          //     <p>見積書を発行する相手先企業を選択します。<span style={{color: 'red'}}>※取引先が複数ある場合のみ</span></p>
          //     <img style={{width: '100%', maxWidth: 930, border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fquotation%2F%E8%B2%A9%E5%A3%B2%E3%83%A2%E3%83%BC%E3%83%88%E3%82%99_%E3%83%98%E3%83%83%E3%82%BF%E3%82%99%E3%83%BC.jpg?alt=media&token=098bb40c-dbc4-4013-ba61-6fa3289ce7fc'}/>
          //     <br/><br/>
          //     <p>サイドメニューから<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 見積書登録 </span>を選択します。</p>
          //     <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fquotation%2F%E8%B2%A9%E7%A4%BEU_%E8%A6%8B%E7%A9%8D%E6%9B%B8_20230317_1.jpg?alt=media&token=38cccfc3-6635-4c91-b6c5-1c18f4be83dd'}/>
          //     <br/><br/>
          //     <p>① 各項目を入力します。<span style={{color: 'red'}}>相手先CC選択以外に未入力項目があると登録できません</span></p>
          //     <br/>
          //     <p>② PDF形式の見積書をアップロードします</p>
          //     <p>【アップロード方法】</p>
          //     <p>方法１．円の中にPDFファイルをドラッグ＆ドロップします。</p>
          //     <p>方法２．円をクリックすると別ウィンドウが開きます。 ファイルを選択して[開く]をクリックすると アップロードされます。</p>
          //     <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fquotation%2F3%E8%A6%8B%E7%A9%8D%E6%9B%B8%E8%AB%8B%E6%B1%82%E6%9B%B8%E5%A0%B1%E5%91%8A%E6%9B%B8%E3%82%92%E7%99%BB%E9%8C%B2%E3%81%99%E3%82%8B.jpg?alt=media&token=be335435-6d81-47ab-95bf-4df074a4c80b'}/>
          //     <br/><br/>
          //     <p>見積書がアップロードされるとファイル名が表示されます。</p>
          //     <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fquotation%2F4_%E8%A6%8B%E7%A9%8D%E6%9B%B8%E7%99%BB%E9%8C%B2-2048x586.png?alt=media&token=5094c260-c54e-4f80-be6f-844bc18acc8b'}/>
          //     <br/><br/>
          //     <p><span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 新規見積書登録 </span>をクリックすると見積書が登録されます。</p>
          //     <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fquotation%2F5_%E8%A6%8B%E7%A9%8D%E6%9B%B8%E7%99%BB%E9%8C%B2-2048x586.png?alt=media&token=e9e424dc-c9d9-4656-9393-eec7ed4e4fdd'}/>
          //     <br/><br/>
          //     <p>サイドメニューから<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 見積書一覧 </span>を開くと、登録した見積書が確認できます。</p>
          //     <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fquotation%2F%E8%B2%A9%E7%A4%BEU_%E8%A6%8B%E7%A9%8D%E6%9B%B8_20230317_3.jpg?alt=media&token=f07c94d6-fba7-49de-a1aa-4b883e57550f'}/>
          //     <br/><br/>
          //     <div style={{backgroundColor: '#fffaeb', borderLeft: '10px solid #FFD54F', padding: 15}}>
          //       <h4>POINT</h4>
          //       <br/>
          //       <p>一覧に表示されるのは、<span style={{color: 'red'}}>相手先選択モードで選択している事業所</span>の見積書/請求書/報告書のみです。</p>
          //       <p>他の事業所の見積書/請求書/報告書を確認するときは、相手先選択モードで切り替えてページを再読込みします</p>
          //       <img style={{width: '100%', maxWidth: 800, border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fquotation%2F%E8%B2%A9%E5%A3%B2%E3%83%A2%E3%83%BC%E3%83%88%E3%82%99_%E3%83%98%E3%83%83%E3%82%BF%E3%82%99%E3%83%BC-1.jpg?alt=media&token=6144d7bf-e107-4e3f-b5e0-9c446bba4676'}/>
          //     </div>
          //   </div>
          // },
          {
            id: 2,
            title: '工事見積書を登録する',
            type: '販売側向け',
            link: '/support/quotation/2',
            data: <div>
              <p>※販社ユーザー、メーカーユーザー向け</p>
              <br/><br/>
              <p>工事見積書を発行する相手先企業を選択します。<span style={{color: 'red'}}>※取引先が複数ある場合のみ</span></p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fquotation%2F%E8%B2%A9%E5%A3%B2%E3%83%A2%E3%83%BC%E3%83%88%E3%82%99_%E3%83%98%E3%83%83%E3%82%BF%E3%82%99%E3%83%BC%20.jpg?alt=media&token=6bf36102-c190-4834-a11a-bdb008824019'}/>
              <br/><br/>
              <p>サイドメニューから<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 工事見積書登録 </span>を選択します。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fquotation%2F販社U_工事見積書_20230317_1.jpg?alt=media&token=3891d639-d671-4a81-ac4c-2da8ba06bfc0'}/>
              <br/><br/>
              <p>各項目を入力します。<span style={{color: 'red'}}>※未入力項目があると登録できません。</span></p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fquotation%2F3工事見積書を登録する.jpg?alt=media&token=79c2a623-4817-478e-b3d0-99b02058e2a8'}/>
              <br/><br/>
              <p>PDF形式の工事見積書をアップロードします。</p>
              <p>【アップロード方法】</p>
              <p>方法１．円の中にPDFファイルをドラッグ＆ドロップします。</p>
              <p>方法２．円をクリックすると別ウィンドウが開きます。 ファイルを選択して[開く]をクリックすると アップロードされます。</p>
              <br/>
              <p>工事見積書がアップロードされるとファイル名が表示されます。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fquotation%2F販社U_工事見積書_20230317_3.jpg?alt=media&token=328ce516-e3f2-4a4a-830f-994e297e4b9b'}/>
              <br/><br/>
              <p><span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 新規工事見積書登録 </span>をクリックすると工事見積書が登録されます。</p>
              <p>同時に、相手先担当者選択欄で指定したアカウントに通知メールが届きます。（相手先担当者が通知メール設定をオンにしている場合）</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fquotation%2F販社U_工事見積書_20230317_4-1024x290.jpg?alt=media&token=4b27821e-d967-483f-a92a-64e53e008175'}/>
              <br/><br/>
              <div style={{backgroundColor: '#fffaeb', borderLeft: '10px solid #FFD54F', padding: 15}}>
                <h4>POINT</h4>
                <br/>
                <p>一覧に表示されるのは、<span style={{color: 'red'}}>相手先選択モードで選択している事業所</span>の工事見積書のみです。</p>
                <p>他の事業所の工事見積書を確認するときは、相手先選択プルダウンリストから選択します。</p>
                <img style={{width: '100%', maxWidth: 900, border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fquotation%2F%E8%B2%A9%E5%A3%B2%E3%83%A2%E3%83%BC%E3%83%88%E3%82%99_%E3%83%98%E3%83%83%E3%82%BF%E3%82%99%E3%83%BC-1.jpg?alt=media&token=eb01df8a-2986-4455-8a93-c87ffe097f1d'}/>
              </div>
            </div>
          },
          {
            id: 3,
            title: '工事見積書一覧の見方',
            type: '販売側向け',
            link: '/support/quotation/3',
            data: <div>
              <p>サイドメニューから<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 工事見積書一覧 </span>を開きます。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fquotation%2F1%E5%B7%A5%E4%BA%8B%E8%A6%8B%E7%A9%8D%E6%9B%B8%E4%B8%80%E8%A6%A7%E3%81%AE%E8%A6%8B%E6%96%B9.jpg?alt=media&token=df6ff43a-4f31-4ba3-9636-48645ad19003'}/>
              <br/><br/>
              <p>工事見積書一覧が表示されます。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fquotation%2F2%E5%B7%A5%E4%BA%8B%E8%A6%8B%E7%A9%8D%E6%9B%B8%E4%B8%80%E8%A6%A7%E3%81%AE%E8%A6%8B%E6%96%B9.jpg?alt=media&token=13dafbc9-6dcc-42c4-860c-bc247e190a13'}/>
              <br/><br/>
              <p>① 見積番号/見積書ID</p>
              <p>工事見積書登録時に指定した見積番号と、P4CLOUDで管理されるドキュメントIDが表示されます。</p>
              <br/>
              <p>② 送信者/送信日時/見積受取者</p>
              <p>上段、中段に工事見積書を送信したユーザーと送信日時が表示されます。</p>
              <p>下段に工事見積書登録時に受取者として指定したユーザーが表示されます。</p>
              <br/>
              <p>③ 見積確認者/確認日時</p>
              <p>仕入側で最初に工事見積書を確認したユーザーと確認日時が表示されます。</p>
              <br/>
              <p>④ 発行日/有効期限/納品日</p>
              <p>販売側が工事見積書登録時に指定した日付が表示されます。</p>
              <br/>
              <p>⑤ ステータス確定日時</p>
              <p>仕入側での承認・却下ステータスと承認・却下したユーザー名、日時が表示されます。</p>
              <p>承認・却下前の工事見積書は<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 未受注 </span>が表示されます。</p>
              <p>承認されると<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 受注済 </span>が表示され、工事見積書を送信したユーザーには注文を知らせるメールが届きます。（通知設定をオンにしている場合）</p>
              <br/>
              <p>⑥ 受注確認者/確認日時</p>
              <p>仕入側が承認した工事見積書は<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 受注確認 </span>ボタンが表示されます。</p>
              <p>販売側で<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 受注確認 </span>ボタンをクリックすると<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 受注確認済 </span>に表示が変わり、クリックしたユーザー名と日時が表示されます。</p>
              <br/>
              <p>⑦ 削除</p>
              <p>ゴミ箱アイコンをクリックすると、ステータス確定日時欄に<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 削除済 </span>ラベルが表示されます。</p>
              <p>削除をしても販売側の工事見積書一覧には表示されますが、<span style={{color: 'red'}}>仕入側の工事見積書一覧には表示されません。</span></p>
              <br/>
              <div style={{backgroundColor: '#fffaeb', borderLeft: '10px solid #FFD54F', padding: 15}}>
                <h4>POINT</h4>
                <br/>
                <p>販売側が工事見積書を新規登録した時、販売側・仕入側いずれも以下の箇所にバッジが表示されます。</p>
                <p>・ログインアカウント名の横</p>
                <p>・相手先選択プルダウンボックス</p>
                <p>・サイドメニュー>工事見積書</p>
                <br/>
                <p>同時に対象の工事見積書は太字で表示されます。</p>
                <p>※バッジおよび太字は事業所内登録アカウントすべてに表示されます。</p>
                <br/>
                <p>事業所内のいずれかのアカウントが工事見積書一覧の 表示 をクリックすると、事業所内全アカウントの画面からバッジが減少し太字が解除されます。</p>
                <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fquotation%2F3%E5%B7%A5%E4%BA%8B%E8%A6%8B%E7%A9%8D%E6%9B%B8%E4%B8%80%E8%A6%A7%E3%81%AE%E8%A6%8B%E6%96%B9.jpg?alt=media&token=2e7dda2d-801c-4ec3-8771-491d281a5096'}/>
              </div>
            </div>
          },
          // {
          //   id: 4,
          //   title: '請求書を登録する',
          //   type: '販売側向け',
          //   link: '/support/quotation/4',
          //   data: <div>
          //     <p>請求書を発行する相手先企業を選択します。</p>
          //     <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fquotation%2F0%E8%AB%8B%E6%B1%82%E6%9B%B8%E3%82%92%E7%99%BB%E9%8C%B2%E3%81%99%E3%82%8B.jpg?alt=media&token=1550ac6e-7172-4c4b-b18a-5304afc6246f'}/>
          //     <br/><br/>
          //     <p>サイドメニューから <span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 請求書登録 </span> を選択します。</p>
          //     <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fquotation%2F106.png?alt=media&token=2bfc7114-6efd-49b1-b32d-6034095d5ea3'}/>
          //     <br/><br/>
          //     <p>請求書登録画面の各項目に入力します。<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 相手先担当者 </span>および<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 相手先CC </span>で選択したユーザーには以下のメール通知が届きます。</p>
          //     <p>・請求書登録通知</p>
          //     <p>・請求書削除通知</p>
          //     <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fquotation%2F1%E8%AB%8B%E6%B1%82%E6%9B%B8%E3%82%92%E7%99%BB%E9%8C%B2%E3%81%99%E3%82%8B-1.jpg?alt=media&token=6a5bd66e-ae46-4897-bc9c-f314ee757074'}/>
          //     <br/><br/>
          //     <p>PDF形式の請求書をアップロードします。</p>
          //     <p>【アップロード方法】</p>
          //     <p>方法１．円の中にPDF請求書をドラッグ＆ドロップします。</p>
          //     <p>方法２．円をクリックすると別ウィンドウが開きます。ファイルを選択して[開く]をクリックするとアップロードされます。</p>
          //     <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fquotation%2F2%E8%AB%8B%E6%B1%82%E6%9B%B8%E3%82%92%E7%99%BB%E9%8C%B2%E3%81%99%E3%82%8B-2.jpg?alt=media&token=bbd95c62-8c68-4198-b5f8-19034005b21d'}/>
          //     <br/><br/>
          //     <p>請求書がアップロードされるとファイル名が表示されます。</p>
          //     <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fquotation%2F109.png?alt=media&token=ccd4090e-7eee-4692-85fa-1cf76dd86e5c'}/>
          //     <br/><br/>
          //     <p><span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 新規請求書登録 </span>をクリックすると請求書が登録されます。</p>
          //     <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fquotation%2F110-1.png?alt=media&token=cd3dc48e-343d-4055-a414-9313aeab6d4c'}/>
          //     <br/><br/>
          //     <p>登録した請求書は、サイドメニューの請求書一覧から確認することができます。</p>
          //     <img style={{width: '100%', border: '1px solid lightgrey', maxWidth: 1288}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fquotation%2F6%E8%AB%8B%E6%B1%82%E6%9B%B8%E3%82%92%E7%99%BB%E9%8C%B2%E3%81%99%E3%82%8B-1.jpg?alt=media&token=2f08c9b6-1315-4de2-9e34-8194ecdf0d23'}/>
          //     <br/><br/>
          //     <div style={{backgroundColor: '#fffaeb', borderLeft: '10px solid #FFD54F', padding: 15}}>
          //       <h4>POINT</h4>
          //       <br/>
          //       <p>請求書一覧に表示されるのは、<span style={{color: '#ff0000'}}>相手先選択モードで選択している事業所</span>の請求書のみです。</p>
          //       <p>他の事業所への請求書を確認するときは、相手先選択モードで切り替えてページを再読込みします。</p>
          //       <img style={{width: '100%', border: '1px solid lightgrey', maxWidth: 850}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fquotation%2F6%E8%AB%8B%E6%B1%82%E6%9B%B8%E3%82%92%E7%99%BB%E9%8C%B2%E3%81%99%E3%82%8B-1.jpg?alt=media&token=2f08c9b6-1315-4de2-9e34-8194ecdf0d23'}/>
          //     </div>
          //   </div>
          // },
          {
            id: 5,
            title: '請求書/納品書/見積書/報告書を登録する',
            type: '販売側向け',
            link: '/support/quotation/5',
            data: <div>
              <p>※販売側向けの機能</p>
              <br/>
              <div style={{backgroundColor: '#fffaeb', borderLeft: '10px solid #FFD54F', padding: 15}}>
                <h4>POINT</h4>
                <br/>
                <p>ここでは請求書を例に説明していますが、納品書/見積書/報告書も同様の操作になります。</p>
              </div>
              <br/><br/>
              <p>請求書を発行する相手先企業を選択します。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fquotation%2F0%E8%AB%8B%E6%B1%82%E6%9B%B8%E3%82%92%E7%99%BB%E9%8C%B2%E3%81%99%E3%82%8B.jpg?alt=media&token=b46e0fd2-fbdc-4bda-81f9-830358b27b77'}/>
              <br/><br/>
              <p>サイドメニューから<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 請求書登録 </span>を選択します。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fquotation%2F106.png?alt=media&token=7e935990-8b12-4492-a63d-409ccfd4052f'}/>
              <br/><br/>
              <p>登録画面の各項目に入力します。<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}>相手先担当者</span>および<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}>相手先CC</span>で選択したユーザーには以下のメール通知が届きます。</p>
              <p>・請求書登録通知</p>
              <p>・請求書削除通知</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fquotation%2F1%E8%AB%8B%E6%B1%82%E6%9B%B8%E3%82%92%E7%99%BB%E9%8C%B2%E3%81%99%E3%82%8B-1.jpg?alt=media&token=7eab60a6-80c7-45f8-89d2-bedc83445776'}/>
              <br/><br/>
              <p>PDF形式の請求書をアップロードします。</p>
              <p>【アップロード方法】</p>
              <p>方法１．円の中にPDF請求書をドラッグ＆ドロップします。</p>
              <p>方法２．円をクリックすると別ウィンドウが開きます。ファイルを選択して[開く]をクリックするとアップロードされます。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fquotation%2F2%E8%AB%8B%E6%B1%82%E6%9B%B8%E3%82%92%E7%99%BB%E9%8C%B2%E3%81%99%E3%82%8B-2.jpg?alt=media&token=a68c3eef-e38a-49af-86e5-6c32f0af26d1'}/>
              <br/><br/>
              <p>請求書がアップロードされるとファイル名が表示されます。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fquotation%2F109.png?alt=media&token=fd007fbd-9f02-4464-9d18-dddbaf5296ce'}/>
              <br/><br/>
              <p><span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 新規請求書登録 </span>をクリックすると請求書が登録されます。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fquotation%2F110-1.png?alt=media&token=308d1d5c-840a-4641-8e0f-87f55ff1af3f'}/>
              <br/><br/>
              <p>登録した請求書は、サイドメニューの請求書一覧から確認することができます。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fquotation%2F6%E8%AB%8B%E6%B1%82%E6%9B%B8%E3%82%92%E7%99%BB%E9%8C%B2%E3%81%99%E3%82%8B-1.jpg?alt=media&token=00c523e5-06e6-45dd-a4c9-1479591aedf1'}/>
              <br/><br/>
              <div style={{backgroundColor: '#fffaeb', borderLeft: '10px solid #FFD54F', padding: 15}}>
                <h4>POINT</h4>
                <br/>
                <p>請求書一覧に表示されるのは、<span style={{color: '#ff0000'}}>相手先選択モードで選択している事業所</span>の請求書のみです。</p>
                <p>他の事業所への請求書を確認するときは、相手先選択モードで切り替えてページを再読込みします。</p>
                <img style={{width: '100%', border: '1px solid lightgrey', maxWidth: 850}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fquotation%2F6%E8%AB%8B%E6%B1%82%E6%9B%B8%E3%82%92%E7%99%BB%E9%8C%B2%E3%81%99%E3%82%8B-1.jpg?alt=media&token=00c523e5-06e6-45dd-a4c9-1479591aedf1'}/>
              </div>
            </div>
          },
          {
            id: 6,
            title: '請求書一覧/納品書一覧/見積書一覧/報告書一覧の見方',
            type: '販売側/仕入側向け',
            link: '/support/quotation/6',
            data: <div>
              <div style={{backgroundColor: '#fffaeb', borderLeft: '10px solid #FFD54F', padding: 15}}>
                <h4>POINT</h4>
                <br/>
                <p>ここでは請求書一覧を例に説明していますが、納品書一覧/見積書一覧/報告書一覧も同様の操作になります。</p>
              </div>
              <br/><br/>
              <p>サイドメニューから<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 請求書一覧 </span>を開きます。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fquotation%2F0%E8%AB%8B%E6%B1%82%E6%9B%B8%E4%B8%80%E8%A6%A7%E3%81%AE%E8%A6%8B%E6%96%B9.jpg?alt=media&token=330c34f7-a9e5-4c44-9c13-519493e8ea85'}/>
              <br/><br/>
              <p>請求書一覧が表示されます。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fquotation%2F1%E8%AB%8B%E6%B1%82%E6%9B%B8%E4%B8%80%E8%A6%A7%E3%81%AE%E8%A6%8B%E6%96%B9-3-scaled.jpg?alt=media&token=1a9f398f-7a28-4764-a9b1-826150a7076a'}/>
              <br/><br/>
              <p>① PDFファイルダウンロード</p>
              <p>ダウンロードしたい請求書のチェックボックスをクリックしてから<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 選択したPDF一括ダウンロードボタン </span>をクリックします。</p>
              <p>選択したファイルはZIP形式でダウンロードされます。</p>
              <br/>
              <p>② 検索</p>
              <p>プルダウンメニューから検索項目を選び、入力エリアに日付またはキーワードを入力した後、検索ボタンをクリックします。</p>
              <p style={{color: '#ff0000'}}>日付を入力する場合、開始日・終了日の両方を入力してください。いずれか一方の入力では検索することができません。</p>
              <br/>
              <p>③ 並べ替え</p>
              <p>プルダウンメニューから並べ替えの基準となる項目を選択後、昇順または降順のいずれかを選択すると一覧が並べ替えられます。</p>
              <br/>
              <p>④ 表示　※販売側のみの機能</p>
              <p>デフォルトでは<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 送信済一覧 </span>（削除済み以外の請求書）が選択されています。</p>
              <p>削除済みの請求書を表示したいときは、<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 削除済一覧 </span>を選択します。</p>
              <p><span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> すべて </span>を選択すると、削除済みを含めたすべての請求書が表示されます。</p>
              <br/>
              <p>⑤ リセット</p>
              <p>検索結果、並び替え、表示をデフォルト状態にリセットします。</p>
              <br/>
              <p>⑥ 請求書確認者/請求書確認日</p>
              <p>仕入側事業所内で最初に請求書を確認したユーザー名と確認日時が表示されます。</p>
              <br/>
              <p>⑦ 削除　※販売側のみの機能</p>
              <p>ゴミ箱アイコンをクリックすると、請求書を登録時に選択した相手先担当者および相手先CCに請求書の削除を通知するメールが送信されます。（通知設定をONにしている場合）</p>
              <p>削除をすると<span style={{color: '#ff0000'}}>仕入側の請求書一覧からは非表示になり、閲覧することができなくなります。</span></p>
            </div>
          }
        ],
        'sds-manual-catalog': [
          // {
          //   id: 1,
          //   title: 'SDS/取扱説明書/カタログを登録する※販売モードの機能',
          //   link: '/support/sds-manual-catalog/1',
          //   data: <div>
          //     <div style={{backgroundColor: '#fffaeb', borderLeft: '10px solid #FFD54F', padding: 15}}>
          //       <h4>POINT</h4>
          //       <br/>
          //       <p>ここではSDSを例に説明していますが、取扱説明書、カタログも同様の操作になります。</p>
          //     </div>
          //     <br/>
          //     <p>サイドメニューから SDS登録 を選択します。</p>
          //     <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fsds%2F%E3%82%B5%E3%82%A4%E3%83%88%E3%82%99%E3%83%A1%E3%83%8B%E3%83%A5%E3%83%BC_SDS_01-1024x448.png?alt=media&token=8bcce94a-b153-4939-a6ee-49618df5cac5'}/>
          //     <br/><br/>
          //     <p>各項目を入力します。<span style={{color: 'red'}}>※未入力項目があると登録できません。</span></p>
          //     <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fsds%2FSDS%E7%99%BB%E9%8C%B2_1-1024x643.png?alt=media&token=8d2fd24b-7a3b-48ea-8022-e5dc868929f8'}/>
          //     <br/><br/>
          //     <p>PDF形式のSDSをアップロードします。</p>
          //     <p>【アップロード方法】</p>
          //     <p>方法１．円の中にPDFファイルをドラッグ＆ドロップします。</p>
          //     <p>方法２．円をクリックすると別ウィンドウが開きます。 ファイルを選択して[開く]をクリックすると アップロードされます</p>
          //     <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fsds%2FSDS%E7%99%BB%E9%8C%B2_2-1024x643.png?alt=media&token=60da8e85-09a9-417c-bd17-15a2f08dcb00'}/>
          //     <br/><br/>
          //     <p>SDSがアップロードされるとファイル名が表示されます。</p>
          //     <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fsds%2FSDS%E7%99%BB%E9%8C%B2_3-1024x275.png?alt=media&token=cc25e8e2-44cc-4db5-a55c-954f337ec016'}/>
          //     <br/><br/>
          //     <p><span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 新規SDS登録 </span>をクリックするとSDSが登録されます。</p>
          //     <p>※この操作ではSDSは登録商品には紐づきません。</p>
          //     <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fsds%2FSDS%E7%99%BB%E9%8C%B2_4-1024x275.png?alt=media&token=66e21e0c-a4b3-462f-a8fa-18241d7e146b'}/>
          //     <br/><br/>
          //     <p>サイドメニューから<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> SDS一覧 </span>を開くと、登録したSDSが確認できます。</p>
          //     <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fsds%2FSDS%E4%B8%80%E8%A6%A7-1024x245.png?alt=media&token=28ac8130-d7c3-4859-8632-58ac8e6c6b5e'}/>
          //     <br/><br/>
          //     <div style={{backgroundColor: '#fffaeb', borderLeft: '10px solid #FFD54F', padding: 15}}>
          //       <h4>POINT</h4>
          //       <br/>
          //       <p>SDS一覧、取扱説明書一覧、カタログ一覧には<span style={{color: 'red'}}>相手先選択モードで選択していない事業所を含め、登録しているすべて</span>が表示されます。</p>
          //     </div>
          //   </div>
          // },
          {
            id: 2,
            title: '商品に紐づいているSDS/取扱説明書/カタログを確認する',
            link: '/support/sds-manual-catalog/2',
            data: <div>
              <p>サイドメニューから<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 商品購入 </span>→<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 商品一覧 </span>を開きます。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fsds%2Fimage-5-1024x222.png?alt=media&token=9cb18e71-ad42-4742-9545-197f2cc7f54e'}/>
              <br/><br/>
              <p>(a)各商品行の<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> SDS </span>/<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 取扱説明書 </span>/<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> カタログ </span>ボタンをクリックすると、商品に登録されたSDS/取扱説明書/カタログが表示されます。</p>
              <p>※SDS/取扱説明書/カタログの表示ボタンは、登録がない場合は表示されません。</p>
              <br/>
              <p>(b)SDSが登録されている商品は、商品一覧画面の<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> SDS登録商品 </span>にチェックを入れ<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 表示 </span>をクリックすることで絞り込んで表示させることができます。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fsds%2Fimage-4-1024x432.png?alt=media&token=43695111-44db-4765-84dc-9243c90f2c57'}/>
            </div>
          },
          {
            id: 3,
            title: 'SDSを管理する',
            link: '/support/sds-manual-catalog/3',
            data: <div>
              <div style={{backgroundColor: '#fffaeb', borderLeft: '10px solid #FFD54F', padding: 15}}>
                <h4>POINT</h4>
                <br/>
                <p>仕入側はSDSが登録されている商品のSDS定期チェックを申し込むことができます。</p>
                <p>定期チェックは毎年自動で更新されます。</p>
              </div>
              <br/>
              <h4>■SDS管理を申し込む（仕入側の機能）</h4>
              <br/>
              <p>サイドメニューから<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 商品一覧 </span>を開き、<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> SDS管理申込 </span>ボタンをクリックします。</p>
              <br/>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fsds%2Fimage-1024x367.png?alt=media&token=e0f93de0-a611-4ca5-9b90-87dbf2d97121'}/>
              <br/><br/>
              <p>ご希望のチェック月にチェックを入れ、<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 申込確認 </span>ボタンをクリックします。</p>
              <img style={{width: '100%', maxWidth: 450, border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fsds%2F2SDS%E7%AE%A1%E7%90%86%E4%B8%80%E8%A6%A7_%E3%83%9B%E3%82%9A%E3%83%83%E3%83%95%E3%82%9A%E3%82%A2%E3%83%83%E3%83%95%E3%82%9A%E7%94%BB%E9%9D%A2.jpg?alt=media&token=a1b07c18-00df-4b87-9972-37ccc0fc4459'}/>
              <br/><br/>
              <p><span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 申込 </span>ボタンをクリックすると申込が完了します。</p>
              <p>※この時点では受付は完了していません。販売側が受付処理を行うと受付が完了します。</p>
              <br/>
              <img style={{width: '100%', maxWidth: 449, border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fsds%2F3SDS%E7%AE%A1%E7%90%86%E4%B8%80%E8%A6%A7_%E3%83%9B%E3%82%9A%E3%83%83%E3%83%95%E3%82%9A%E3%82%A2%E3%83%83%E3%83%95%E3%82%9A%E7%94%BB%E9%9D%A2.jpg?alt=media&token=db68c872-2af3-49e1-83b8-3cb38f7518fa'}/>
              <br/><br/>
              <h4>■SDS管理を受け付ける（販売側の機能）</h4>
              <br/>
              <p>サイドメニューから<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> SDS管理一覧 </span>を開きます。</p>
              <p><span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 受付処理 </span>をクリックすると受付が完了します。</p>
              <br/>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fsds%2F4%E8%B2%A9%E7%A4%BEU_SDS%E7%AE%A1%E7%90%86%E4%B8%80%E8%A6%A7-1-2048x815.jpg?alt=media&token=9cd3d6c0-310e-41df-9427-36a4e65871cd'}/>
              <br/><br/>
              <h4>■申込内容とチェック実施履歴、管理状況の確認</h4>
              <br/>
              <p>申込内容およびチェック実施履歴は、サイドメニューの<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> SDS管理一覧 </span>から確認することができます。</p>
              <p>販売側が受付処理をすると、管理状況を示す通知バッジおよびランプが点灯します。</p>
              <br/>
              <p style={{fontWeight: 'bold'}}>[ランプ表示]</p>
              <p>表示箇所：ヘッダー、SDS管理一覧画面</p>
              <p style={{display: 'flex', alignItems: 'center'}}><div style={{width: 15, height: 15, backgroundColor: '#ff3547', borderRadius: 15}}/>赤色…希望日を過ぎてチェック更新されていない状態</p>
              <p style={{display: 'flex', alignItems: 'center'}}><div style={{width: 15, height: 15, backgroundColor: '#007bff', borderRadius: 15}}/>青色…希望日を過ぎてチェック更新されている状態</p>
              <p style={{display: 'flex', alignItems: 'center'}}><div style={{width: 15, height: 15, backgroundColor: '#ffeb3b', borderRadius: 15}}/>黄色…チェック更新日が1週間以内</p>
              <br/>
              <p>表示箇所：ヘッダー、サイドメニュー</p>
              <p style={{display: 'flex', alignItems: 'center'}}><div style={{width: 15, height: 15, backgroundColor: '#2bbbad', borderRadius: 15}}/>緑色…SDS管理申込済みだが受付未処理の状態</p>
              <br/>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fsds%2F6SDS%E7%AE%A1%E7%90%86%E4%B8%80%E8%A6%A7-2048x964.jpg?alt=media&token=fceff9b9-41a7-4617-a862-4f31c561527d'}/>
            </div>
          },
          {
            id: 4,
            title: 'リスクアセスメント管理を申請する（仕入側）',
            link: '/support/sds-manual-catalog/4',
            data: <div>
              <div style={{backgroundColor: '#fffaeb', borderLeft: '10px solid #FFD54F', padding: 15}}>
                <h4>POINT</h4>
                <br/>
                <p>スクアセスメント管理はSDS管理中商品にのみ適用可能です。</p>
              </div>
              <br/>
              <p>サイドメニューから<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> SDS管理一覧 </span>を開きます。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fsds%2F0_%E3%83%AA%E3%82%B9%E3%82%AF%E3%82%A2%E3%82%BB%E3%82%B9%E3%83%A1%E3%83%B3%E3%83%88%E7%AE%A1%E7%90%86%E3%82%92%E7%94%B3%E3%81%97%E8%BE%BC%E3%82%80-5.jpg?alt=media&token=4585a3b4-f9a9-4a9b-b874-b4205dfbce6b'}/>
              <br/><br/>
              <p><span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}>  リスクアセスメント管理申込 </span>ボタンをクリックします。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fsds%2F1_%E3%83%AA%E3%82%B9%E3%82%AF%E3%82%A2%E3%82%BB%E3%82%B9%E3%83%A1%E3%83%B3%E3%83%88%E7%AE%A1%E7%90%86%E3%82%92%E7%94%B3%E3%81%97%E8%BE%BC%E3%82%80-2.jpg?alt=media&token=f8454cbb-e8fc-4844-b79d-99e2b4966394'}/>
              <br/><br/>
              <p>リスクアセスメント管理申込のポップアップが表示されます。<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 申し込む </span>をクリックします。</p>
              <img style={{width: '100%', maxWidth: 500, border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fsds%2F2_%E3%83%AA%E3%82%B9%E3%82%AF%E3%82%A2%E3%82%BB%E3%82%B9%E3%83%A1%E3%83%B3%E3%83%88%E7%AE%A1%E7%90%86%E3%82%92%E7%94%B3%E3%81%97%E8%BE%BC%E3%82%80-1.jpg?alt=media&token=9489780b-da85-45f4-bdeb-30f36ed5eb8b'}/>
              <br/><br/>
              <p>表示が<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> リスクアセスメント管理受付待 </span>に変わります。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fsds%2F3_%E3%83%AA%E3%82%B9%E3%82%AF%E3%82%A2%E3%82%BB%E3%82%B9%E3%83%A1%E3%83%B3%E3%83%88%E7%AE%A1%E7%90%86%E3%82%92%E7%94%B3%E3%81%97%E8%BE%BC%E3%82%80-3.jpg?alt=media&token=399007a6-88d3-4972-a5ba-973bbdc8aa0d'}/>
              <br/><br/>
              <p>販売側の受付処理が完了すると、表示が<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> リスクアセスメント管理中 </span>に変わり、ボタンがアクティブになります。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fsds%2F4_%E3%83%AA%E3%82%B9%E3%82%AF%E3%82%A2%E3%82%BB%E3%82%B9%E3%83%A1%E3%83%B3%E3%83%88%E7%AE%A1%E7%90%86%E3%82%92%E7%94%B3%E3%81%97%E8%BE%BC%E3%82%80-2.jpg?alt=media&token=eafa066b-3b0b-400f-9808-4687bf45c078'}/>
              <br/><br/>
              <p><span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> リスクアセスメント管理受付中 </span>ボタンをクリックすると、リスクアセスメント管理詳細画面が表示されます。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fsds%2F5_%E3%83%AA%E3%82%B9%E3%82%AF%E3%82%A2%E3%82%BB%E3%82%B9%E3%83%A1%E3%83%B3%E3%83%88%E7%AE%A1%E7%90%86%E3%82%92%E7%94%B3%E3%81%97%E8%BE%BC%E3%82%80_03.jpg?alt=media&token=871c2745-a041-4937-9986-3354916d1f00'}/>
              <br/><br/>
              <p>リスクアセスメント管理は一時停止することができます。</p>
              <p>リスト右端のメニューアイコン【<img src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2ForderReceived%2Fimage-1.png?alt=media&token=5bdfc0c9-9763-4c9a-acf0-52badedc5a07'}/>】をクリックして、プルダウンメニューから<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> リスクアセスメント管理一時停止 </span>を選択します。</p>
              <p>※トリコロンメニューでは「管理一時停止」から「管理再開」も可能です。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fsds%2F6_%E3%83%AA%E3%82%B9%E3%82%AF%E3%82%A2%E3%82%BB%E3%82%B9%E3%83%A1%E3%83%B3%E3%83%88%E7%AE%A1%E7%90%86%E3%82%92%E7%94%B3%E3%81%97%E8%BE%BC%E3%82%80.jpg?alt=media&token=efb1094d-0756-4be9-8a4d-ba1b6df30c76'}/>
            </div>
          },
          {
            id: 5,
            title: 'リスクアセスメント管理を受け付ける（販売側）',
            link: '/support/sds-manual-catalog/5',
            data: <div>
              <div style={{backgroundColor: '#fffaeb', borderLeft: '10px solid #FFD54F', padding: 15}}>
                <h4>POINT</h4>
                <br/>
                <p>スクアセスメント管理はSDS管理中商品にのみ適用可能です。</p>
              </div>
              <br/>
              <p>サイドメニューから<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> SDS管理一覧 </span>を開きます。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fsds%2F0_%E3%83%AA%E3%82%B9%E3%82%AF%E3%82%A2%E3%82%BB%E3%82%B9%E3%83%A1%E3%83%B3%E3%83%88%E7%AE%A1%E7%90%86%E3%82%92%E5%8F%97%E3%81%91%E4%BB%98%E3%81%91%E3%82%8B-3.jpg?alt=media&token=470862bf-6a74-4d41-9e8f-4b7d8488c0c3'}/>
              <br/><br/>
              <p>仕入側がリスクアセスメント管理を申込むと、<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> リスクアセスメント管理受付待 </span>ボタンが表示されます。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fsds%2F1_%E3%83%AA%E3%82%B9%E3%82%AF%E3%82%A2%E3%82%BB%E3%82%B9%E3%83%A1%E3%83%B3%E3%83%88%E7%AE%A1%E7%90%86%E3%82%92%E5%8F%97%E3%81%91%E4%BB%98%E3%81%91%E3%82%8B-1.jpg?alt=media&token=23309ede-eba3-41c9-942f-1ad9bd488576'}/>
              <br/><br/>
              <p><span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> リスクアセスメント管理受付待 </span>ボタンをクリックすると、リスクアセスメント管理受付のポップアップが表示されます。</p>
              <p><span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 受け付ける </span>ボタンをクリックします。</p>
              <img style={{width: '100%', maxWidth: 500, border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fsds%2F2_%E3%83%AA%E3%82%B9%E3%82%AF%E3%82%A2%E3%82%BB%E3%82%B9%E3%83%A1%E3%83%B3%E3%83%88%E7%AE%A1%E7%90%86%E3%82%92%E5%8F%97%E3%81%91%E4%BB%98%E3%81%91%E3%82%8B.jpg?alt=media&token=ba7f3f41-3950-413b-a77f-ed6c3e7f91b6'}/>
              <br/><br/>
              <p>ボタンが<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> リスクアセスメント管理中 </span>に変わり、アクティブになります。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fsds%2F3_%E3%83%AA%E3%82%B9%E3%82%AF%E3%82%A2%E3%82%BB%E3%82%B9%E3%83%A1%E3%83%B3%E3%83%88%E7%AE%A1%E7%90%86%E3%82%92%E5%8F%97%E3%81%91%E4%BB%98%E3%81%91%E3%82%8B.jpg?alt=media&token=338c6f70-98cb-4413-9b31-ed3fb876331b'}/>
              <br/><br/>
              <p><span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> リスクアセスメント管理受付中 </span>ボタンをクリックすると、リスクアセスメント管理詳細画面が表示されます。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fsds%2F4_%E3%83%AA%E3%82%B9%E3%82%AF%E3%82%A2%E3%82%BB%E3%82%B9%E3%83%A1%E3%83%B3%E3%83%88%E7%AE%A1%E7%90%86%E3%82%92%E5%8F%97%E3%81%91%E4%BB%98%E3%81%91%E3%82%8B_.jpg?alt=media&token=59eae5c2-916f-4584-8d77-2867ef1646af'}/>
              <br/><br/>
              <p>仕入側で「管理一時停止」の処理が行われると、<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> リスクアセスメント管理一時停止 </span>が表示されます。</p>
              <p>仕入側で「再開」の処理が行われると、表示が<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> リスクアセスメント管理受付中 </span>に戻ります。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fsds%2F5_%E3%83%AA%E3%82%B9%E3%82%AF%E3%82%A2%E3%82%BB%E3%82%B9%E3%83%A1%E3%83%B3%E3%83%88%E7%AE%A1%E7%90%86%E3%82%92%E5%8F%97%E3%81%91%E4%BB%98%E3%81%91%E3%82%8B-1.jpg?alt=media&token=d156dbff-5525-4a91-94a0-2c6eb0db7a69'}/>
            </div>
          },
          {
            id: 6,
            title: 'リスクアセスメントファイルを管理する（販売側・仕入側）',
            link: '/support/sds-manual-catalog/6',
            data: <div>
              <p>SDS管理一覧から<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> リスクアセスメント管理中 </span>ボタンをクリックします。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fsds%2F1_%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%82%92%E7%AE%A1%E7%90%86%E3%81%99%E3%82%8B.jpg?alt=media&token=fb7cfd5a-0b9b-49d6-a7b4-df3f8e5f3ae0'}/>
              <br/><br/>
              <p>リスクアセスメント管理詳細画面が表示されます。</p>
              <p>各ボタンから管理ファイルをアップロードします</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fsds%2F2_%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%82%92%E7%AE%A1%E7%90%86%E3%81%99%E3%82%8B-1.jpg?alt=media&token=f3d9abee-e09e-4cab-ae9a-417a68bf61e2'}/>
              <br/><br/>
              <h5>①テンプレートファイルアップロード / ②条件記入済みテンプレートファイルアップロード</h5>
              <p>仕入側・販売側ともファイルをアップロードできます。</p>
              <p>アップロードできるファイルは1点のみです。</p>
              <br/>
              <h5>③チェック済みファイルアップロード</h5>
              <p><span style={{color: '#ff0000'}}>仕入側のみファイルをアップロードできます。</span></p>
              <p>※<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> チェック済みファイルアップロード </span>ボタンは仕入側にのみ表示されます。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fsds%2F%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%81%AE%E3%83%95%E3%83%AD%E3%83%BC_%E3%82%A2%E3%83%BC%E3%83%88%E3%83%9B%E3%82%99%E3%83%BC%E3%83%88%E3%82%99-1-1-scaled.jpg?alt=media&token=ab7ebba6-92bf-4087-8eba-d498c7ca2df5'}/>
              <br/><br/>
              <div style={{backgroundColor: '#fffaeb', borderLeft: '10px solid #FFD54F', padding: 15}}>
                <h4>POINT</h4>
                <br/>
                <p>チェック済みファイルアップロード時に、チェック日を指定します。</p>
                <p>ここで指定した日付がリスクアセスメント管理詳細画面のチェック日欄に表示されます。</p>
                <img style={{width: '100%', maxWidth: 250, border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fsds%2F3_%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%82%92%E7%AE%A1%E7%90%86%E3%81%99%E3%82%8B-1.jpg?alt=media&token=d8ebb90d-7f12-40a5-983d-4848a51cce6f'}/>
              </div>
              <br/>
              <p>ファイルをダウンロードもしくは削除する場合は、右端のメニューアイコン【<img src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2ForderReceived%2Fimage-1.png?alt=media&token=5bdfc0c9-9763-4c9a-acf0-52badedc5a07'}/>】をクリックしてプルダウンメニューから選択します。</p>
              <p><span style={{color: '#ff0000', fontWeight: 'bold'}}>※チェック済みファイルを削除できるのは購入側のみです。</span></p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fsds%2F4_%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%82%92%E7%AE%A1%E7%90%86%E3%81%99%E3%82%8B.jpg?alt=media&token=7a6cad08-8ec6-4c04-ba7a-43e145fe3773'}/>
            </div>
          }
        ],
        setting: [
          {
            id: 1,
            title: '通知メール受信設定',
            link: '/support/setting/1',
            data: <div>
              <p>画面右上のログインアカウント名をクリックします。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fsetting%2F%E3%83%9B%E3%83%BC%E3%83%A0%E7%94%BB%E9%9D%A2-1-1024x133.png?alt=media&token=b1dd6d7a-c270-4b06-82a5-4d65e7cf4414'}/>
              <br/><br/>
              <p>プロフィール情報の下にある<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 通知メール受信設定 </span>をクリックします。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fsetting%2F%E3%83%9B%E3%83%BC%E3%83%A0%E7%94%BB%E9%9D%A2_%E3%82%A2%E3%82%AB%E3%82%A6%E3%83%B3%E3%83%88%E6%83%85%E5%A0%B1-1024x353.png?alt=media&token=d8348259-2bad-4c70-8df8-44ec6bf75c68'}/>
              <br/><br/>
              <p>通知メール受信設定画面が表示されます。各項目のON/OFFを切り替えます。</p>
              <p><span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 設定する </span>をクリックすると設定が有効になります。</p>
              <br/>
              <p>※ユーザーの属性によって設定できる項目は異なります。画像は販社ユーザーの例です。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fsetting%2F%E9%80%9A%E7%9F%A5%E3%83%A1%E3%83%BC%E3%83%AB%E5%8F%97%E4%BF%A1%E8%A8%AD%E5%AE%9A-1-1024x670.png?alt=media&token=b6e3658f-86a8-4c79-8e4a-21981da286e0'}/>
              <br/><br/>
              <div style={{backgroundColor: '#fffaeb', borderLeft: '10px solid #FFD54F', padding: 15}}>
                <h4>POINT</h4>
                <br/>
                <p>【受信設定できるメールの種類】</p>
                <br/>
                <p>■購入ユーザー</p>
                <p>上長承認依頼時（仕入モード側受信）※上長権限者のみ</p>
                <p>上長却下時（仕入モード側受信</p>
                <p>上長承認時（仕入モード側受信）</p>
                <p>出荷日送り状番号送信時（仕入モード側受信）</p>
                <br/>
                <p>■販社ユーザー</p>
                <p>上長承認依頼時（仕入モード側受信）※上長権限者のみ</p>
                <p>上長却下時（仕入モード側受信）</p>
                <p>上長承認時（仕入モード側受信）</p>
                <p>出荷日送り状番号送信時（仕入モード側受信）</p>
                <p>新規注文受取時（販売モード側受信）</p>
                <br/>
                <p>■メーカーユーザー</p>
                <p>新規注文受取時（販売モード側受信）</p>
              </div>
            </div>
          }
        ],
        'otasuke-cart': [
          {
            id: 1,
            title: 'お助けカートを使ってカートを作成する',
            type: '仕入側向け',
            link: '/support/otasuke-cart/1',
            backText: 'お助けカートメニュー一覧に戻る',
            backLink: 'otasuke-cart',
            data: <div>
              <div style={{background: '#fffaeb', border: '3px solid #FFD54F', }}>
                <h4 style={{padding: 15, backgroundColor: '#FFD54F'}}>お助けカートとは</h4>
                <div style={{padding: 15}}>
                  <p>販売側が仕入側のカート作成を補助する機能です。</p>
                  <p>販売側が商品を選び「お助けカート」を作成し、仕入側に送信することができます。</p>
                  <p>仕入側はお助けカートをそのままカートに投入することで、商品を探す手間を省くことができます。</p>
                </div>
              </div>
              <br/>
              <p>販売側からお助けカートが届くと、メールで通知が届き、ヘッダーとサイドメニューにバッジが点灯します。</p>
              <p>サイドメニューの <span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}>お助けカート</span> → <span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}>お助けカート一覧</span> を開きます。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2F1%E3%81%8A%E5%8A%A9%E3%81%91%E3%82%AB%E3%83%BC%E3%83%88-1.jpg?alt=media&token=abd0b5a7-ad50-4529-9ce0-b7082c5806c1'}/>
              <br/><br/>
              <p>新規で届いたお助けカートは太字で表示されます。 <span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}>表示</span> をクリックして、お助けカートを開きます。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2F2%E3%81%8A%E5%8A%A9%E3%81%91%E3%82%AB%E3%83%BC%E3%83%88-1.jpg?alt=media&token=b966bfcf-c9ac-455a-b550-c2bb5f2af780'}/>
              <br/><br/>
              <p>お助けカートが表示されます。内容を確認し、 <span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}>カートに追加する</span> をクリックします。</p>
              <p style={{color: '#ff0000'}}>数量等の変更はカートに投入した後に行うことができます。</p>
              <img style={{width: '100%', maxWidth: 796, border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2F3%E3%81%8A%E5%8A%A9%E3%81%91%E3%82%AB%E3%83%BC%E3%83%88.jpg?alt=media&token=6a712e4c-1768-4461-b55e-a328bf458826'}/>
              <br/><br/>
              <div style={{background: '#fffaeb', border: '3px solid #FFD54F', }}>
                <h4 style={{padding: 15, backgroundColor: '#FFD54F'}}> POINT</h4>
                <div style={{padding: 15}}>
                  <p> <span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}>この納品場所をアドレス帳に追加する</span> をクリックすると、販売側が設定した納品場所をアドレス帳に登録することができます。</p>
                  <img style={{width: '100%', maxWidth: 800, border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2F4%E3%81%8A%E5%8A%A9%E3%81%91%E3%82%AB%E3%83%BC%E3%83%88.jpg?alt=media&token=29f6eb15-e466-482e-8a0c-ad6fba5b74d2'}/>
                </div>
              </div>
              <br/>
              <p>カート選択のポップアップが開きます。 <span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}>選択</span> をクリックすると、カートにお助けカートの内容が追加されます。</p>
              <p>※選択したカートにすでに商品が入っている場合、すべて破棄しないとお助けカートの内容を追加することができません。</p>
              <img style={{width: '100%', maxWidth: 542, border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2F5%E3%81%8A%E5%8A%A9%E3%81%91%E3%82%AB%E3%83%BC%E3%83%88.jpg?alt=media&token=be0ccce9-c66e-42f1-ae54-39134436da4a'}/>
            </div>
          },
          {
            id: 2,
            title: 'お助けカートの作成/送信',
            type: '販売側向け',
            link: '/support/otasuke-cart/2',
            data: <div>
              <div style={{backgroundColor: '#fffaeb', borderLeft: '10px solid #FFD54F', padding: 15}}>
                <h4>お助けカートとは</h4>
                <br/>
                <p>販売側が仕入側のカート作成を補助する機能です。</p>
                <p>販売側が商品を選び「お助けカート」を作成し、仕入側に送信することができます。</p>
                <p>仕入側はお助けカートをそのままカートに投入することで、商品を探す手間を省くことができます。</p>
              </div>
              <br/>
              <p>サイドメニューから <span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}>商品購入</span> → <span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}>商品一覧</span> を開きます。</p>
              <p>各商品行の <span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}>お助けカートに入れる</span> をクリックします。</p>
              <p>※商品一覧のチェックボックスをクリックすると、まとめてお助けカートに入れることができます。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2Fs_side_items_2-2048x1136.jpg?alt=media&token=b1087067-835d-40ce-b8a1-ae379872be99'}/>
              <br/><br/>
              <p>お助けカート選択のポップアップ画面が表示されます。</p>
              <p><span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 選択 </span>ボタンをクリックします。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2Fs_popup_otasuke_1-1-2048x1135.jpg?alt=media&token=cc64241b-af8b-40b7-88f7-71ecc98092f5'}/>
              <br/><br/>
              <p>商品をお助けカートへ投入したら、サイドメニューからお助けカートを開きます。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2Fs_side_otasuke_2-2048x1184.jpg?alt=media&token=60c61626-f903-4274-aa36-bd17cbc570d9'}/>
              <br/><br/>
              <p>お助けカートの内容が表示されます。</p>
              <p>①<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> カート名称 </span>：お助けカートの名称を設定することができます。名称は送信先（仕入側）にも表示されます。</p>
              <p>②<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 商品を追加する </span>：お助けカート内から商品を追加することができます。</p>
              <p>③<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> お助けカート送信先 </span>：お助けカート送信先を選択します。</p>
              <p>④<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> このお助けカートを送信する </span>：お助けカート送信先で指定した相手先にお助けカートが届きます。同時にメールで通知が送信されます。</p>
              <img style={{width: '100%', maxWidth: 768, border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2Fs_otasuke_1-1-1536x2048.jpg?alt=media&token=52fd24bd-6990-407f-b55f-794b4d7b86e7'}/>
              <br/><br/>
              <div style={{backgroundColor: '#fffaeb', borderLeft: '10px solid #FFD54F', padding: 15}}>
                <h4>仕入側にお助けカートが届くと</h4>
                <br/>
                <p>お助けカートを送信すると、送信先の事業所内アカウントすべてがお助けカートを開き確認することができます</p>
                <p>仕入側はお助けカートを編集することはできませんが、お助けカートをカートに投入することによってカート内容を編集することができます。</p>
              </div>
            </div>
          },
          {
            id: 3,
            title: '履歴を使ってお助けカートを作成する',
            type: '販売側向け',
            link: '/support/otasuke-cart/3',
            data: <div>
              <p>サイドメニューから<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> お助けカート </span>→<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> お助けカート送信履歴 </span>を開きます。</p>
              <p>お助けカート送信履歴の一覧から編集・再送信したいお助けカートの<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 表示 </span>をクリックします。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fotasuke%2Fs_side_otasukehistory_1-1-1024x592.jpg?alt=media&token=60e24a5a-2747-4d4b-ab72-b568128ccd59'}/>
              <br/><br/>
              <p><span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> この履歴を使ってお助けカートを作成する </span>ボタンをクリックします。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fotasuke%2Fs_otasukehistory_detail-1024x711.jpg?alt=media&token=bfffe807-5ab3-472d-aa87-65e5285a607b'}/>
              <br/><br/>
              <p>お助けカート選択のポップアップが開きます。</p>
              <p><span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 選択 </span>ボタンをクリックします。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fotasuke%2Fs_popup_otasuke_2-300x197.jpg?alt=media&token=d1cdd6d1-042e-450a-83ad-e4ebfd8c2808'}/>
              <br/><br/>
              <p>お助けカートが新規作成されます。※すでにお助けカートに入っていた商品は削除されます。</p>
              <p>商品の追加や各項目を編集することができます</p>
              <p><span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> このお助けカートを送信する </span>をクリックすると、お助けカート送信先で指定した相手先にお助けカートが届きます</p>
              <p>同時にメールで通知が送信されます。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fotasuke%2Fs_otasuke_2-767x1024.jpg?alt=media&token=9a71c9e7-15a6-4344-9c50-68bc0feb158d'}/>
              <br/><br/>
            </div>
          }
        ],
        'favorite-set': [
          {
            id: 1,
            title: 'お気に入りセット登録',
            link: '/support/favorite-set/1',
            data: <div>
              <div style={{backgroundColor: '#fffaeb', borderLeft: '10px solid #FFD54F', padding: 15}}>
                <h4>お気に入りセットとは</h4>
                <br/>
                <p>よく利用する商品をあらかじめお気に入りセットとして登録することができます。</p>
                <p>お気に入りセットはカートもしくはお助けカートに追加することができます。</p>
              </div>
              <br/>
              <p>サイドメニューから<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 商品購入 </span>→<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 商品一覧 </span>を開きます。</p>
              <p>各商品行の<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> お気に入りセット登録 </span>をクリックします。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Ffavorite%2Fb_side_items_1-1024x592.jpg?alt=media&token=04e87e05-8276-423a-90eb-e45bcb2d9631'}/>
              <br/><br/>
              <p>お気に入りセット選択のポップアップ画面が表示されます。</p>
              <p><span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 選択 </span>ボタンをクリックします。</p>
              <p>お気に入りセットに商品が追加されます。</p>
              <img style={{width: '100%', maxWidth: 491, border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Ffavorite%2Fc_popup_favorite_1-674x1024.jpg?alt=media&token=d2b92d42-1d02-4224-9eba-22cd1a512edd'}/>
              <br/><br/>
              <p>サイドメニューから<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> お気に入りセット一覧 </span>を開きます。</p>
              <p>お気に入りセット一覧の中から<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 表示 </span>をクリックします。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Ffavorite%2Fb_favorite_list_1-1024x615.jpg?alt=media&token=a742bc05-3901-4564-be5e-78c85b3fef8b'}/>
              <br/><br/>
              <p>お気に入りセットの内容が表示されます。</p>
              <p>①<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> セット名称 </span>：お気に入りセットの名称を設定することができます。</p>
              <p>②<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 商品を追加する </span>：お気に入りセットに商品を追加することができます。</p>
              <p>③<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> このセットをカートに追加 </span>（<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> このセットをお助けカートに追加 </span>）：お気に入りセットをカート（お助けカート）に追加することができます。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Ffavorite%2Fb_favorite_detail_1-1024x703.jpg?alt=media&token=e9eeaf86-8f85-4ae1-acb1-a8001857202a'}/>
              <br/><br/>
              <div style={{backgroundColor: '#fffaeb', borderLeft: '10px solid #FFD54F', padding: 15}}>
                <h4>POINT</h4>
                <br/>
                <p>お気に入りセットは30セットまで登録することができます。</p>
              </div>
            </div>
          }
        ],
        'delivery-address-list': [
          {
            id: 1,
            title: '納品場所の追加と管理',
            link: '/support/delivery-address-list/1',
            data: <div>
              <div style={{backgroundColor: '#fffaeb', borderLeft: '10px solid #FFD54F', padding: 15}}>
                <h4>POINT</h4>
                <br/>
                <p>よく利用する納品場所をアドレス帳に登録することができます。</p>
              </div>
              <br/>
              <p>サイドメニューから<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 納品場所管理(アドレス帳) </span>を選択します。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fdelivery%2Fb_address_0-2-1024x212.jpg?alt=media&token=432b319d-a356-4dbb-8233-4dba939efa7f'}/>
              <br/><br/>
              <p><span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 新しい納品場所を登録する </span>をクリックします。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fdelivery%2Fb_address_1-1024x247.jpg?alt=media&token=9a5bc482-2a51-4166-885d-eee41c648a6f'}/>
              <br/><br/>
              <p>各項目を入力し、<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 登録 </span>をクリックします。</p>
              <p>アドレス帳に納品場所が追加されます。</p>
              <p>※全ての項目を入力しないと登録できません。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fdelivery%2Fb_address_2-1024x500.jpg?alt=media&token=fd812380-8477-4242-a280-5cf0996b7f00'}/>
              <br/><br/>
              <div style={{backgroundColor: '#fffaeb', borderLeft: '10px solid #FFD54F', padding: 15}}>
                <h4>アドレス帳の各アイコンの説明</h4>
                <br/>
                <p>① 詳細を見る：納品場所の詳細を確認します。</p>
                <p>② 編集：納品場所を編集します。</p>
                <p>③ コピー：すでに登録してある納品場所を複製することができます。</p>
                <p>④ 削除：アドレス帳から納品場所が削除されます。</p>
                <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fdelivery%2Fb_address_3-1024x284.jpg?alt=media&token=efa13ec4-292e-4041-946f-dd383988f92f'}/>
                <br/><br/>
              </div>
            </div>
          }
        ],
        analytics: [
          {
            id: 1,
            title: '購入データを確認する',
            link: '/support/analytics/1',
            data: <div>
              <p>サイドメニューから<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> データ分析 </span>→<span style={{backgroundColor: '#ddff99', color: '#000', padding: '1px 0'}}> 購入データ </span>を開きます。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fanalytics%2F2%E3%82%B5%E3%82%A4%E3%83%88%E3%82%99%E3%83%A1%E3%83%8B%E3%83%A5%E3%83%BC.jpg?alt=media&token=bbec7008-30d3-44e8-9253-e86280a6f318'}/>
              <br/><br/>
              <p>現年度の取引量の推移がグラフと表で表示されます。</p>
              <p style={{color: '#ff0000'}}>データに含まれるのはステータスが出荷済みの商品です。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fanalytics%2F1%E3%83%86%E3%82%99%E3%83%BC%E3%82%BF%E5%88%86%E6%9E%90-1.jpg?alt=media&token=55bea1ec-157b-4ea5-bb39-4cab7a5cb6da'}/>
              <br/><br/>
              <h3 style={{background: '#f5f5f5', paddingLeft: 10}}>
                <strong>データの見方</strong>
              </h3>
              <br/>
              <p><strong>①比較年度選択</strong></p>
              <p>選択した年度と、現年度の取引量を比較することができます。</p>
              <br/>
              <p><strong>②商品選択</strong></p>
              <p>商品ごとの取引量を確認することができます。</p>
              <p>デフォルトでは全商品の取引量が表示されています。</p>
              <p>※出荷履歴のない商品はプルダウンに表示されません。</p>
              <br/>
              <p><strong>③担当者選択</strong></p>
              <p>担当者（仕入側発注者）ごとの取引量を確認することができます。</p>
              <p>デフォルトではヘッダーに表示されている相手先事業所に登録されている担当者（仕入側発注者）全員の取引量が表示されています。</p>
              <br/>
              <p><strong>④購入データダウンロード</strong></p>
              <p>データの一覧をエクセル形式でダウンロードすることができます。</p>
              <p>ファイルでは出荷日最新順にソートされた出荷済みの発注データを一覧で確認することができます。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fanalytics%2F2%E3%83%86%E3%82%99%E3%83%BC%E3%82%BF%E5%88%86%E6%9E%90-1.jpg?alt=media&token=2cba0772-aa5e-4b8a-a93d-80726a35eef3'}/>

            </div>
          }
        ],
        'update-list': [
          {
            id: 43,
            title: 'update list',
            link: '/supportUpdate/43',
            shortData: `工事見積書/請求書/見積書/報告書の機能改善およびPDFダウンロード機能を追加`,
            shortDataDate: `2024年6月19日`,
            date: '2024年6月',
            year: '2024年',
            month: '6月',
            data: <div>
              <h3>工事見積書/請求書/見積書/報告書の機能改善およびPDFダウンロード機能を追加</h3>
              <br/>
              <p>リリース日：2024年6月19日</p>
              <br/>
              <p>【アップデート内容】</p>
              <br/>
              <p>■工事見積書、請求書、見積書、報告書</p>
              <p>・PDFダウンロード機能を追加しました。</p>
              <br/>
              <p>■請求書</p>
              <p>・検索および並べ替え機能の使いやすさを向上させるため、レイアウトを調整しました。</p>
              <br/>
              <p>■工事見積書、見積書、報告書</p>
              <p>・検索機能を改善しました。より詳細なキーワードや条件での検索が可能になりました。</p>
              <p>・並べ替え機能を追加しました。一覧を条件に応じて並べ替えることが可能になりました</p>
              <br/>
              <p>■見積書</p>
              <p>・見積書登録および見積書一覧に「税込合計金額（円）」の項目を追加しました。</p>
            </div>
          },
          {
            id: 42,
            title: 'update list',
            link: '/supportUpdate/42',
            shortData: `新たに納品書機能を追加`,
            shortDataDate: `2024年6月19日`,
            date: '2024年6月',
            year: '2024年',
            month: '6月',
            data: <div>
              <h3>新たに納品書機能を追加</h3>
              <br/>
              <p>リリース日：2024年6月19日</p>
              <br/>
              <p>【アップデート内容】</p>
              <br/>
              <p>新たに納品書登録機能を追加しました。</p>
              <p>販売側のユーザーは納品書をシステム内で簡単に登録することができます。</p>
              <p>仕入側のユーザーは販売側が登録した納品書をリアルタイムで確認することができます。</p>
              <br/>
              <p>登録された納品書はPDF形式でダウンロードが可能です。</p>
              <p>納品書一覧は条件に応じて並べ替えたり、キーワードや条件での検索が可能です。</p>
            </div>
          },
          {
            id: 41,
            title: 'update list',
            link: '/supportUpdate/41',
            shortData: `測定データ画面における軽微な修正`,
            shortDataDate: `2024年5月20日`,
            date: '2024年5月',
            year: '2024年',
            month: '5月',
            data: <div>
              <h3>測定データ画面における軽微な修正</h3>
              <br/>
              <p>リリース日：2024年5月20日</p>
              <br/>
              <p>【アップデート内容】</p>
              <br/>
              <p>測定データ画面において、以下の修正を行いました。</p>
              <ul>
                <li>ポップアップ画面内の一部テキストを修正</li>
                <li>メニューアイコンをクリックしたときに反応しづらい問題を修正</li>
              </ul>
            </div>
          },
          {
            id: 40,
            title: 'update list',
            link: '/supportUpdate/40',
            shortData: `上長ログイン時、カート画面に承認/却下ボタンを追加`,
            shortDataDate: `2024年5月20日`,
            date: '2024年5月',
            year: '2024年',
            month: '5月',
            data: <div>
              <h3>上長ログイン時、カート画面に承認/却下ボタンを追加</h3>
              <br/>
              <p>リリース日：2024年5月20日</p>
              <br/>
              <p>【アップデート内容】</p>
              <br/>
              <p>上長権限者はカート画面の上長承認依頼ボタンをクリックすると、承認/却下ボタンが表示されるようになりました。</p>
              <p>発注書一覧・発注書詳細に遷移せずに、カート画面で承認/却下を行うことができます。</p>
              <p>※仕入モードのみの機能</p>
            </div>
          },
          {
            id: 39,
            title: 'update list',
            link: '/supportUpdate/39',
            shortData: `SDS管理画面にリスクアセスメント管理機能を追加`,
            shortDataDate: `2024年3月21日`,
            date: '2024年3月',
            year: '2024年',
            month: '3月',
            data: <div>
              <h3>SDS管理画面にリスクアセスメント管理機能を追加</h3>
              <br/>
              <p>リリース日：2024年3月21日</p>
              <br/>
              <p>【アップデート内容】</p>
              <br/>
              <p>SDS管理一覧画面にてリスクアセスメント管理機能を追加しました。</p>
              <br/>
              <p>販売側・仕入側は以下のリスクアセスメント管理ファイルのアップロード・ダウンロードが可能です。</p>
              <p>・テンプレートファイル</p>
              <p>・条件記入済みテンプレートファイル</p>
              <p>・チェック済みファイル（販売側はダウンロードのみ）</p>
            </div>
          },
          {
            id: 38,
            title: 'update list',
            link: '/supportUpdate/38',
            shortData: `請求書一覧画面にて表示項目の変更および検索・並べ替え機能を追加`,
            shortDataDate: `2024年3月14日`,
            date: '2024年3月',
            year: '2024年',
            month: '3月',
            data: <div>
              <h3>請求書一覧画面にて表示項目の変更および検索・並べ替え機能を追加</h3>
              <br/>
              <p>リリース日：2024年3月14日</p>
              <br/>
              <p>【アップデート内容】</p>
              <br/>
              <p>請求書一覧画面にて、表示項目を追加・変更しました。</p>
              <p>また、検索および並べ替え機能を追加しました。</p>
              <br/>
              <p>追加・変更された表示項目</p>
              <p>・請求締日（旧表記：請求日）</p>
              <p>・請求書作成日（旧表記：発行日）</p>
              <p>・請求書送信日（旧表記：送信日時）</p>
              <p>・請求書確認日（旧表記：確認日時）</p>
              <p>・税込合計金額（追加）</p>
              <br/>
              <p>追加された検索項目</p>
              <p>・請求書タイトル</p>
              <p>・請求締日</p>
              <p>・請求書作成日</p>
              <p>・請求書送信日</p>
              <p>・請求書確認日</p>
              <p>・税込合計金額</p>
              <p>・送信者</p>
              <p>・受取者</p>
              <p>・請求番号</p>
              <p>・請求書ID</p>
              <br/>
              <p>追加された並べ替え項目</p>
              <p>・請求書作成順</p>
              <p>・請求締日順</p>
            </div>
          },
          {
            id: 37,
            title: 'update list',
            link: '/supportUpdate/37',
            shortData: `請求書登録画面にて入力項目名の変更および追加`,
            shortDataDate: `2024年3月14日`,
            date: '2024年3月',
            year: '2024年',
            month: '3月',
            data: <div>
              <h3>請求書登録画面にて入力項目名の変更および追加</h3>
              <br/>
              <p>リリース日：2024年3月14日</p>
              <br/>
              <p>【アップデート内容】</p>
              <br/>
              <p>販売モードの請求書登録画面にて、下記の入力項目名を変更しました。</p>
              <p>旧表記：請求日　新表記：請求締日</p>
              <p>旧表記：発行日　新表記：請求書作成日</p>
              <br/>
              <p>販売モードの請求書登録画面にて、下記の入力項目を追加しました。</p>
              <p>・税込合計金額（円）</p>
            </div>
          },
          {
            id: 36,
            title: 'update list',
            link: '/supportUpdate/36',
            shortData: `カート画面の入力フォームに必須および任意ラベルを追加`,
            shortDataDate: `2024年1月26日`,
            date: '2024年1月',
            year: '2024年',
            month: '1月',
            data: <div>
              <h3>カート画面の入力フォームに必須および任意ラベルを追加</h3>
              <br/>
              <p>リリース日：2024年1月26日</p>
              <br/>
              <p>【アップデート内容】</p>
              <br/>
              <p>カート画面の各入力フォームに必須および任意のラベルを追加しました。</p>
            </div>
          },
          {
            id: 35,
            title: 'update list',
            link: '/supportUpdate/35',
            shortData: `SDS管理一覧でのランプ表示に関するバグの修正`,
            shortDataDate: `2024年1月5日`,
            date: '2024年1月',
            year: '2024年',
            month: '1月',
            data: <div>
              <h3>SDS管理一覧でのランプ表示に関するバグの修正</h3>
              <br/>
              <p>リリース日：2024年1月5日</p>
              <br/>
              <p>【アップデート内容】</p>
              <br/>
              <p>下記のバグを修正しました。</p>
              <br/>
              <p>・SDS管理一覧画面において、年度をまたぐ際にランプの色が反映されない問題</p>
            </div>
          },
          {
            id: 34,
            title: 'update list',
            link: '/supportUpdate/34',
            shortData: `発注書一覧・注文書一覧にて数量欄の文字サイズおよび太さを変更`,
            shortDataDate: `2023年12月22日`,
            date: '2023年12月',
            year: '2023年',
            month: '12月',
            data: <div>
              <h3>発注書一覧・注文書一覧にて数量欄の文字サイズおよび太さを変更</h3>
              <br/>
              <p>リリース日：2023年12月22日</p>
              <br/>
              <p>【アップデート内容】</p>
              <br/>
              <p>仕入モードでの発注書一覧・発注書および販売モードでの注文書一覧・注文書画面にて、数量欄の文字のサイズと太さを変更しました。</p>
            </div>
          },
          {
            id: 33,
            title: 'update list',
            link: '/supportUpdate/33',
            shortData: `注文書での入力に関するバグを修正`,
            shortDataDate: `2023年12月19日`,
            date: '2023年12月',
            year: '2023年',
            month: '12月',
            data: <div>
              <h3>注文書での入力に関するバグを修正</h3>
              <br/>
              <p>リリース日：2024年3月14日</p>
              <br/>
              <p>【アップデート内容】</p>
              <br/>
              <p>以下のバグを修正しました。</p>
              <p>・販売モードでの注文書画面にて、納期回答入力後の出荷日入力ができない問題</p>
            </div>
          },
          {
            id: 32,
            title: 'update list',
            link: '/supportUpdate/32',
            shortData: `販売モードの工事見積書、請求書、見積書、報告書の各一覧画面にて削除済みの行のみを表示する機能を追加`,
            shortDataDate: `2023年12月18日`,
            date: '2023年12月',
            year: '2023年',
            month: '12月',
            data: <div>
              <h3>販売モードの工事見積書、請求書、見積書、報告書の各一覧画面にて削除済みの行のみを表示する機能を追加</h3>
              <br/>
              <p>リリース日：2023年12月18日</p>
              <br/>
              <p>【アップデート内容】</p>
              <br/>
              <p>販売モードの工事見積書、請求書、見積書、報告書の各一覧画面にて、削除した行のみの表示ができるようになりました。</p>
              <p>プルダウンメニューから下記の項目を選択し、表示を切り替えることができます。</p>
              <ul>
                <li>送信済一覧</li>
                <li>削除済一覧</li>
                <li>すべて</li>
              </ul>
            </div>
          },
          {
            id: 31,
            title: 'update list',
            link: '/supportUpdate/31',
            shortData: `工事見積書、請求書、見積書、報告書の登録画面でメール通知の宛先にCC指定機能を追加`,
            shortDataDate: `2023年12月15日`,
            date: '2023年12月',
            year: '2023年',
            month: '12月',
            data: <div>
              <h3>工事見積書、請求書、見積書、報告書の登録画面でメール通知の宛先にCC指定機能を追加</h3>
              <br/>
              <p>リリース日：2023年12月15日</p>
              <br/>
              <p>【アップデート内容】</p>
              <br/>
              <p>販売モードの工事見積書、請求書、見積書、報告書の各登録画面にて、メール通知の宛先にCCを指定できるようになりました。</p>
              <p>これにより新規登録時のメール通知を担当者以外に送信することができます。</p>
              <p>CCは複数選択することが可能です。</p>
              <p>※宛先に指定した相手先担当者およびCCの履歴は保存されません。</p>
              <p>※相手先担当者およびCCに指定したユーザーが新規登録時のメール通知設定をオフにしている場合、メールは届きません。</p>
            </div>
          },
          {
            id: 30,
            title: 'update list',
            link: '/supportUpdate/30',
            shortData: `カートにて相手先業務担当者を設定できる機能を追加`,
            shortDataDate: `2023年12月11日`,
            date: '2023年12月',
            year: '2023年',
            month: '12月',
            data: <div>
              <h3>カートにて相手先業務担当者を設定できる機能を追加</h3>
              <br/>
              <p>リリース日：2023年12月11日</p>
              <br/>
              <p>【アップデート内容】</p>
              <br/>
              <p>仕入モードのカート内にて、相手先の営業担当者に加え業務担当者を設定できるようになりました。</p>
              <p>これにより相手先が受け取る新規注文受取時のメール通知を、営業担当者以外に送信することができます。</p>
              <p>相手先業務担当者は複数選択することが可能です。</p>
              <p>また、設定した業務担当者の履歴が保存され、次回以降も既選択状態でプルダウンメニューに表示されます</p>
              <p>※相手先営業担当者および相手先業務担当者が新規注文受取時のメール通知設定をオフにしている場合、メールは届きません。</p>
            </div>
          },
          {
            id: 29,
            title: 'update list',
            link: '/supportUpdate/29',
            shortData: `商品一覧およびお気に入りセット一覧にpopupメッセージ追加`,
            shortDataDate: `2023年11月22日`,
            date: '2023年11月',
            year: '2023年',
            month: '11月',
            data: <div>
              <h3>商品一覧およびお気に入りセット一覧にpopupメッセージ追加</h3>
              <br/>
              <p>リリース日：2023年11月22日</p>
              <br/>
              <p>【アップデート内容】</p>
              <br/>
              <p>商品一覧、お気に入りセット一覧からカートに追加完了したことがpopupメッセージで確認できるようになりました。</p>
            </div>
          },
          {
            id: 28,
            title: 'update list',
            link: '/supportUpdate/28',
            shortData: `お気に入りセットからカートへ追加する際の画面遷移の変更`,
            shortDataDate: `2023年11月22日`,
            date: '2023年11月',
            year: '2023年',
            month: '11月',
            data: <div>
              <h3>お気に入りセットからカートへ追加する際の画面遷移の変更</h3>
              <br/>
              <p>リリース日：2023年11月22日</p>
              <br/>
              <p>【アップデート内容】</p>
              <br/>
              <p>従来はお気に入りセットからカートへ追加する際にカート画面に遷移していましたが、カート画面に遷移せず追加することができるように変更しました。</p>
            </div>
          },
          {
            id: 27,
            title: 'update list',
            link: '/supportUpdate/27',
            shortData: `SDS管理一覧並べ替え機能およびSDSダウンロード機能追加`,
            shortDataDate: `2023年11月22日`,
            date: '2023年11月',
            year: '2023年',
            month: '11月',
            data: <div>
              <h3>SDS管理一覧並べ替え機能およびSDSダウンロード機能追加</h3>
              <br/>
              <p>リリース日：2023年11月22日</p>
              <br/>
              <p>【アップデート内容】</p>
              <br/>
              <p>SDS管理一覧画面において、管理中SDSを個別チェックボックス選択または管理中SDSをすべて一括でダウンロードすることができます。</p>
              <p>また、下記の順でSDS管理一覧を並べ替えることができます。</p>
              <br/>
              <p>・受付日順</p>
              <p>・商品名順</p>
              <p>・最新改定日順</p>
              <p>・最終チェック日順</p>
            </div>
          },
          {
            id: 26,
            title: 'update list',
            link: '/supportUpdate/26',
            shortData: `コメント送受信機能を追加`,
            shortDataDate: `2023年11月22日`,
            date: '2023年11月',
            year: '2023年',
            month: '11月',
            data: <div>
              <h3>コメント送受信機能を追加</h3>
              <br/><br/>
              <p>リリース日：2023年11月22日</p>
              <br/>
              <p>【アップデート内容】</p>
              <br/>
              <p>注文書においてコメント送信機能が追加されました。</p>
              <p>納期回答および出荷日/送り状番号の入力欄からコメントを登録/送信することができます。</p>
              <p>販売側がコメントを登録/送信すると、購入側にコメントアイコンが表示されます。</p>
              <br/>
              <p>コメントアイコンの表示</p>
              <p>未読：緑</p>
              <p>既読：グレー</p>
              <br/>
              <p>※分納手続き中は出荷日/送り状番号のコメント欄の編集および送信はできません。</p>
              <p>また、分納が確定するまでコメントは既読になりません。</p>
            </div>
          },
          {
            id: 25,
            title: 'update list',
            link: '/supportUpdate/25',
            shortData: `分納機能を追加`,
            shortDataDate: `2023年11月22日`,
            date: '2023年11月',
            year: '2023年',
            month: '11月',
            data: <div>
              <h3>分納機能を追加</h3>
              <br/><br/>
              <p>リリース日：2023年11月22日</p>
              <br/>
              <p>【アップデート内容】</p>
              <br/>
              <p>注文書において分納機能を追加しました。</p>
              <p>販売側は出荷前の注文を複数に分けて発送処理を行うことができます。</p>
              <p>販売側が分納手続き中の注文は購入側の発注書にも表示されます。</p>
              <br/>
              <p>・ステータスラベルの表示</p>
              <p>商品行に「分納手続き中」もしくは「分納確定」を示すステータスラベルが表示されます。</p>
              <p>発注書を表示/印刷すると、同様にラベルが表示されます。</p>
              <p>「分納手続き中」 ラベル名＝分納手続中（赤</p>
              <p>「分納確定」 ラベル名＝分納 （グレー）</p>
              <p>※分納確定後であっても出荷前の商品であれば再度分納手続きをすることができます。</p>
              <br/>
              <p>・メール通知</p>
              <p>販売側が分納手続き開始および分納確定すると、購入側にメール通知されます。</p>
              <br/><br/>
              <p><Link to={'/support/order/6'} target="_blank">分納された注文を確認する</Link>（購入側向け）</p>
              <p><Link to={'/support/orderReceived/4'} target="_blank">分納をする</Link>（販売側向け）</p>
            </div>
          },
          {
            id: 24,
            title: 'update list',
            link: '/supportUpdate/24',
            shortData: `お助けカート受信時のバッジ表示機能を追加`,
            shortDataDate: `2023年9月11日`,
            date: '2023年9月',
            year: '2023年',
            month: '9月',
            data: <div>
              <h3>お助けカート受信時のバッジ表示機能を追加</h3>
              <br/><br/>
              <p>リリース日：2023年9月11日</p>
              <br/>
              <p>【アップデート内容】</p>
              <p><Link to={'/support/otasuke-cart/1#search'}>お助けカート受信時のバッジ表示機能</Link>が追加されました。</p>
              <br/>
              <p>販売側がお助けカートを送信すると、仕入側事業所内すべての登録アカウントのサイドメニュー、ヘッダーにバッジが表示されます。</p>
              <p>事業所内のいずれかのアカウントが受信したお助けカートを既読にすると、事業所内すべての登録アカウントの画面からバッジ件数が減少します。</p>
            </div>
          },
          {
            id: 23,
            title: 'update list',
            link: '/supportUpdate/23',
            shortData: `商品一覧でのメーカー名検索機能を追加`,
            shortDataDate: `2023年9月11日`,
            date: '2023年9月',
            year: '2023年',
            month: '9月',
            data: <div>
              <h3>商品一覧でのメーカー名検索機能を追加</h3>
              <br/><br/>
              <p>リリース日：2023年9月11日</p>
              <br/>
              <p>【アップデート内容】</p>
              <p>商品一覧に<HashLink to={'/support/order/1#search'}>メーカー名検索機能</HashLink>を追加しました。</p>
              <br/>
              <p>従来の全文検索、商品名検索に加え、新たにメーカー名での検索ができるようになりました。</p>
            </div>
          },
          {
            id: 22,
            title: 'update list',
            link: '/supportUpdate/22',
            shortData: `発注書のPDFファイル出力機能を追加`,
            shortDataDate: `2023年9月1日`,
            date: '2023年9月',
            year: '2023年',
            month: '9月',
            data: <div>
              <h3>発注書のPDFファイル出力機能を追加</h3>
              <br/><br/>
              <p>リリース日：2023年9月1日</p>
              <br/>
              <p>【アップデート内容】</p>
              <p>発注書のPDFファイル出力機能が追加されました。</p>
              <br/>
              <p>サイドメニュー[発注書一覧]から発注書を開き、[PDF 表示]ボタンをクリックするとPDF出力のプレビューが表示されます。</p>
              <p>プレビュー画面の[PDF ダウンロード]ボタンをクリックすると、PDFファイル形式の発注書をダウンロードすることができます。</p>
              <p>※PDF出力できるのは上長承認済み（発注確定後）の発注書のみになります。</p>
            </div>
          },
          {
            id: 21,
            title: 'update list',
            link: '/supportUpdate/21',
            shortData: `発注データおよび商品データのファイルダウンロード機能を追加`,
            shortDataDate: `2023年9月1日`,
            date: '2023年9月',
            year: '2023年',
            month: '9月',
            data: <div>
              <h3>発注データおよび商品データのファイルダウンロード機能を追加</h3>
              <br/><br/>
              <p>リリース日：2023年9月1日</p>
              <br/>
              <p>【アップデート内容】</p>
              <p>１．発注データ一覧のファイルダウンロード機能が追加されました</p>
              <p>サイドメニュー[データ分析]→[購入データ(ベータ版)]の画面から、[購入データ ダウンロード]ボタンをクリックするとダウンロードすることができます。（Excel形式）</p>
              <p>ダウンロードファイルでは出荷日最新順にソートされた出荷済みの発注データ一覧を確認していただくことができます。</p>
              <br/>
              <p>２．商品データ一覧のファイルダウンロード機能が追加されました</p>
              <p>サイドメニュー[商品一覧]を開き、[商品一覧 ダウンロード]ボタンをクリックすると登録商品の一覧をダウンロードすることができます。（Excel形式）</p>
            </div>
          },
          {
            id: 20,
            title: 'update list',
            link: '/supportUpdate/20',
            shortData: `データ分析機能ベータ版をリリース`,
            shortDataDate: `2023年7月11日`,
            date: '2023年7月',
            year: '2023年',
            month: '7月',
            data: <div>
              <h3>データ分析機能ベータ版をリリース</h3>
              <br/><br/>
              <p>リリース日：2023年7月11日</p>
              <br/>
              <p>【アップデート内容】</p>
              <p>１．データ分析機能ベータ版をリリース</p>
              <p>担当者ごとの取引量の推移を確認いただけるようになりました。</p>
              <br/>
              <p>■使いかた</p>
              <p>サイドメニューから[データ分析]→[購入データ]を選択します。</p>
              <p>比較年度、商品、担当者を選択すると、取引量の推移がグラフと表で表示されます。</p>
              <p>※データに含まれるのはステータスが出荷済みの商品のみです。</p>
              <br/>
              <p>２．その他、軽微な修正</p>
              <p>カタログ一覧、請求書一覧の不具合の修正を行いました。</p>
            </div>
          },
          {
            id: 19,
            title: 'update list',
            link: '/supportUpdate/19',
            shortData: `相手先選択プルダウンリストに検索ボックスを追加`,
            shortDataDate: `2023年6月27日`,
            date: '2023年6月',
            year: '2023年',
            month: '6月',
            data: <div>
              <h3>相手先選択プルダウンリストに検索ボックスを追加</h3>
              <br/><br/>
              <p>リリース日：2023年6月27日</p>
              <br/>
              <p>【アップデート内容】</p>
              <p>相手先選択プルダウンリストに検索ボックスが追加されました。</p>
              <p>検索ボックスに文字列を入力すると検索結果候補一覧が表示されます。</p>
              <p>[TAB]キー（[Shift]+[TAB]キー）もしくはカーソルキーで移動し、[Enter]キーで相手先事業所が確定します。</p>
            </div>
          },
          {
            id: 18,
            title: 'update list',
            link: '/supportUpdate/18',
            shortData: `SDS管理機能を追加`,
            shortDataDate: `2023年6月19日`,
            date: '2023年6月',
            year: '2023年',
            month: '6月',
            data: <div>
              <h3>SDS管理機能を追加</h3>
              <br/><br/>
              <p>リリース日：2023年6月19日</p>
              <br/>
              <p>【アップデート内容】</p>
              <p>SDS管理機能を追加しました。</p>
              <br/>
              <p>仕入側はSDSが登録されている商品のSDS定期チェックを申し込むことができます。</p>
              <p>定期チェックは毎年自動で更新されます。</p>
              <br/>
              <p>■申込方法（仕入側の機能）</p>
              <p>申し込みは商品一覧画面の「SDS管理申込」ボタンから行います。</p>
              <p>ご希望のチェック月を選択していただくことができます。</p>
              <p>※チェック日は各月1日です</p>
              <br/>
              <p>■申込および受付の確認（仕入側、販売側の機能）</p>
              <p>仕入側がSDS管理申込をすると以下の箇所に緑色のランプが点灯します。</p>
              <p>・ヘッダーのSDS欄</p>
              <p>・サイドメニュー「SDS管理一覧」</p>
              <p>・相手先選択プルダウン（※販売側のみの表示）</p>
              <br/>
              <p>■受付方法（販売側の機能）</p>
              <p>サイドメニュー「SDS管理一覧」の受付処理ボタンをクリックすることで受付が完了します。</p>
              <br/>
              <p>■申込内容およびチェック実施履歴の確認（仕入側、販売側の機能）</p>
              <p>サイドメニュー「SDS管理一覧」から確認することができます。</p>
              <p>販売側が受付処理をすると、ヘッダーおよびSDS管理一覧画面に管理状況を示すランプが点灯します。</p>
              <br/>
              <p>[表示]</p>
              <p>赤色：希望日を過ぎてチェック更新されていない状態</p>
              <p>青色：希望日を過ぎてチェック更新されている状態</p>
              <p>黄色：チェック更新日が1週間以内</p>
            </div>
          },
          {
            id: 17,
            title: 'update list',
            link: '/supportUpdate/17',
            shortData: `上長承認依頼時に数量0の商品を削除する機能を追加`,
            shortDataDate: `2023年4月19日`,
            date: '2023年4月',
            year: '2023年',
            month: '4月',
            data: <div>
            <h3>上長承認依頼時に数量0の商品を削除する機能を追加</h3>
            <br/><br/>
            <p>リリース日：2023年4月19日</p>
            <br/>
            <p>【アップデート内容】</p>
            <p>カートに数量0の商品が含まれている場合、上長承認依頼ボタンをクリックするとポップアップガイダンスが表示されます。</p>
            <p>そのまま上長承認依頼をすると数量0の商品は削除されます。</p>
            <p>削除された商品は発注書には表示されません。</p>
            <p>※仕入モードのみの機能です。</p>
            </div>
          },
          {
            id: 16,
            title: 'update list',
            link: '/supportUpdate/16',
            shortData: `商品一覧画面にSDS、取扱説明書、カタログの表示ボタンを追加`,
            shortDataDate: `2023年4月19日`,
            date: '2023年4月',
            year: '2023年',
            month: '4月',
            data: <div>
            <h3>商品一覧画面にSDS、取扱説明書、カタログの表示ボタンを追加</h3>
            <br/><br/>
            <p>リリース日：2023年4月19日</p>
            <br/>
            <p>【アップデート内容】</p>
            <p>商品一覧画面にSDS、取扱説明書、カタログの表示ボタンを追加</p>
            <br/>
            <p>各商品行にSDS、取扱説明書、カタログを表示するボタンが追加されました。</p>
            <p>SDSが登録されている商品は、商品一覧画面の[SDS登録商品]にチェックを入れ[表示]をクリックすることで絞り込んで表示させることができます。</p>
            <p>※SDS、取扱説明書、カタログの表示ボタンは、登録がない場合は表示されません。</p>
            </div>
          },
          {
            id: 15,
            title: 'update list',
            link: '/supportUpdate/15',
            shortData: `出荷完了ステータス表示機能の追加とバグの修正`,
            shortDataDate: `2023年3月22日`,
            date: '2023年3月',
            year: '2023年',
            month: '3月',
            data: <div>
              <h3>出荷完了ステータス表示機能の追加とバグの修正</h3>
              <br/><br/>
              <p>リリース日：2023年3月22日</p>
              <br/>
              <p>【アップデート内容】</p>
              <p>１．出荷完了ステータスを表すランプ表示及びソート機能が追加されました。</p>
              <br/>
              <p>[ランプ表示]</p>
              <p>発注書一覧/注文書一覧</p>
              <p>・緑のランプ：すべての商品が出荷済みの状態</p>
              <p>・赤のランプ：未出荷の商品が含まれている状態</p>
              <p>発注書詳細/注文書詳細</p>
              <p>・緑のランプ：商品が出荷済みの状態</p>
              <p>・赤のランプ：商品が未出荷の状態</p>
              <br/>
              <p>注文書の”出荷日/送り状番号”に入力して送信すると、ランプが赤から緑に変わります。</p>
              <br/>
              <p>※発注書一覧/注文書一覧の赤のランプの件数が以下の場所に表示されます。</p>
              <p>・画面右上のログインアカウント名の横</p>
              <p>・相手先選択プルダウン（販売モードのみ）</p>
              <p>・サイドメニュー</p>
              <br/>
              <p>[ソート機能]　追加選択肢</p>
              <p>発注書一覧/注文書一覧</p>
              <p>・「出荷済」</p>
              <p>・「未出荷あり」</p>
              <br/>
              <p>２．バグの修正</p>
              <p>お気に入りセット、お助けカートに販売終了商品が含まれるとき、画面が表示されないバグを修正しました。</p>
            </div>
          },
          {
            id: 14,
            title: 'update list',
            link: '/supportUpdate/14',
            shortData: `商品の価格変更に関する機能を改善`,
            shortDataDate: `2023年2月8日`,
            date: '2023年2月',
            year: '2023年',
            month: '2月',
            data: <div>
              <h3>商品の価格変更に関する機能を改善</h3>
              <br/><br/>
              <p>リリース日：2023年2月8日</p>
              <br/>
              <p>【アップデート内容】</p>
              <p>商品の価格変更時、次の各商品単価も自動的に変更されるようになりました。</p>
              <br/>
              <ul>
                <li>
                  <p>通常カート（仕入モード）</p>
                  <p>上長承認依頼前、承認依頼中、却下再編集の価格が変更されます。</p>
                </li>
                <li>
                  <p>発注書（仕入モード）</p>
                  <p>上長承認依頼前の価格が変更されます。</p>
                </li>
                <li>
                  <p>お助けカート（販売モード）</p>
                  <p>送信前のお助けカートは変更されます。送信済お助けカート履歴の商品価格は変更されません。</p>
                </li>
                <li>
                  <p>お助けカート（仕入モード）</p>
                </li>
                <li>
                  <p>お気に入りセット（仕入モード、販売モード）</p>
                </li>
              </ul>
            </div>
          },
          {
            id: 13,
            title: 'update list',
            link: '/supportUpdate/13',
            shortData: `お気に入りセット登録上限変更と商品単価の表示機能改善`,
            shortDataDate: `2023年1月24日`,
            date: '2023年1月',
            year: '2023年',
            month: '1月',
            data: <div>
              <h3>お気に入りセット登録上限変更と商品単価の表示機能改善</h3>
              <br/><br/>
              <p>【アップデート内容】</p>
              <br/>
              <ol>
                <li>
                  <p>お気に入りセット登録上限変更</p>
                  <p>お気に入りセットの登録の上限が10セットから30セットに変更されました。</p>
                </li>
                <li>
                  <p>商品単価の表示</p>
                  <p>次の画面内に商品単価が表示されるようになりました。</p>
                </li>
              </ol>
              <br/>
              <p>■ 仕入れモード</p>
              <br/>
              <ul>
                <li>
                  <p>通常カート</p>
                  <p>発注書詳細</p>
                  <p>お助けカート詳細</p>
                  <p>お気に入りセット詳細</p>
                </li>
              </ul>
              <br/>
              <p>■ 販売モード</p>
              <br/>
              <ul>
                <li>
                  <p>お助けカート</p>
                  <p>お助けカート送信履歴詳細</p>
                  <p>注文書詳細</p>
                  <p>お気に入りセット詳細</p>
                </li>
              </ul>
            </div>
          },
          {
            id: 12,
            title: 'update list',
            link: '/supportUpdate/12',
            shortData: `納品場所管理(アドレス帳)機能を追加`,
            shortDataDate: `2022年12月26日`,
            date: '2022年12月',
            year: '2022年',
            month: '12月',
            data: <div>
              <h3>納品場所管理(アドレス帳)機能を追加</h3>
              <br/><br/>
              <p>リリース日：2022年12月26日</p>
              <br/>
              <p>【アップデート内容】</p>
              <br/>
              <p>サイドメニューに納品場所管理(アドレス帳)が追加されました。</p>
              <p>ユーザーは納品場所の新規登録や編集、削除をすることができます。</p>
              <br/>
              <p>カート（販売側はお助けカート）では納品先の直接入力以外に、</p>
              <p>アドレス帳に登録した納品先や前回設定した納品先を選択することができます。</p>
              <p>※前回設定した納品先は発注履歴のあるユーザーのみ</p>
            </div>
          },
          {
            id: 11,
            title: 'update list',
            link: '/supportUpdate/11',
            shortData: `カートにて相手先担当者が自動的に選択される機能を追加`,
            shortDataDate: `2022年11月28日`,
            date: '2022年11月',
            year: '2022年',
            month: '11月',
            data: <div>
              <h3>カートにて相手先担当者が自動的に選択される機能を追加</h3>
              <br/><br/>
              <p>リリース日：2022年11月28日</p>
              <br/>
              <p>【アップデート内容】</p>
              <br/>
              <p>・カート内で相手先担当者が自動的に選択されます</p>
              <p>毎回スクロールして相手先担当者を選択する手間を省きたいというご要望にお応えし、</p>
              <p>商品が入っているカート（１～５）を開いたとき、前回選択した相手先担当者が自動的に選択されるようになりました。</p>
              <p>※本機能はアップデート後にいずれかのカートで「上長承認依頼」を行なった時点から有効になります。</p>
            </div>
          },
          {
            id: 10,
            title: 'update list',
            link: '/supportUpdate/10',
            shortData: `商品一覧、発注書一覧/注文書一覧に検索機能を追加`,
            shortDataDate: `2022年11月18日`,
            date: '2022年11月',
            year: '2022年',
            month: '11月',
            data: <div>
              <h3>商品一覧、発注書一覧/注文書一覧に検索機能を追加</h3>
              <br/><br/>
              <p>１．商品一覧</p>
              <p>従来の全文検索に加え、新たに商品名検索ができるようになりました。</p>
              <br/>
              <p>２．発注書一覧/注文書一覧</p>
              <p>一覧画面に検索ボックスが追加されました。</p>
              <p>キーワードを入力して目的のデータを検索することができます。</p>
            </div>
          },
          {
            id: 9,
            title: 'update list',
            link: '/supportUpdate/9',
            shortData: `工事見積書/請求書/見積書/報告書に検索機能、並べ替え機能を追加`,
            shortDataDate: `2022年11月8日`,
            date: '2022年11月',
            year: '2022年',
            month: '11月',
            data: <div>
              <h3>工事見積書/請求書/見積書/報告書に検索機能、並べ替え機能を追加</h3>
              <br/><br/>
              <p>リリース日：2022年11月8日</p>
              <p>【アップデート内容】</p>
              <br/>
              <p>１．検索機能</p>
              <p>各一覧画面に検索ボックスが追加されました。</p>
              <p>キーワードを入力して目的のデータを検索することができます。</p>
              <br/>
              <p>２．並べ替え機能</p>
              <p>発行日順に並ぶデータを昇順または降順に切り替えることができます。</p>
            </div>
          },
          {
            id: 8,
            title: 'update list',
            link: '/supportUpdate/8',
            shortData: `工事見積書に新機能を追加`,
            shortDataDate: `2022年10月27日`,
            date: '2022年10月',
            year: '2022年',
            month: '10月',
            data: <div>
              <h3>工事見積書に新機能を追加</h3>
              <br/><br/>
              <p>リリース日：2022年10月27日</p>
              <hr/>
              <p>【アップデート内容】</p>
              <p>１．メール通知機能</p>
              <p>２．バッジ表示機能</p>
              <p>３．承認・却下機能（仕入側の機能）</p>
              <p>４．受注確認機能（販売側の機能）</p>
              <p>５．新規登録時の入力項目追加</p>
              <p>６．工事見積書一覧の表示項目追加</p>
              <hr/>
              <h4>１．メール通知機能</h4>
              <br/>
              <p>次の項目のメール通知を設定することができます。</p>
              <br/>
              <p>【仕入側の受信設定】</p>
              <p>・販売側が工事見積書を新規発行した時</p>
              <p>・販売側が工事見積書を削除した時</p>
              <br/>
              <p>【販売側の受信設定】</p>
              <p>・仕入側が工事見積書を承認した時（工事見積書による新規注文をした時）</p>
              <p>・仕入側が工事見積書を却下した時</p>
              <br/>
              <p>※メールの受信/拒否はヘッダーのユーザー名をクリックし、プロフィール情報の「通知メール受信設定」で切り替えることができます。</p>
              <br/>
              <h4>２．工事見積書の新規登録時のバッジ表示機能</h4>
              <br/>
              <p>販売側が工事見積書を新規登録した時、販売側・仕入側いずれも</p>
              <p>1) 相手先選択プルダウンボックス</p>
              <p>2) サイドメニュー>工事見積書</p>
              <p>にバッジが表示されます。</p>
              <br/>
              <p>バッジは事業所内登録アカウントすべてに表示されます。</p>
              <p>事業所内のいずれかのアカウントが[表示]をクリックすると、事業所内全アカウントの画面からバッジ件数が減少します。</p>
              <br/>
              <h4>３．承認・却下機能（仕入側の機能）</h4>
              <br/>
              <p>次のアカウントは工事見積書を承認・却下することができます。</p>
              <br/>
              <p>・販売側が工事見積書登録時に相手先担当者として指定したアカウント</p>
              <p>・事業所内の上長権限を持つアカウント</p>
              <br/>
              <h4>４．受注確認機能（販売側の機能）</h4>
              <br/>
              <p>工事見積書による新規注文が入ると、工事見積書一覧に[受注確認]ボタンが表示されます。</p>
              <p>[受注確認]ボタンをクリックすると販売側・仕入側いずれも[受注確認済]ラベルが表示され、受注確認者/確認日時が表示されます。</p>
              <br/>
              <h4>５．工事見積書の新規登録時の入力項目追加</h4>
              <br/>
              <p>新規登録時の入力項目に「納品日」が追加されました。</p>
              <br/>
              <h4>６．工事見積書一覧の表示項目追加</h4>
              <br/>
              <p>工事見積書一覧に下記の項目が追加されました。</p>
              <br/>
              <p>【見積書ID】P4CLOUDで管理されるドキュメントIDが表示されます。</p>
              <p>【送信者/送信日時】工事見積書を送信したユーザーと送信日時が表示されます。</p>
              <p>【見積確認者/確認日時】仕入側で最初に工事見積書を確認したユーザーと確認日時が表示されます。</p>
              <p>【発行日/有効期限/納品日】販売側が工事見積書登録時に指定した日付が表示されます。</p>
              <p>【ステータス確定日時】承認・却下した日時が表示されます。日時の上に承認・却下したユーザー名とステータスを表すラベルが表示されます。</p>
              <p>【削除ボタン※販売側のみ】ゴミ箱アイコンをクリックすると、ステータスラベル表示欄に[削除済]ラベルが表示されます。 削除をしても販売側の一覧には表示されますが、仕入側の一覧には表示されません。</p>
            </div>
          },
          {
            id: 7,
            title: 'update list',
            link: '/supportUpdate/7',
            shortData: `お気に入りセット機能を追加`,
            shortDataDate: `2022年9月20日`,
            date: '2022年9月',
            year: '2022年',
            month: '9月',
            data: <div>
              <h3>お気に入りセット機能を追加</h3>
              <br/><br/>
              <p>リリース日：2022年9月20日</p>
              <br/>
              <hr/>
              <p>機能概要</p>
              <hr/>
              <p>よく利用する商品をあらかじめお気に入りセットとして登録することができます。</p>
              <p>お気に入りセットはカートもしくはお助けカートに追加することができます。</p>
              <hr/>
              <p>利用手順</p>
              <hr/>
              <p>【お気に入りセットを登録する】</p>
              <br/>
              <p>■商品一覧から登録する</p>
              <ol>
                <li>
                  <p>サイドメニューの[商品購入]→[商品一覧]をクリックします。</p>
                </li>
                <li>
                  <p>登録したい商品の[お気に入りセット登録]ボタンをクリックします</p>
                  <p>お気に入りセット登録のポップアップが開きます。</p>
                </li>
                <li>
                  <p>[選択]をクリックするとお気に入りセットに商品が追加されます</p>
                </li>
              </ol>
              <br/>
              <p>■お気に入りセット一覧から登録する</p>
              <ol>
                <li>
                  <p>サイドメニューの[お気に入り]→[お気に入りセット一覧]をクリックします。</p>
                </li>
                <li>
                  <p>一覧の中から[表示]ボタンをクリックします。お気に入りセットが開きます。</p>
                </li>
                <li>
                  <p>[商品を追加する]をクリックします。商品一覧画面が表示されます。</p>
                </li>
                <li>
                  <p>登録したい商品の[お気に入りセット登録]ボタンをクリックします。</p>
                  <p>お気に入りセットに商品が追加されます。</p>
                </li>
              </ol>
              <br/>
              <p>※お気に入りセットに名称を付け保存することができます。</p>
              <p>※お気に入りセットは10セットまで登録することができます。</p>
              <br/>
              <p>【お気に入りセットをお助けカートに追加する】 ※販売側の操作</p>
              <br/>
              <ol>
                <li>
                  <p>サイドメニューの[お気に入り]→[お気に入りセット一覧]をクリックします。</p>
                </li>
                <li>
                  <p>[お助けカートに追加]ボタンをクリックします。お助けカート選択のポップアップが開きます。</p>
                </li>
                <li>
                  <p>[選択]をクリックします。</p>
                  <p>[お助けカートを空にして追加する][お助けカートに追加する]のいずれかを選択します。</p>
                </li>
                <li>お助けカートにお気に入りセットが追加されます。</li>
              </ol>
              <br/>
              <p>【お気に入りセットをカートに追加する】 ※仕入側の操作</p>
              <br/>
              <ol>
                <li>
                  <p>サイドメニューの[お気に入り]→[お気に入りセット一覧]をクリックします。</p>
                </li>
                <li>
                  <p>[カートに追加]ボタンをクリックします。カート選択のポップアップが開きます。</p>
                </li>
                <li>
                  <p>[選択]をクリックします。</p>
                  <p>[カートを空にして追加する][カートに追加する]のいずれかを選択します。</p>
                </li>
                <li>
                  <p>カートにお気に入りセットが追加されます。</p>
                </li>
              </ol>
            </div>,
          },
          {
            id: 6,
            title: 'update list',
            link: '/supportUpdate/6',
            shortData: `注文書一覧/発注書一覧に並べ替え機能追加、その他機能改善`,
            shortDataDate: `2022年9月5日`,
            date: '2022年9月',
            year: '2022年',
            month: "9月",
            data: <div>
              <h3>注文書一覧/発注書一覧に並べ替え機能追加、その他機能改善</h3>
              <br/><br/>
              <p>リリース日：2022年9月5日</p>
              <br/>
              <p>【更新内容】</p>
              <p>P4CLOUDの下記機能についてアップデートを実施しました。</p>
              <br/>
              <h5>１．並べ替え機能</h5>
              <br/>
              <p>注文書一覧、発注書一覧をステータスで絞り、並べ替えをすることができます。</p>
              <br/>
              <p>■注文書一覧（販売側）</p>
              <p>すべて：すべての注文書を受注日時順に表示</p>
              <p>最新更新順：すべての注文書を更新日時順に表示</p>
              <p>受注済：受注確認前の注文書を受注日時順に表示</p>
              <p>受注確認済：受注確認済みの注文書を受注確認日時順に表示</p>
              <br/>
              <p>■発注書一覧（仕入側）</p>
              <p>すべて：すべての発注書を発注日時順に表示</p>
              <p>最新更新順：すべての発注書を更新日時順に表示</p>
              <p>上長承認待ち：上長承認待ちの発注書を承認依頼日時順に表示</p>
              <p>発注済：発注確定した発注書（上長承認済）を発注日時順に表示</p>
              <p>却下：上長却下された発注書を却下日時順に表示</p>
              <br/>
              <h5>２．その他機能改善項目</h5>
              <br/>
              <p>■販売モード</p>
              <p>・「受注確認」ボタンは1ユーザーのみがクリックでき、受注確認者が上書きされないようになりまし</p>
              <p>・受注未確認の注文書を既読にしてもバッジが減少せず、「受注確認」ボタンをクリックしたときにバッジが減少するようになりました。</p>
              <p>・注文書一覧の自確認ラベル表示をなくしました。受注確認者の上にラベルが表示されます。</p>
              <br/>
              <p>■仕入モード</p>
              <p>・発注書一覧の見出し項目の配列を変更しました。</p>
            </div>
          },
          {
            id: 5,
            title: 'update list',
            link: '/supportUpdate/5',
            shortData: `お助けカート機能追加`,
            shortDataDate: `2022年9月5日`,
            date: '2022年9月',
            year: 2022,
            month: 9,
            data: <div>
              <h3>お助けカート機能追加</h3>
              <br/><br/>
              <p>リリース日：2022年9月5日</p>
              <br/>
              <p>【更新内容】</p>
              <p>P4CLOUDの下記機能についてアップデートを実施しました。</p>
              <p>・お助けカート機能追加</p>
              <br/>
              <hr/>
              <p>お助けカートとは</p>
              <hr/>
              <p>販売側が仕入側のカート作成を補助する機能です。</p>
              <p>販売側が商品を選び「お助けカート」を作成し、仕入側に送信することができます。</p>
              <p>仕入側はお助けカートをそのままカートに投入することで、商品を探す手間を省くことができます。</p>
              <br/>
              <p>■販売側　‥‥‥‥‥‥‥‥‥‥‥‥‥‥‥‥‥‥‥‥‥‥‥‥‥‥‥‥‥‥‥</p>
              <br/>
              <p>【お助けカートを作成する】</p>
              <p>１．商品一覧の「お助けカートに入れる」をクリックします。お助けカート選択のポップアップが開きます。</p>
              <p>２．「選択」をクリックすると、商品がお助けカートに入ります。</p>
              <p>※商品一覧のチェックボックスをクリックすると、まとめてお助けカートに入れることができます。</p>
              <br/>
              <p>【お助けカートを送信する】</p>
              <p>１．サイドメニューから送信したいお助けカートを開き、各フォームに必要事項を入力します。</p>
              <p>２．「このお助けカートを送信する」をクリックします。仕入側にお助けカートが送信されます。</p>
              <p>※「お助けカート送信先」で選択されたユーザーにメールで通知が届きます。</p>
              <p>※ お助けカート名称は仕入側にも表示されます。</p>
              <br/>
              <p>【送信済みのお助けカート履歴から任意のお助けカートを再編集して新規お助けカートを作成する】</p>
              <p>１．サイドメニューの「お助けカート送信履歴」から送信したいお助けカートを開きます。</p>
              <p>２．「この履歴を使ってお助けカートを作成する」をクリックします。お助けカート選択のポップアップが開きます。</p>
              <p>３．「選択」をクリックすると、お助けカートが作成されます。各項目は編集して送信することができます。</p>
              <p>※すでにお助けカートに入っていた商品は削除されます。</p>
              <br/>
              <p>■仕入側　‥‥‥‥‥‥‥‥‥‥‥‥‥‥‥‥‥‥‥‥‥‥‥‥‥‥‥‥‥‥‥</p>
              <br/>
              <p>【お助けカートを確認する】</p>
              <p>１．お助けカートを受信するとメールで通知が届きます。</p>
              <p>２．サイドメニューの「お助けカート一覧」からお助けカートを開きます。</p>
              <br/>
              <p>【お助けカートをカートに追加する】</p>
              <p>１．お助けカートを開き、「カートに追加する」をクリックします。カート選択のポップアップが開きます。</p>
              <p>２．「選択」をクリックすると、お助けカートの内容がカートに追加されます。</p>
              <p>※すでにカートに入っていた商品は削除されます。</p>
            </div>
          },
          {
            id: 4,
            title: 'update list',
            link: '/supportUpdate/4',
            shortData: `通知メール受信設定機能を追加`,
            shortDataDate: `2022年5月26日`,
            date: '2022年5月',
            year: '2022年',
            month: '5月',
            data: <div>
              <h3>通知メール受信設定機能を追加</h3>
              <br/><br/>
              <p>リリース日：2022年5月26日</p>
              <br/>
              <p>【設定方法】</p>
              <ol>
                <li>
                  <p>画面右上のログインアカウント名をクリックします。</p>
                </li>
                <li>
                  <p>プロフィール情報の下にある「通知メール設定」をクリックします。</p>
                  <p>通知メール設定画面が表示されます。</p>
                </li>
                <li>
                  <p>各項目のON/OFFを切り替えます。</p>
                </li>
                <li>
                  <p>「設定する」をクリックすると設定が有効になります。</p>
                </li>
              </ol>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fupdate%2F%E9%80%9A%E7%9F%A5%E3%83%A1%E3%83%BC%E3%83%AB%E5%8F%97%E4%BF%A1%E8%A8%AD%E5%AE%9A-1024x670.png?alt=media&token=087fbdb4-bce9-4502-a534-e2b1796a17ee'}/>
              <br/>
              <p>【受信設定できるメールの種類】</p>
              <p>■購入ユーザー</p>
              <p>上長承認依頼時（仕入モード側受信）※上長権限者のみ</p>
              <p>上長却下時（仕入モード側受信）</p>
              <p>上長承認時（仕入モード側受信）</p>
              <p>出荷日送り状番号送信時（仕入モード側受信）</p>
              <br/>
              <p>■販社ユーザー</p>
              <p>上長承認依頼時（仕入モード側受信）※上長権限者のみ</p>
              <p>上長却下時（仕入モード側受信）</p>
              <p>上長承認時（仕入モード側受信）</p>
              <p>出荷日送り状番号送信時（仕入モード側受信）</p>
              <p>新規注文受取時（販売モード側受信）</p>
              <br/>
              <p>■メーカーユーザー</p>
              <p>新規注文受取時（販売モード側受信）</p>
            </div>
          },
          {
            id: 3,
            title: 'update list',
            link: '/supportUpdate/3',
            shortData: `通知メールとバッジ表示機能追加`,
            shortDataDate: `2022年5月9日`,
            date: '2022年5月',
            year: '2022年',
            month: '5月',
            data: <div>
              <h3>通知メールとバッジ表示機能追加</h3>
              <br/><br/>
              <p>リリース日：2022年5月9日</p>
              <br/>
              <p>＝＝＝＝＝＝＝＝＝＝ 目次 ＝＝＝＝＝＝＝＝＝＝</p>
              <br/>
              <p>【仕入モードでログイン時】</p>
              <ol>
                <li>
                  <p>上長承認依頼：通知メール 　</p>
                  <p>※上長承認依頼通知メール受信上長アカウント指定のお願い</p>
                </li>
                <li>
                  <p>上長却下：通知メール</p>
                </li>
                <li>
                  <p>上長承認（=発注）：通知メール（発注側カート作成アカウント宛て）＋通知メール（発注側から指定された販売側担当者宛て）</p>
                </li>
                <li>
                  <p>仕入先側受注確認：発注書一覧と詳細画面に「受注確認した仕入先のアカウント名」を表示</p>
                </li>
                <li>
                  <p>出荷通知：通知メール（発注側カート作成アカウント宛て）と発注側全アカウントへのバッジ表示</p>
                </li>
              </ol>
              <br/>
              <p>【販売モードでログイン時】</p>
              <br/>
              <ol>
                <li>
                  <p>受注：通知メール（発注側から指定された担当者宛て）と受注側全アカウントへのバッジ表示</p>
                </li>
                <li>
                  <p>受注確認：「受注確認したアカウント名」の表示とバッジ消去</p>
                </li>
                <li>
                  <p>出荷：通知メール（発注側カート作成アカウント宛て）と発注側全アカウントへのバッジ表示</p>
                </li>
              </ol>
              <br/>
              <p>＝＝＝＝＝＝＝＝＝＝ 機能詳細 ＝＝＝＝＝＝＝＝＝＝</p>
              <br/>
              <p>【仕入モードでログイン時】</p>
              <br/>
              <p>1.上長承認依頼</p>
              <p>商品が投入されたカート画面にある「上長承認依頼」ボタンを押下すると、予め指定された上長アカウントのメールアドレスに「承認依頼を通知するメール」が送られます。</p>
              <img style={{width: '100%', maxWidth: 644, border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fupdate%2F%E3%82%AB%E3%83%BC%E3%83%88_2-edited.png?alt=media&token=8d2d73cf-d190-440c-b85e-8ffafcd81eea'}/>
              <br/><br/>
              <div style={{border: '1px solid lightgrey', background: '#FFFF66', borderRadius: 3, padding: '1em'}}>
                <p>※上長承認依頼の通知メールを受信する上長アカウントを予め設定いたしますので、通知メール受信を許可する</p>
                <p>1)上長氏名</p>
                <p>2)登録されているメールアドレス</p>
                <p>をお送りください。</p>
                <p>通知メール受信を許可する上長は複数名設定可能です。</p>
              </div>
              <br/>
              <p>2.上長却下</p>
              <p>発注書一覧画面または発注書詳細画面にて「却下」ボタンを押下すると、カート作成アカウント（上長承認依頼したアカウント）宛に「却下を通知するメール」が送られます。</p>
              <br/>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fupdate%2F%E8%B3%BC%E5%85%A5U_%E7%99%BA%E6%B3%A8%E6%9B%B8%E4%B8%80%E8%A6%A72_01-1-1024x246.png?alt=media&token=4607f510-5f89-4336-9a9a-12c230ff1ddf'}/>
              <br/>
              <p>3. 上長承認（=発注）</p>
              <p>発注書一覧画面または発注書詳細画面にて「承認」ボタンを押下すると、カート作成アカウント（上長承認依頼したアカウント）宛に「承認（=発注）を通知するメール」が送られます。</p>
              <br/>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fupdate%2F%E8%B3%BC%E5%85%A5U_%E7%99%BA%E6%B3%A8%E6%9B%B8%E4%B8%80%E8%A6%A7_%E6%89%BF%E8%AA%8D_01-1024x246.png?alt=media&token=820cab0f-66cb-468a-bcb9-c903c6fedae3'}/>
              <br/>
              <p>4.仕入先側受注確認</p>
              <p>仕入先側で「受注確認」ボタンが押下されると、発注書一覧に「受注確認した仕入先のアカウント名」が表示されます。</p>
              <br/>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fupdate%2F%E7%99%BA%E6%B3%A8%E6%9B%B8%E7%A2%BA%E8%AA%8D%E8%80%85%E3%81%AE%E3%82%A2%E3%82%AB%E3%82%A6%E3%83%B3%E3%83%88%E5%90%8D_03-1024x302.png?alt=media&token=111f3ff6-9b1f-4d1c-b9da-d86a07606228'}/>
              <br/>
              <p>5.出荷通知</p>
              <p>仕入先から出荷日および送り状番号が登録送信されると、カート作成アカウント宛に「出荷を通知するメール」が送られます。 同時に発注書一覧メニューに件数を通知するバッジが表示されます。バッジは事業所内登録アカウントすべてに表示されます。 発注書一覧から表示ボタンを押下すると、対象のバッジ（件数）はすべてのアカウント画面から消えます。</p>
              <br/>
              <img style={{width: '100%', maxWidth: 284, border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fupdate%2F%E8%B3%BC%E5%85%A5U_%E3%82%B5%E3%82%A4%E3%83%88%E3%82%99%E3%83%A1%E3%83%8B%E3%83%A5%E3%83%BC_01.png?alt=media&token=d0cf351a-8abb-4821-91e4-eb41a260ddca'}/>
              <br/>
              <p>．．．．．．．．．</p>
              <br/>
              <p>【販売モードでログイン時】</p>
              <br/>
              <p>1.受注</p>
              <p>仕入側（購入側）事業所からの発注（上長承認）が確定すると、カート内で指定された販売側アカウント宛てに「発注を通知するメール」が送られます。</p>
              <br/>
              <p>同時にP4CLOUD画面内の</p>
              <p>1)相手先選択プルダウンボックス</p>
              <p>2)サイドメニュー>注文書一覧</p>
              <p>に件数を通知するバッジが表示されます。バッジは事業所内登録アカウントすべてに表示されます。</p>
              <img style={{width: '100%', maxWidth: 284, border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fupdate%2F%E8%B2%A9%E7%A4%BEU_%E3%82%B5%E3%82%A4%E3%83%88%E3%82%99%E3%83%A1%E3%83%8B%E3%83%A5%E3%83%BC_01-1.png?alt=media&token=c905cb6e-7c5a-4ec9-adb9-7d48e3120147'}/>
              <br/>
              <p>2.受注確認</p>
              <p>注文書詳細画面にて「受注確認」ボタンを押下すると、注文書一覧に「受注確認したアカウント名」が表示されます。</p>
              <p>同時に、対象のバッジ（件数）はすべてのアカウントから消えます。</p>
              <br/>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fupdate%2F%E3%83%A1%E3%83%BC%E3%82%AB%E3%83%BCU_%E6%B3%A8%E6%96%87%E6%9B%B8%E4%B8%80%E8%A6%A7_03-1024x328.png?alt=media&token=0c1a94e5-44b7-46a1-9349-e9bcee2da564'}/>
              <br/>
              <p>3.出荷</p>
              <p>注文書詳細画面にて出荷日および送り状番号を入力の上「送信」ボタンを押下すると、発注側事業所のカート作成アカウント宛てに「出荷を通知するメール」が送られます。同時に相手先事業所のすべてのアカウントにバッジが表示されます。</p>
            </div>
          },
          {
            id: 2,
            title: 'update list',
            link: '/supportUpdate/2',
            shortData: `P4CLOUDの一部機能を改善`,
            shortDataDate: `2021年12月25日`,
            date: '2021年12月',
            year: '2021年',
            month: "12月",
            data: <div>
              <h3>P4CLOUDの一部機能を改善</h3>
              <br/><br/>
              <p>リリース日：2021年12月25日</p>
              <br/>
              <p>【更新内容】</p>
              <p>P4CLOUDの一部機能についてアップデートを実施しました。</p>
              <br/>
              <p>１．カート内編集時、希望納期日付指定カレンダーを選択したにもかかわらず日付を選択しなかった場合に、画面右上にアラートを表示します。</p>
              <p>　↳日付入力ミスをなくすことができます。</p>
              <img style={{width: '100%', border: '1px solid lightgrey'}} src={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Fupdate%2F%E6%97%A5%E4%BB%98%E6%9C%AA%E6%8C%87%E5%AE%9A%E3%82%A2%E3%83%A9%E3%83%BC%E3%83%88-1024x624.png?alt=media&token=54b1f976-a5a6-4eef-a982-4533e356924e'}/>
              <br/>
              <p>２．軽微なバグの修正を行いました。</p>
            </div>
          },
          {
            id: 1,
            title: 'update list',
            link: '/supportUpdate/1',
            shortData: `P4CLOUDの一部機能を改善`,
            shortDataDate: `2021年8月14日`,
            date: '2021年8月',
            year: '2021年',
            month: '8月',
            data: <div>
              <h3>P4CLOUDの一部機能を改善</h3>
              <br/><br/>
              <p>リリース日：2021年8月14日</p>
              <br/>
              <p>【更新内容】</p>
              <p>P4CLOUDの一部機能についてアップデートを実施しました。</p>
              <br/>
              <ol>
                  <li>
                      <p>商品購入の商品一覧に再発注ボタンを設置しました。</p>
                      <p>商品詳細を確認することなく購入する場合、直接カートに入れることができます。</p>
                  </li>
                  <li>
                      <p>カートの希望納期に日付指定（カレンダー選択）を追加しました。</p>
                      <p>希望納期をカレンダー選択にて日付指定することにより、伝達ミスをなくすことができます。</p>
                  </li>
                  <li>
                      <p>軽微なバグの修正を行いました。</p>
                  </li>
              </ol>
            </div>
          }
        ],
        search: [
          {
            id: 1,
            title: '発注をする',
            text: `サイドメニューから 商品購入 → 商品一覧 を開きます。
            
            発注する商品の 再発注 ボタンをクリックします。
            ※全文検索、商品名検索、メーカー名検索のいずれかで商品を検索することができます。
            
            注意
            購入数量にかかわらず、一度だけ 再発注 ボタンをクリックします。
            数量の変更はこの後の手順で行います。
            
            カート選択のポップアップ画面が表示されます。商品を入れるカートを選択します。
            お助けカートからカートに商品を投入することもできます。
            お助けカートを使ってカートを作成する
            
            商品を全てカートへ投入したら、サイドメニューから カート を開きます
            
            選んだ商品の内容や価格が表示されます。
            数量の変更は数量欄に希望の数を半角数字で直接入力するか、＋/－ボタンをクリックします。
            
            相手先営業担当者は必ず選択してください。相手先業務担当者は任意で選択します。
            各項目を入力し、最後に 上長承認依頼 をクリックします。
            上長に「発注内容上長承認依頼」を通知するメールが届きます。（受信設定をONにしている場合）
            上長が承認すると、相手先営業担当者と相手先業務担当者に通知メールが届きます。（受信設定をONにしている場合）
            
            POINT
            選択した相手先営業担当者と相手先業務担当者は、次回以降も既選択状態でプルダウンメニューに表示されます。
            変更することも可能です。
            
            カートに数量0の商品が含まれている場合、上長承認依頼ボタンをクリックすると以下のポップアップガイダンスが表示されます。
            削除して上長承認依頼する をクリックすると数量0の商品は削除されます
            削除された商品は発注書には表示されません
            
            注意
            上長が承認するまではカートは空になりません。`,
            link: '/support/order/1'
          },
          {
            id: 2,
            title: '発注書一覧の見方',
            text: `サイドメニューから 発注書一覧 を開きます。発注書一覧が表示されます。
            ① 既読にするチェックボックスにチェックを入れ「既読にする」をクリックすると、太字が解除されます。同時にバッジ(⑦)が減少します。
            ② 発注書受取者 / 受注確認者
            ラベルの上に購入時のカートで指定した発注書受取者が表示されます
            ラベルの下に受注を確認したユーザーの名前が表示されます。
            受注が確認されると、ラベルが 未確認 から 確認済 に変わります。
            ③ 状態
            上長の承認/却下の状況に応じて以下のラベルが表示されます。
             発注済 、 却下 ラベルの上には承認/却下をした上長の名前が表示されます。
            ・承認待
            ・発注済
            ・却下

            ④ 上長確認
            上長が発注書を開くと、ラベルが 未確認 から 確認済 に変わります。

            ⑤ 操作
            上長権限のユーザーがログインすると、承認待ちの発注に 承認 、 却下 ボタンが表示されます。

            ⑥ 出荷ステータス
            ランプの色で出荷完了ステータスを表します。
            [発注書一覧]
            ●緑のランプ：すべての商品が出荷済みの状態
            ●赤のランプ：未出荷の商品が含まれている状態
            [発注書詳細]
            ●緑のランプ：商品が出荷済みの状態
            ●赤のランプ：商品が未出荷の状態

            ※販売側が”出荷日/送り状番号”を送信すると、ランプが赤から緑に変わります。

            ⑦ 未読
            販売側が以下のアクションをしたとき、バッジが点灯します。同時に対象の注文が太字になります。
            ・受注の確認をしたとき
            ・出荷日/送り状番号を送信したとき

            ⑧ 未完
            未出荷の商品が含まれている発注書の数が表示されます。`,
            link: '/support/order/2'
          },
          {
            id: 3,
            title: '発注を承認/却下する（上長権限のみ）',
            text: `上長権限者は以下の画面から承認/却下をすることができます。

            発注書一覧
            発注書詳細
            カート画面（※）
            
            POINT
            上長権限者はカート画面の上長承認依頼ボタンをクリックすると、承認/却下ボタンが表示されます。
            発注書一覧・発注書詳細に遷移せずに、カート画面で承認/却下を行うことができます。

            サイドメニューから 発注書一覧 を開きます。

            表示 をクリックして発注書を開きます。

            ■承認するとき

            承認（発注確定）確認 をクリックします。

            ポップアップ画面が出たら、 承認(発注確定)する をクリックします。

            発注者に「承認」のメール通知が届きます。（受信設定をONにしている場合）
            相手先（販売側）に注文情報が送信されます
            ステータスラベルが 承認待 から 発注済 に変わります。

            ■ 却下するとき

            却下（担当者に差戻）確認 をクリックします。

            ポップアップ画面が出たら、 却下（担当者に差戻）する をクリックします。

            発注者に「却下」のメール通知が届きます。（受信設定をONにしている場合）
            ステータスラベルが 承認待 から 却下 に変わります。

            却下された発注はカートにそのまま残り、 却下（差戻） ラベルが表示されます。

            POINT
            発注書一覧画面から承認/却下することもできます`,
            link: '/support/order/3'
          },
          {
            id: 4,
            title: '却下された発注を修正する',
            text: `サイドメニューから却下された発注商品が入っているカートを開きます。
            各項目を編集します。商品一覧からカートに商品を追加することもできます。
            カートの編集が終わったら 上長承認依頼 をクリックします。
            上長に「発注内容上長承認依頼」のメール通知が届きます。（受信設定をONにしている場合）
            ボタンが 上長承認依頼 から 承認依頼中 に変わります。
            上長が承認すると発注が確定し、注文情報が相手先に送信されます。`,
            link: '/support/order/4'
          },
          {
            id: 5,
            title: '受注を確認する',
            text: `通知メール受信設定をONにしている場合、新規注文が入ると購入側が指定した担当者宛てに通知メールが届きます。
            同時に以下の箇所にバッジが点灯します。
            サイドメニューから 注文書一覧 を選択します。注文書一覧の 表示 をクリックします。注文書が表示されます。 受注確認 をクリックします。
            受注確認 をクリックすると、注文書一覧の太字が解除されます。リロードをするとバッジが減少します。（①）
            受注未確認 ラベルが 受注確認済 に変わります。（②）
            ラベルの下には 受注確認 を押下したユーザーの名前が表示されます。
            既読にすると太字が解除されますが、受注確認を押下するまではバッジは減少しません。`,
            link: '/support/orderReceived/1'
          },
          {
            id: 6,
            title: '納期/出荷日/送り状番号を回答する',
            text: `注文書一覧の 表示 をクリックして注文書を開きます。
            納期回答の入力フォームをクリックし、カレンダーから日付を選択して納期を入力します。
            登録 をクリックすると相手先（購入側）の発注書に納期が登録されます。
            ※納期回答は何度でも変更することができます。
            ■ 出荷日が決まったら
            ① 出荷日の入力フォームをクリックし、カレンダーから日付を選択して出荷日を入力します。
            ② 送り状番号の入力フォームをクリックし、送り状番号を入力します。

             送信 をクリックすると相手先（購入側）の発注書に出荷日と送り状番号が登録されます。
            同時に相手先（購入側）にメールで通知されます。（※受信設定でONにしている場合）
            ※出荷日／送り状番号は何度でも変更することができます。
            　ただし、送信をクリックするとその都度、相手先（購入側）にメールで通知されます。
            出荷日を相手先（購入側）に送信すると、出荷処理ステータスの表示が赤から緑のランプに変わります。`,
            link: '/support/orderReceived/2'
          },
          {
            id: 19,
            title: 'お助けカートの作成/送信',
            text: ` お助けカートとは
            販売側が仕入側のカート作成を補助する機能です。
            販売側が商品を選び「お助けカート」を作成し、仕入側に送信することができます。
            仕入側はお助けカートをそのままカートに投入することで、商品を探す手間を省くことができます。
            
            サイドメニューから 商品購入 → 商品一覧 を開きます。
            各商品行の お助けカートに入れる をクリックします。
            ※商品一覧のチェックボックスをクリックすると、まとめてお助けカートに入れることができます。
            
            
            お助けカート選択のポップアップ画面が表示されます。
             選択 ボタンをクリックします。
            
            
            商品をお助けカートへ投入したら、サイドメニューからお助けカートを開きます。
            
            
            お助けカートの内容が表示されます。
            ① カート名称 ：お助けカートの名称を設定することができます。名称は送信先（仕入側）にも表示されます。
            ② 商品を追加する ：お助けカート内から商品を追加することができます。
            ③ お助けカート送信先 ：お助けカート送信先を選択します。
            ④ このお助けカートを送信する ：お助けカート送信先で指定した相手先にお助けカートが届きます。同時にメールで通知が送信されます。
            
            
             仕入側にお助けカートが届くと
            お助けカートを送信すると、送信先の事業所内アカウントすべてがお助けカートを開き確認することができます。
            仕入側はお助けカートを編集することはできませんが、お助けカートをカートに投入することによってカート内容を編集することができます。`,
            link: '/support/orderReceived/3'
          },
          // {
          //   id: 7,
          //   title: '見積書/請求書/報告書を登録する',
          //   text: `※販社ユーザー、メーカーユーザー向け
          //   ここでは見積書を例に説明していますが、請求書/報告書も同様の操作になります。
          //   見積書を発行する相手先企業を選択します。※取引先が複数ある場合のみ
          //   サイドメニューから 見積書登録 を選択します。
          //   ① 各項目を入力します。※未入力項目があると登録できません。
          //
          //   ② PDF形式の見積書をアップロードします。
          //   【アップロード方法】
          //   方法１．円の中にPDFファイルをドラッグ＆ドロップします。
          //   方法２．円をクリックすると別ウィンドウが開きます。 ファイルを選択して[開く]をクリックすると アップロードされます
          //   見積書がアップロードされるとファイル名が表示されます。
          //    新規見積書登録 をクリックすると見積書が登録されます。
          //    サイドメニューから 見積書一覧 を開くと、登録した見積書が確認できます。
          //    一覧に表示されるのは、相手先選択モードで選択している事業所の見積書/請求書/報告書のみです。
          //    他の事業所の見積書/請求書/報告書を確認するときは、相手先選択モードで切り替えてページを再読込みします。`,
          //   link: '/support/quotation/1'
          // },
          {
            id: 8,
            title: '工事見積書を登録する',
            text: `※販社ユーザー、メーカーユーザー向け
            工事見積書を発行する相手先企業を選択します。※取引先が複数ある場合のみ
            サイドメニューから 工事見積書登録 を選択します。
            各項目を入力します。※未入力項目があると登録できません。
            PDF形式の工事見積書をアップロードします。
            【アップロード方法】
            方法１．円の中にPDFファイルをドラッグ＆ドロップします。
            方法２．円をクリックすると別ウィンドウが開きます。 ファイルを選択して[開く]をクリックすると アップロードされます。

            工事見積書がアップロードされるとファイル名が表示されます。
            新規工事見積書登録 をクリックすると工事見積書が登録されます。
            同時に、相手先担当者選択欄で指定したアカウントに通知メールが届きます。（相手先担当者が通知メール設定をオンにしている場合）
            一覧に表示されるのは、相手先選択モードで選択している事業所の工事見積書のみです。
            他の事業所の工事見積書を確認するときは、相手先選択プルダウンリストから選択します。`,
            link: '/support/quotation/2'
          },
          {
            id: 9,
            title: '工事見積書一覧の見方',
            text: `サイドメニューから 工事見積書一覧 を開きます。
            工事見積書一覧が表示されます。
            ① 見積番号/見積書ID
            工事見積書登録時に指定した見積番号と、P4CLOUDで管理されるドキュメントIDが表示されます。

            ② 送信者/送信日時/見積受取者
            上段、中段に工事見積書を送信したユーザーと送信日時が表示されます。
            下段に工事見積書登録時に受取者として指定したユーザーが表示されます。

            ③ 見積確認者/確認日時
            仕入側で最初に工事見積書を確認したユーザーと確認日時が表示されます。

            ④ 発行日/有効期限/納品日
            販売側が工事見積書登録時に指定した日付が表示されます。

            ⑤ ステータス確定日時
            仕入側での承認・却下ステータスと承認・却下したユーザー名、日時が表示されます。
            承認・却下前の工事見積書は 未受注 が表示されます。
            承認されると 受注済 が表示され、工事見積書を送信したユーザーには注文を知らせるメールが届きます。（通知設定をオンにしている場合）

            ⑥ 受注確認者/確認日時
            仕入側が承認した工事見積書は 受注確認 ボタンが表示されます。
            販売側で 受注確認 ボタンをクリックすると 受注確認済 に表示が変わり、クリックしたユーザー名と日時が表示されます。

            ⑦ 削除
            ゴミ箱アイコンをクリックすると、ステータス確定日時欄に 削除済 ラベルが表示されます。
            削除をしても販売側の工事見積書一覧には表示されますが、仕入側の工事見積書一覧には表示されません。
            販売側が工事見積書を新規登録した時、販売側・仕入側いずれも以下の箇所にバッジが表示されます。
            ・ログインアカウント名の横
            ・相手先選択プルダウンボックス
            ・サイドメニュー>工事見積書

            同時に対象の工事見積書は太字で表示されます。
            ※バッジおよび太字は事業所内登録アカウントすべてに表示されます。

            事業所内のいずれかのアカウントが工事見積書一覧の 表示 をクリックすると、事業所内全アカウントの画面からバッジが減少し太字が解除されます。`,
            link: '/support/quotation/3'
          },
          // {
          //   id: 10,
          //   title: 'SDS/取扱説明書/カタログを登録する※販売モードの機能',
          //   text: `ここではSDSを例に説明していますが、取扱説明書、カタログも同様の操作になります。
          //   サイドメニューから SDS登録 を選択します。
          //   各項目を入力します。※未入力項目があると登録できません。
          //   PDF形式のSDSをアップロードします。
          //   【アップロード方法】
          //   方法１．円の中にPDFファイルをドラッグ＆ドロップします。
          //   方法２．円をクリックすると別ウィンドウが開きます。 ファイルを選択して[開く]をクリックすると アップロードされます。
          //   SDSがアップロードされるとファイル名が表示されます。
          //   新規SDS登録 をクリックするとSDSが登録されます。
          //   ※この操作ではSDSは登録商品には紐づきません。
          //   サイドメニューから SDS一覧 を開くと、登録したSDSが確認できます。
          //   SDS一覧、取扱説明書一覧、カタログ一覧には相手先選択モードで選択していない事業所を含め、登録しているすべてが表示されます。`,
          //   link: '/support/sds-manual-catalog/1'
          // },
          {
            id: 11,
            title: '商品に紐づいているSDS/取扱説明書/カタログを確認する',
            text: `サイドメニューから 商品購入 → 商品一覧 を開きます。
            (a)各商品行の SDS / 取扱説明書 / カタログ ボタンをクリックすると、商品に登録されたSDS/取扱説明書/カタログが表示されます。
            ※SDS/取扱説明書/カタログの表示ボタンは、登録がない場合は表示されません。
            (b)SDSが登録されている商品は、商品一覧画面の SDS登録商品 にチェックを入れ 表示 をクリックすることで絞り込んで表示させることができます。`,
            link: '/support/sds-manual-catalog/2'
          },
          {
            id: 12,
            title: '通知メール受信設定',
            text: `画面右上のログインアカウント名をクリックします。
            プロフィール情報の下にある 通知メール受信設定 をクリックします。
            通知メール受信設定画面が表示されます。各項目のON/OFFを切り替えます。
            設定する をクリックすると設定が有効になります。
            ※ユーザーの属性によって設定できる項目は異なります。画像は販社ユーザーの例です。
            【受信設定できるメールの種類】

            ■購入ユーザー
            上長承認依頼時（仕入モード側受信）※上長権限者のみ
            上長却下時（仕入モード側受信）
            上長承認時（仕入モード側受信）
            出荷日送り状番号送信時（仕入モード側受信）

            ■販社ユーザー
            上長承認依頼時（仕入モード側受信）※上長権限者のみ
            上長却下時（仕入モード側受信）
            上長承認時（仕入モード側受信）
            出荷日送り状番号送信時（仕入モード側受信）
            新規注文受取時（販売モード側受信）

            ■メーカーユーザー
            新規注文受取時（販売モード側受信）`,
            link: '/support/setting/1'
          },
          {
            id: 18,
            title: 'お助けカートを使ってカートを作成する',
            text: `お助けカートとは
            販売側が仕入側のカート作成を補助する機能です。
            販売側が商品を選び「お助けカート」を作成し、仕入側に送信することができます。
            仕入側はお助けカートをそのままカートに投入することで、商品を探す手間を省くことができます。
            販売側からお助けカートが届くと、メールで通知が届き、ヘッダーとサイドメニューにバッジが点灯します。
            サイドメニューの お助けカート → お助けカート一覧 を開きます。
            新規で届いたお助けカートは太字で表示されます。 表示 をクリックして、お助けカートを開きます。
            お助けカートが表示されます。内容を確認し、 カートに追加する をクリックします。
            数量等の変更はカートに投入した後に行うことができます。
             POINT
             この納品場所をアドレス帳に追加する をクリックすると、販売側が設定した納品場所をアドレス帳に登録することができます。
            カート選択のポップアップが開きます。 選択 をクリックすると、カートにお助けカートの内容が追加されます。
            ※選択したカートにすでに商品が入っている場合、すべて破棄しないとお助けカートの内容を追加することができません。`,
            link: '/support/otasuke-cart/1'
          },
          {
            id: 13,
            title: 'お助けカートの作成/送信',
            text: `販売側が仕入側のカート作成を補助する機能です。
            販売側が商品を選び「お助けカート」を作成し、仕入側に送信することができます。
            仕入側はお助けカートをそのままカートに投入することで、商品を探す手間を省くことができます。
            サイドメニューから 商品購入 → 商品一覧 を開きます。
            各商品行の お助けカートに入れる をクリックします。
            ※商品一覧のチェックボックスをクリックすると、まとめてお助けカートに入れることができます。
            お助けカート選択のポップアップ画面が表示されます。
            選択 ボタンをクリックします。
            商品をお助けカートへ投入したら、サイドメニューからお助けカートを開きます。
            お助けカートの内容が表示されます。
            ① カート名称 ：お助けカートの名称を設定することができます。名称は送信先（仕入側）にも表示されます。
            ② 商品を追加する ：お助けカート内から商品を追加することができます。
            ③ お助けカート送信先 ：お助けカート送信先を選択します。
            ④ このお助けカートを送信する ：お助けカート送信先で指定した相手先にお助けカートが届きます。同時にメールで通知が送信されます。
            お助けカートを送信すると、送信先の事業所内アカウントすべてがお助けカートを開き確認することができます。
            仕入側はお助けカートを編集することはできませんが、お助けカートをカートに投入することによってカート内容を編集することができます。`,
            link: '/support/otasuke-cart/2'
          },
          {
            id: 14,
            title: '履歴を使ってお助けカートを作成する',
            text: `サイドメニューから お助けカート → お助けカート送信履歴 を開きます。
            お助けカート送信履歴の一覧から編集・再送信したいお助けカートの 表示 をクリックします。
            この履歴を使ってお助けカートを作成する ボタンをクリックします。
            お助けカート選択のポップアップが開きます。
            選択 ボタンをクリックします。
            お助けカートが新規作成されます。※すでにお助けカートに入っていた商品は削除されます。
            商品の追加や各項目を編集することができます。
            このお助けカートを送信する をクリックすると、お助けカート送信先で指定した相手先にお助けカートが届きます。
            同時にメールで通知が送信されます。`,
            link: '/support/otasuke-cart/3'
          },
          {
            id: 15,
            title: 'お気に入りセット登録',
            text: `よく利用する商品をあらかじめお気に入りセットとして登録することができます。
            お気に入りセットはカートもしくはお助けカートに追加することができます。
            サイドメニューから 商品購入 → 商品一覧 を開きます。
            各商品行の お気に入りセット登録 をクリックします。
            お気に入りセット選択のポップアップ画面が表示されます。
            選択 ボタンをクリックします。
            お気に入りセットに商品が追加されます。
            サイドメニューから お気に入りセット一覧 を開きます。
            お気に入りセット一覧の中から 表示 をクリックします。
            お気に入りセットの内容が表示されます。
            ① セット名称 ：お気に入りセットの名称を設定することができます。
            ② 商品を追加する ：お気に入りセットに商品を追加することができます。
            ③ このセットをカートに追加 （ このセットをお助けカートに追加 ）：お気に入りセットをカート（お助けカート）に追加することができます。
            お気に入りセットは30セットまで登録することができます。`,
            link: '/support/favorite-set/1'
          },
          {
            id: 16,
            title: '納品場所の追加と管理',
            text: `よく利用する納品場所をアドレス帳に登録することができます。
            サイドメニューから 納品場所管理(アドレス帳) を選択します。
            新しい納品場所を登録する をクリックします。
            各項目を入力し、 登録 をクリックします。
            アドレス帳に納品場所が追加されます。
            ※全ての項目を入力しないと登録できません。
            ① 詳細を見る：納品場所の詳細を確認します。
            ② 編集：納品場所を編集します。
            ③ コピー：すでに登録してある納品場所を複製することができます。
            ④ 削除：アドレス帳から納品場所が削除されます。`,
            link: '/support/delivery-address-list/1'
          },
          {
            id: 17,
            title: 'SDSを管理する',
            text: `POINT
            仕入側はSDSが登録されている商品のSDS定期チェックを申し込むことができます。
            定期チェックは毎年自動で更新されます。
            ■SDS管理を申し込む（仕入側の機能）
            サイドメニューから 商品一覧 を開き、 SDS管理申込 ボタンをクリックします。
            ご希望のチェック月にチェックを入れ、 申込確認 ボタンをクリックします。
            申込 ボタンをクリックすると申込が完了します。
            ※この時点では受付は完了していません。販売側が受付処理を行うと受付が完了します。
            ■SDS管理を受け付ける（販売側の機能）
            サイドメニューから SDS管理一覧 を開きます。
            受付処理 をクリックすると受付が完了します。
            ■申込内容とチェック実施履歴、管理状況の確認
            申込内容およびチェック実施履歴は、サイドメニューの SDS管理一覧 から確認することができます。
            販売側が受付処理をすると、管理状況を示す通知バッジおよびランプが点灯します。
            [ランプ表示]
            表示箇所：ヘッダー、SDS管理一覧画面
            赤色…希望日を過ぎてチェック更新されていない状態
            青色…希望日を過ぎてチェック更新されている状態
            黄色…チェック更新日が1週間以内
            表示箇所：ヘッダー、サイドメニュー
            緑色…SDS管理申込済みだが受付未処理の状態`,
            link: '/support/sds-manual-catalog/3'
          },
          {
            id:20,
            title: '購入データを確認する',
            text: `サイドメニューから データ分析 → 購入データ を開きます。
            現年度の取引量の推移がグラフと表で表示されます。
            データに含まれるのはステータスが出荷済みの商品です。
            
            データの見方
            ①比較年度選択
            選択した年度と、現年度の取引量を比較することができます。
            
            ②商品選択
            商品ごとの取引量を確認することができます。
            デフォルトでは全商品の取引量が表示されています。
            ※出荷履歴のない商品はプルダウンに表示されません。
            
            ③担当者選択
            担当者（仕入側発注者）ごとの取引量を確認することができます。
            デフォルトではヘッダーに表示されている相手先事業所に登録されている担当者（仕入側発注者）全員の取引量が表示されています。
            
            ④購入データダウンロード
            データの一覧をエクセル形式でダウンロードすることができます。
            ファイルでは出荷日最新順にソートされた出荷済みの発注データを一覧で確認することができます。`,
            link: '/support/analytics/1'
          },
          {
            id: 21,
            title: '分納された注文を確認する',
            text: `分納について
            販売側は注文を商品ごとに複数に分けて発送処理を行うことができます。
            販売側が分納手続きを開始すると、発注者（カート作成者）にメール通知が届きます。
            分納手続きがすべて確定すると、発注者（カート作成者）にメール通知が届きます。
            
            発注書一覧の見方
            
            ①販売側が分納を開始すると、発注書内のすべての分納が確定するまで  が表示されます。
            例：　発注書内に分納行が3行あり、内2行が分納確定している状態
            ②すべての商品行の分納が確定すると、が表示されます。
            
            発注書の見方
            
            ①販売側が分納が確定するまで、分納手続き中の商品行にが表示されます。
            ②販売側が分納が確定した行はが表示されます。
            ※が表示されていても出荷前のときは、販売側はさらに分納手続きをすることができます。
            ③分納手続き中に発注書を表示・印刷をすると、  が表示されます。
            分納確定後に発注書を表示・印刷をすると、  が表示されます。`,
            link: '/support/order/6'
          },
          {
            id: 22,
            title: '分納をする',
            text: `分納について
            販売側は注文を商品ごとに複数に分けて発送処理を行うことができます。
            分納ができるのは出荷前（出荷日/送り状番号入力前）の商品行のみです。
            分納処理ができるのは上長権限者もしくは発注書受取者に自身に指定されている場合のみです。
            分納手続きを開始する
            
            サイドメニューの注文書一覧から注文書を開きます。
            
            商品行の右端にあるメニューアイコン【】をクリックし、 分納 を選択します。
            分納手続き開始のポップアップが表示され、発注者発注者（カート作成者）にメール通知されます。
            ※分納を取りやめるときは、元の商品行のメニュー【】から 分納をやめる を選択します。
            分納手続きを開始すると商品行が分割され、販売側、購入側の分納手続き中の商品行にが表示されます。（a）
            
            数量を入力する
            
            分割された商品行に数量を入力します。
            ※入力した数量が合計数量と異なるとき、修正するまで分納確定することができません。
            行を増やすときは、メニューアイコン【】から 分納行を増やす を選択します。
            間違って余分に分割した場合は、メニューアイコン【】から この分納行を削除 を選択して商品行を削除します。
            
            POINT
            ・出荷日/送り状番号の入力欄およびコメント欄は、分納手続きが確定するまで入力することができません。
            ・分納手続き中に注文書を表示・印刷をすると、 が表示されます。
            
            分納を確定する
            
            数量の入力が終わったら、メニューアイコン【】を開き 分納確定 を選択します。
            分納確定は分割された商品行ごとに行います。
            分納確定した商品行にはが表示されます。（b）
            分割された行の分納手続きがすべて確定すると、発注者（カート作成者）に分納手続確定のメール通知が送信されます。
            
            POINT
            分納確定後に注文書を表示・印刷をすると、  が表示されます。
            
            注文書一覧の見方
            
            ①分納を開始すると、注文書内のすべての分納が確定するまで  が表示されます。
            例：　注文書内に分納行が3行あり、内2行が分納確定している状態
            ②すべての商品行の分納が確定すると、が表示されます。`,
            link: '/support/orderReceived/4'
          },
          // {
          //   id: 23,
          //   title: '請求書を登録する',
          //   text: `請求書を発行する相手先企業を選択します。
          //
          //   サイドメニューから 請求書登録 を選択します。
          //
          //   請求書登録画面の各項目に入力します。 相手先担当者 および 相手先CC で選択したユーザーには以下のメール通知が届きます。
          //   ・請求書登録通知
          //   ・請求書削除通知
          //
          //   PDF形式の請求書をアップロードします。
          //   【アップロード方法】
          //   方法１．円の中にPDF請求書をドラッグ＆ドロップします。
          //   方法２．円をクリックすると別ウィンドウが開きます。ファイルを選択して[開く]をクリックするとアップロードされます。
          //
          //   請求書がアップロードされるとファイル名が表示されます。
          //
          //   新規請求書登録 をクリックすると請求書が登録されます。
          //
          //   登録した請求書は、サイドメニューの請求書一覧から確認することができます。
          //
          //   POINT
          //   請求書一覧に表示されるのは、相手先選択モードで選択している事業所の請求書のみです。
          //   他の事業所への請求書を確認するときは、相手先選択モードで切り替えてページを再読込みします。`,
          //   link: '/support/quotation/4'
          // },
          {
            id: 24,
            title: '見積書/報告書を登録する',
            text: `※販売側向けの機能

            POINT
            ここでは請求書を例に説明していますが、納品書/見積書/報告書も同様の操作になります。
            
            請求書を発行する相手先企業を選択します。
            
            サイドメニューから 請求書登録 を選択します。
            
            登録画面の各項目に入力します。相手先担当者および相手先CCで選択したユーザーには以下のメール通知が届きます。
            ・請求書登録通知
            ・請求書削除通知
            
            PDF形式の請求書をアップロードします。
            【アップロード方法】
            方法１．円の中にPDF請求書をドラッグ＆ドロップします。
            方法２．円をクリックすると別ウィンドウが開きます。ファイルを選択して[開く]をクリックするとアップロードされます。
            
            請求書がアップロードされるとファイル名が表示されます。
            
            新規請求書登録 をクリックすると請求書が登録されます。
            
            登録した請求書は、サイドメニューの請求書一覧から確認することができます。
            
            POINT
            請求書一覧に表示されるのは、相手先選択モードで選択している事業所の請求書のみです。
            他の事業所への請求書を確認するときは、相手先選択モードで切り替えてページを再読込みします。`,
            link: '/support/quotation/5'
          },
          {
            id: 25,
            title: '請求書一覧の見方',
            text: `POINT
            ここでは請求書一覧を例に説明していますが、納品書一覧/見積書一覧/報告書一覧も同様の操作になります。
            
            サイドメニューから 請求書一覧 を開きます。
            
            請求書一覧が表示されます。
            
            ① PDFファイルダウンロード
            ダウンロードしたい請求書のチェックボックスをクリックしてから 選択したPDF一括ダウンロードボタン をクリックします。
            選択したファイルはZIP形式でダウンロードされます。
            
            ② 検索
            プルダウンメニューから検索項目を選び、入力エリアに日付またはキーワードを入力した後、検索ボタンをクリックします。
            日付を入力する場合、開始日・終了日の両方を入力してください。いずれか一方の入力では検索することができません。
            
            ③ 並べ替え
            プルダウンメニューから並べ替えの基準となる項目を選択後、昇順または降順のいずれかを選択すると一覧が並べ替えられます。
            
            ④ 表示　※販売側のみの機能
            デフォルトでは 送信済一覧 （削除済み以外の請求書）が選択されています。
            削除済みの請求書を表示したいときは、 削除済一覧 を選択します。
            すべて を選択すると、削除済みを含めたすべての請求書が表示されます。
            
            ⑤ リセット
            検索結果、並び替え、表示をデフォルト状態にリセットします。
            
            ⑥ 請求書確認者/請求書確認日
            仕入側事業所内で最初に請求書を確認したユーザー名と確認日時が表示されます。
            
            ⑦ 削除　※販売側のみの機能
            ゴミ箱アイコンをクリックすると、請求書を登録時に選択した相手先担当者および相手先CCに請求書の削除を通知するメールが送信されます。（通知設定をONにしている場合）
            削除をすると仕入側の請求書一覧からは非表示になり、閲覧することができなくなります。`,
            link: '/support/quotation/6'
          },
          {
            id: 26,
            title: 'リスクアセスメント管理を申請する（仕入側）',
            text: `POINT
            スクアセスメント管理はSDS管理中商品にのみ適用可能です。
            サイドメニューから SDS管理一覧 を開きます。
            リスクアセスメント管理申込 ボタンをクリックします。
            リスクアセスメント管理申込のポップアップが表示されます。 申し込む をクリックします。
            表示が リスクアセスメント管理受付待 に変わります。
            販売側の受付処理が完了すると、表示が リスクアセスメント管理中 に変わり、ボタンがアクティブになります。
            リスクアセスメント管理受付中 ボタンをクリックすると、リスクアセスメント管理詳細画面が表示されます。
            リスクアセスメント管理は一時停止することができます。
            リスト右端のメニューアイコン【】をクリックして、プルダウンメニューから リスクアセスメント管理一時停止 を選択します。
            ※トリコロンメニューでは「管理一時停止」から「管理再開」も可能です。`,
            link: '/support/sds-manual-catalog/4'
          },
          {
            id: 27,
            title: 'リスクアセスメント管理を受け付ける（販売側）',
            text: `POINT
            スクアセスメント管理はSDS管理中商品にのみ適用可能です。
            サイドメニューから SDS管理一覧 を開きます。
            仕入側がリスクアセスメント管理を申込むと、 リスクアセスメント管理受付待 ボタンが表示されます。
            リスクアセスメント管理受付待 ボタンをクリックすると、リスクアセスメント管理受付のポップアップが表示されます。
            受け付ける ボタンをクリックします。
            ボタンが リスクアセスメント管理中 に変わり、アクティブになります。
            リスクアセスメント管理受付中 ボタンをクリックすると、リスクアセスメント管理詳細画面が表示されます。
            仕入側で「管理一時停止」の処理が行われると、 リスクアセスメント管理一時停止 が表示されます。
            仕入側で「再開」の処理が行われると、表示が リスクアセスメント管理受付中 に戻ります。`,
            link: '/support/sds-manual-catalog/5'
          },
          {
            id: 28,
            title: 'リスクアセスメントファイルを管理する（販売側・仕入側）',
            text: `SDS管理一覧から リスクアセスメント管理中 ボタンをクリックします。
            リスクアセスメント管理詳細画面が表示されます。
            各ボタンから管理ファイルをアップロードします
            ①テンプレートファイルアップロード / ②条件記入済みテンプレートファイルアップロード
            仕入側・販売側ともファイルをアップロードできます。
            アップロードできるファイルは1点のみです。
            ③チェック済みファイルアップロード
            仕入側のみファイルをアップロードできます。
            ※ チェック済みファイルアップロード ボタンは仕入側にのみ表示されます。
            POINT
            チェック済みファイルアップロード時に、チェック日を指定します。
            ここで指定した日付がリスクアセスメント管理詳細画面のチェック日欄に表示されます。
            ファイルをダウンロードもしくは削除する場合は、右端のメニューアイコン【】をクリックしてプルダウンメニューから選択します。
            ※チェック済みファイルを削除できるのは購入側のみです。`,
            link: '/support/sds-manual-catalog/6'
          },
        ],
        category: [
          {
            icon: 'cart-arrow-down',
            title: ['発注'],
            link: '/support/order',
            desc: '通常カート </br> お助けカート </br> ※仕入側向け'
          },
          {
            icon: 'list-ul',
            title: ['受注'],
            link: '/support/orderReceived',
            desc: '※販売側向け'
          },
          {
            icon: 'file-text',
            title: ['工事見積書', '請求書', '納品書', '見積書', '報告書'],
            link: '/support/quotation',
            desc: ''
          },
          {
            icon: 'book',
            title: ['SDS', 'リスクアセスメント管理', '取扱説明書', 'カタログ'],
            link: '/support/sds-manual-catalog',
            desc: ''
          },
          {
            icon: 'user',
            title: ['設定'],
            link: '/support/setting',
            desc: '通知メール受信設定'
          },
        ],
        useFulFunction: [
          {
            icon: 'handshake-o',
            title: ['お助けカート'],
            link: '/support/otasuke-cart',
            desc: ''
          },
          {
            icon: 'star',
            title: ['お気に入りセット登録'],
            link: '/support/favorite-set/1',
            desc: ''
          },
          {
            icon: 'truck',
            title: ['納品場所管理'],
            link: '/support/delivery-address-list/1',
            desc: ''
          },
          {
            icon: 'bar-chart',
            title: ['データ分析'],
            link: '/support/analytics',
            desc: ''
          }
        ],
        useFulInformation: [
          // {
          //   icon: 'question-circle',
          //   title: ['困ったときは？'],
          //   desc: 'お問い合わせ'
          // },
          {
            icon: 'exclamation-circle',
            title: ['関連情報'],
            desc: 'アップデート情報',
            link: '/supportUpdate'
          },
        ]
      }
    };

  }

  checkRev () {
    // リビジョンの確認 ※Promiseで返される
    checkRevision()
    .then( result => {
      // 何らかのAPI通信
      // ~~~~~
    },
    err => {
      console.log("リビジョン確認失敗")
    })
  }

  logout = () => {

    firebase.auth().signOut();
    this.setState({
      loading: false,
      user: null,
      login: false,
      userRef: null,
      targetOrgRef: null,
      targetOrgSellerRef: null,
    });
    localStorage.removeItem('targetOrg');
    localStorage.removeItem('targetOrgSellerRef');
  }

  userCheck = (u) => {
    if(this.state.user){
      this.setState({
        loading: true
      });
      var verified = this.state.user.emailVerified;

      let userRef = db.collection('users').doc(this.state.user.uid);
      let getDoc = userRef.get()
        .then(doc => {
          if (!doc.exists) {
            console.log('No such user document!')
            userRef.set({
              lastlogin: new Date(),
            })
            this.logout();
          } else {
            userRef.update({
              lastlogin: new Date(),
            })
            if ( doc.data().active ) {
              let user = doc.data();
              user.id = doc.id;
              this.setState({
                loading: false,
                login: true,
                userRef: user,
              }, () => {
                console.log('userCheck')
                this.orderNotShipped();
                this.orderUnread();
                this.billUnread();
                this.deliveryNotesUnread();
                this.estimates2Unread();
                this.estimatesUnread();
                this.reportUnread();
                this.statusSDS();
                this.otasukeUnread();
              })

              /*
              console.log("u.id",u);
              if(u.uid=='tdAKzhuI3hdvyiUl38AX1BZUw2u1'){
                u.updateEmail('ejiri@hayashi-s.co.jp');
              }
              if(u.uid=='pTFjgTiVwJUJWWvgzD64J06cQai1'){
                u.updateEmail('seizou@hayashi-s.co.jp');
              }
              */

            } else {
              this.logout();
            }
          }
        })
        .catch(err => {
          console.log('Error getting user document', err)
          this.logout();
        })
    }
  }

  orgSelectSeller = (id, name, initial) => {
    // console.log('orgSelectSeller', id, name);
    this.orgSelectSellerData(id, name);

    if (!initial) {
      this.setState({
        targetOrgRef: null,
      });
      localStorage.removeItem('targetOrg');
    }
  }

  orgSelectSellerData = (orgId, orgName) => {
    let orgRef = db.collection('organizations').doc(orgId);
    let getDoc = orgRef.get()
        .then(doc => {
          if (!doc.exists) {
            console.log('No such organizations document!')
          } else {
            if (doc.data().active && doc.data().buyer) {
              let org = doc.data();
              org.id = doc.id;
              org.display_name = orgName;
              this.setState({
                targetOrgSellerRef: org
              });
              localStorage.setItem('targetOrgSeller', JSON.stringify(org))
            }
            if (doc.data().active && doc.data().reseller) {
              let org = doc.data();
              org.id = doc.id;
              org.display_name = orgName;
              this.setState({
                targetOrgSellerRef: org
              });
              localStorage.setItem('targetOrgSeller', JSON.stringify(org));
            }
            if (doc.data().active && doc.data().maker) {
              let org = doc.data();
              org.id = doc.id;
              org.display_name = orgName;
              this.setState({
                targetOrgSellerRef: org
              });
              localStorage.setItem('targetOrgSeller', JSON.stringify(org));
            }
          }
        })
        .catch(err => {
          console.log('Error getting organizations document', err)
        });
  }

  orgSelect = (e) => {
    e.preventDefault();

    const orgId = e.currentTarget.dataset.org;
    const orgName = e.currentTarget.dataset.name;

    this.orgSelectData(orgId,orgName);
    //window.location.href='/'; //無理やり………ダメっぽい

  }

  orgSelectData = (orgId,orgName) => {
    let orgRef = db.collection('organizations').doc(orgId);
    let getDoc = orgRef.get()
    .then(doc => {
      if (!doc.exists) {
        console.log('No such organizations document!')
        this.setState({
          targetOrgRef: null,
        });
        localStorage.removeItem('targetOrg');
      } else {
        if ( doc.data().active && doc.data().buyer) {
          let org = doc.data();
          org.id = doc.id;
          org.display_name = orgName;
          this.setState({
            targetOrgRef: org,
          });
          localStorage.setItem('targetOrg', JSON.stringify(org))
        }
        if ( doc.data().active && doc.data().reseller) {
          let org = doc.data();
          org.id = doc.id;
          org.display_name = orgName;
          this.setState({
            targetOrgRef: org,
          });
          localStorage.setItem('targetOrg', JSON.stringify(org))
        }
        if ( doc.data().active && doc.data().maker) {
          let org = doc.data();
          org.id = doc.id;
          org.display_name = orgName;
          this.setState({
            targetOrgRef: org,
          });
          localStorage.setItem('targetOrg', JSON.stringify(org))
        }
      }
    })
    .catch(err => {
      console.log('Error getting organizations document', err)
    })

  }

  reportUnread = async () => {
    let report = db.collection("report");

    report.where("fromOrganization", "==", this.state.userRef.organization)
        .where("readSeller", "==", false)
        .onSnapshot((snapshot) => {
          let unreadReportSeller = snapshot.size || 0;
          let unreadReportSellerWithOrg = {};
          snapshot.docs.map(doc => {
            let reportData = doc.data();
            if (unreadReportSellerWithOrg[reportData.toOrganization]) {
              unreadReportSellerWithOrg[reportData.toOrganization] += 1;
            } else {
              unreadReportSellerWithOrg[reportData.toOrganization] = 1;
            }
          });

          if (unreadReportSeller !== this.state.unreadReportSeller || JSON.stringify(unreadReportSellerWithOrg) !== JSON.stringify(this.state.unreadReportSellerWithOrg)) {
            this.setState({unreadReportSeller, unreadReportSellerWithOrg});
          }
        });

    report.where("toOrganization", "==", this.state.userRef.organization)
        .where("deleted", "==", false)
        .where("readBuyer", "==", false)
        .onSnapshot((snapshot) => {
          let unreadReportBuyer = snapshot.size || 0;
          let unreadReportBuyerWithOrg = {};
          snapshot.docs.map(doc => {
            let reportData = doc.data();
            if (unreadReportBuyerWithOrg[reportData.fromOrganization]) {
              unreadReportBuyerWithOrg[reportData.fromOrganization] += 1;
            } else {
              unreadReportBuyerWithOrg[reportData.fromOrganization] = 1;
            }
          });

          if (unreadReportBuyer !== this.state.unreadReportBuyer || JSON.stringify(unreadReportBuyerWithOrg) !== JSON.stringify(this.state.unreadReportBuyerWithOrg)) {
            this.setState({unreadReportBuyer, unreadReportBuyerWithOrg});
          }
        });
  }

  estimates2Unread = async () => {
    let estimates2 = db.collection("estimates2");

    estimates2.where("fromOrganization", "==", this.state.userRef.organization)
        .where("readSeller", "==", false)
        .onSnapshot((snapshot) => {
          let unreadEstimates2Seller = snapshot.size || 0;
          let unreadEstimates2SellerWithOrg = {};
          snapshot.docs.map(doc => {
            let estimates2Data = doc.data();
            if (unreadEstimates2SellerWithOrg[estimates2Data.toOrganization]) {
              unreadEstimates2SellerWithOrg[estimates2Data.toOrganization] += 1;
            } else {
              unreadEstimates2SellerWithOrg[estimates2Data.toOrganization] = 1;
            }
          });

          if (unreadEstimates2Seller !== this.state.unreadEstimates2Seller || JSON.stringify(unreadEstimates2SellerWithOrg) !== JSON.stringify(this.state.unreadEstimates2SellerWithOrg)) {
            this.setState({unreadEstimates2Seller, unreadEstimates2SellerWithOrg});
          }
        })

    estimates2.where("toOrganization", "==", this.state.userRef.organization)
        .where("deleted", "==", false)
        .where("readBuyer", "==", false)
        .onSnapshot((snapshot) => {
          let unreadEstimates2Buyer = snapshot.size || 0;
          let unreadEstimates2BuyerWithOrg = {};
          snapshot.docs.map(doc => {
            let estimates2Data = doc.data();
            if (unreadEstimates2BuyerWithOrg[estimates2Data.fromOrganization]) {
              unreadEstimates2BuyerWithOrg[estimates2Data.fromOrganization] += 1;
            } else {
              unreadEstimates2BuyerWithOrg[estimates2Data.fromOrganization] = 1;
            }
          });

          if (unreadEstimates2Buyer !== this.state.unreadEstimates2Buyer || JSON.stringify(unreadEstimates2BuyerWithOrg) !== JSON.stringify(this.state.unreadEstimates2BuyerWithOrg)) {
            this.setState({unreadEstimates2Buyer, unreadEstimates2BuyerWithOrg});
          }
        });
  }

  estimatesUnread = async () => {
    let estimates = db.collection("estimates");

    estimates.where("fromOrganization", "==", this.state.userRef.organization)
        .where("readSeller", "==", false)
        .onSnapshot((snapshot) => {
          let unreadEstimatesSeller = snapshot.size || 0;
          let unreadEstimatesSellerWithOrg = {};
          snapshot.docs.map(doc => {
            let estimatesData = doc.data();
            if (unreadEstimatesSellerWithOrg[estimatesData.toOrganization]) {
              unreadEstimatesSellerWithOrg[estimatesData.toOrganization] += 1;
            } else {
              unreadEstimatesSellerWithOrg[estimatesData.toOrganization] = 1;
            }
          })

          if (unreadEstimatesSeller !== this.state.unreadEstimatesSeller && JSON.stringify(unreadEstimatesSellerWithOrg) !== JSON.stringify(this.state.unreadEstimatesSellerWithOrg)) {
            this.setState({unreadEstimatesSeller, unreadEstimatesSellerWithOrg});
          }
        });

    estimates.where("toOrganization", "==", this.state.userRef.organization)
        .where("delete", "==", false)
        .where("readBuyer", "==", false)
        .onSnapshot((snapshot) => {
          let unreadEstimatesBuyer = snapshot.size || 0;
          let unreadEstimatesBuyerWithOrg = {};
          snapshot.docs.map(doc => {
            let estimatesData = doc.data();
            if (unreadEstimatesBuyerWithOrg[estimatesData.fromOrganization]) {
              unreadEstimatesBuyerWithOrg[estimatesData.fromOrganization] += 1;
            } else {
              unreadEstimatesBuyerWithOrg[estimatesData.fromOrganization] = 1;
            }
          })

          if (unreadEstimatesBuyer !== this.state.unreadEstimatesBuyer && JSON.stringify(unreadEstimatesBuyerWithOrg) !== JSON.stringify(this.state.unreadEstimatesBuyerWithOrg)) {
            this.setState({unreadEstimatesBuyer, unreadEstimatesBuyerWithOrg});
          }
        })
  }

  billUnread = async () => {
    let bills = db.collection("bills");
    bills.where('fromOrganization', '==', this.state.userRef.organization)
        .where('readSeller', '==', false)
        .onSnapshot((snapshot) => {
          let unreadBillSeller = snapshot.size || 0;
          let unreadBillSellerWithOrg = {};
          snapshot.docs.map(doc => {
            let billData = doc.data();
            if (unreadBillSellerWithOrg[billData.toOrganization]) {
              unreadBillSellerWithOrg[billData.toOrganization] += 1;
            } else {
              unreadBillSellerWithOrg[billData.toOrganization] = 1;
            }
          })

          if (unreadBillSeller !== this.state.unreadBillSeller || JSON.stringify(unreadBillSellerWithOrg) !== JSON.stringify(this.state.unreadBillSellerWithOrg)) {
            this.setState({unreadBillSeller: unreadBillSeller, unreadBillSellerWithOrg: unreadBillSellerWithOrg});
          }
        })

    bills.where("toOrganization", "==", this.state.userRef.organization)
        .where("deleted", "==", false)
        .where('readBuyer', '==', false)
        .onSnapshot((snapshot) => {
          let unreadBillBuyer = snapshot.size || 0;
          let unreadBillBuyerWithOrg = {};
          snapshot.docs.map(doc => {
            let billData = doc.data();
            if (unreadBillBuyerWithOrg[billData.fromOrganization]) {
              unreadBillBuyerWithOrg[billData.fromOrganization] += 1;
            } else {
              unreadBillBuyerWithOrg[billData.fromOrganization] = 1;
            }
          })

          if (unreadBillBuyer !== this.state.unreadBillBuyer || JSON.stringify(unreadBillBuyerWithOrg) !== JSON.stringify(this.state.unreadBillBuyerWithOrg)) {
            this.setState({unreadBillBuyer: unreadBillBuyer, unreadBillBuyerWithOrg: unreadBillBuyerWithOrg});
          }
        })
  }

   deliveryNotesUnread = async () => {
    let deliveryNotes = db.collection("delivery_notes");
     deliveryNotes.where('fromOrganization', '==', this.state.userRef.organization)
        .where('readSeller', '==', false)
        .onSnapshot((snapshot) => {
          let unreadDeliveryNotesSeller = snapshot.size || 0;
          let unreadDeliveryNotesSellerWithOrg = {};
          snapshot.docs.map(doc => {
            let deliveryNotesData = doc.data();
            if (unreadDeliveryNotesSellerWithOrg[deliveryNotesData.toOrganization]) {
              unreadDeliveryNotesSellerWithOrg[deliveryNotesData.toOrganization] += 1;
            } else {
              unreadDeliveryNotesSellerWithOrg[deliveryNotesData.toOrganization] = 1;
            }
          })

          if (unreadDeliveryNotesSeller !== this.state.unreadDeliveryNotesSeller || JSON.stringify(unreadDeliveryNotesSellerWithOrg) !== JSON.stringify(this.state.unreadDeliveryNotesSellerWithOrg)) {
            this.setState({unreadDeliveryNotesSeller: unreadDeliveryNotesSeller, unreadDeliveryNotesSellerWithOrg: unreadDeliveryNotesSellerWithOrg});
          }
        })

    deliveryNotes.where("toOrganization", "==", this.state.userRef.organization)
        .where("deleted", "==", false)
        .where('readBuyer', '==', false)
        .onSnapshot((snapshot) => {
          let unreadDeliveryNotesBuyer = snapshot.size || 0;
          let unreadDeliveryNotesBuyerWithOrg = {};
          snapshot.docs.map(doc => {
            let deliveryNotesData = doc.data();
            if (unreadDeliveryNotesBuyerWithOrg[deliveryNotesData.fromOrganization]) {
              unreadDeliveryNotesBuyerWithOrg[deliveryNotesData.fromOrganization] += 1;
            } else {
              unreadDeliveryNotesBuyerWithOrg[deliveryNotesData.fromOrganization] = 1;
            }
          })

          if (unreadDeliveryNotesBuyer !== this.state.unreadDeliveryNotesBuyer || JSON.stringify(unreadDeliveryNotesBuyerWithOrg) !== JSON.stringify(this.state.unreadDeliveryNotesBuyerWithOrg)) {
            this.setState({unreadDeliveryNotesBuyer: unreadDeliveryNotesBuyer, unreadDeliveryNotesBuyerWithOrg: unreadDeliveryNotesBuyerWithOrg});
          }
        })
  }


  orderNotShipped = async () => {}

  otasukeUnread = async () => {
    let otasuke_carts_history = db.collection("otasuke_carts_history");
    otasuke_carts_history
        .where("toOrganization", "==", this.state.userRef.organization)
        .where("sendHelpCart", "==", 1)
        .where("readBuyer", "==", false).onSnapshot((snapshot) => {
          let unreadOCH = snapshot.size || 0;
          let unreadOCHWithOrg = {};
          snapshot.docs.map(doc => {
            let otasukeData = doc.data();
            if (unreadOCHWithOrg[otasukeData.fromOrganization]) {
              unreadOCHWithOrg[otasukeData.fromOrganization] += 1;
            } else {
              unreadOCHWithOrg[otasukeData.fromOrganization] = 1;
            }
          });

          if (unreadOCH !== this.state.unreadOCH && JSON.stringify(unreadOCHWithOrg) !== JSON.stringify(this.state.unreadOCHWithOrg)) {
            this.setState({unreadOCH: unreadOCH, unreadOCHWithOrg: unreadOCHWithOrg})
          }
      });
  }

  orderUnread = async () => {
    // console.log('orderUnread')
    let order = db.collection("orders");
    order.where("applied", "==", true)
        .where("toOrganization", "==", this.state.userRef.organization)
        .where("readSeller", "==", false)
        .onSnapshot((snapshot) => {
          let sCount = snapshot.size || 0;
          let unreadOrderSellerWithOrg = {};
          snapshot.docs.map(doc => {
            let orderData = doc.data();
            if (unreadOrderSellerWithOrg[orderData.fromOrganization]) {
              unreadOrderSellerWithOrg[orderData.fromOrganization] += 1;
            } else {
              unreadOrderSellerWithOrg[orderData.fromOrganization] = 1;
            }
          })
          // console.log('sCount', sCount);

          if (sCount !== this.state.unreadOrderSeller && unreadOrderSellerWithOrg !== this.state.unreadOrderSellerWithOrg) {
            this.setState({unreadOrderSeller: sCount, unreadOrderSellerWithOrg});
          }
        });

    order.where("applied", "==", true)
        .where("fromOrganization", "==", this.state.userRef.organization)
        .where("readBuyer", "==", false)
        .onSnapshot((snapshot) => {
          let bCount = snapshot.size || 0;
          let unreadOrderBuyerWithOrg = {};
          snapshot.docs.map(doc => {
            let orderData = doc.data();
            if (unreadOrderBuyerWithOrg[orderData.toOrganization]) {
              unreadOrderBuyerWithOrg[orderData.toOrganization] += 1;
            } else {
              unreadOrderBuyerWithOrg[orderData.toOrganization] = 1;
            }
          });
          // console.log('bCount',bCount);

          if (bCount !== this.state.unreadOrderBuyer && unreadOrderBuyerWithOrg !== this.state.unreadOrderBuyerWithOrg) {
            this.setState({unreadOrderBuyer: bCount, unreadOrderBuyerWithOrg});
          }
        });

    order.where("applied", "==", true)
        .where("toOrganization", "==", this.state.userRef.organization)
        .where("isShipped", "==", false)
        .onSnapshot((snapshot) => {
          let sCount = snapshot.size || 0;
          let notShippedOrderSellerWithOrg = {};
          snapshot.docs.map(doc => {
            let orderData = doc.data();
            if (notShippedOrderSellerWithOrg[orderData.fromOrganization]) {
              notShippedOrderSellerWithOrg[orderData.fromOrganization] += 1;
            } else {
              notShippedOrderSellerWithOrg[orderData.fromOrganization] = 1;
            }
          })

          if (sCount !== this.state.notShippedOrderSeller && notShippedOrderSellerWithOrg !== this.state.notShippedOrderSellerWithOrg) {
            this.setState({notShippedOrderSeller: sCount, notShippedOrderSellerWithOrg: notShippedOrderSellerWithOrg});
          }
        });

    order.where("applied", "==", true)
        .where("fromOrganization", "==", this.state.userRef.organization)
        .where("isShipped", "==", false)
        .onSnapshot((snapshot) => {
          let bCount = snapshot.size || 0;
          let notShippedOrderBuyerWithOrg = {};
          snapshot.docs.map(doc => {
            let orderData = doc.data();
            if (notShippedOrderBuyerWithOrg[orderData.toOrganization]) {
              notShippedOrderBuyerWithOrg[orderData.toOrganization] += 1;
            } else {
              notShippedOrderBuyerWithOrg[orderData.toOrganization] = 1;
            }
          });

          if (bCount !== this.state.notShippedOrderBuyer && notShippedOrderBuyerWithOrg !== this.state.notShippedOrderBuyerWithOrg) {
            this.setState({notShippedOrderBuyer: bCount, notShippedOrderBuyerWithOrg: notShippedOrderBuyerWithOrg});
          }
        });
  }

  statusSDS = async () => {
    let sds_managements = db.collection("sds_management");

    if (this.state.userRef && this.state.userRef.admin) {
      console.log('sds admin')
      sds_managements.where("status", "==", "waiting")
          .onSnapshot((snapshot) => {
            let sdsWaitingCount = snapshot.size || 0;
            let sdsSellerWaitingCountWithOrg = {};
            let sdsBuyerWaitingCountWithOrg = {};

            snapshot.docs.map(doc => {
              let sdsManagementData = doc.data();
              if (sdsSellerWaitingCountWithOrg[sdsManagementData.fromOrganization]) {
                sdsSellerWaitingCountWithOrg[sdsManagementData.fromOrganization] += 1;
              } else {
                sdsSellerWaitingCountWithOrg[sdsManagementData.fromOrganization] = 1;
              }

              if (sdsBuyerWaitingCountWithOrg[sdsManagementData.toOrganization]) {
                sdsBuyerWaitingCountWithOrg[sdsManagementData.toOrganization] += 1;
              } else {
                sdsBuyerWaitingCountWithOrg[sdsManagementData.toOrganization] = 1;
              }
            })

            console.log('sdsWaitingCount', sdsWaitingCount, sdsSellerWaitingCountWithOrg, sdsBuyerWaitingCountWithOrg);

            if (sdsWaitingCount !== this.state.sdsWaitingCount) {
              this.setState({sdsWaitingCount})
            }

            if (JSON.stringify(sdsSellerWaitingCountWithOrg) !== JSON.stringify(this.state.sdsSellerWaitingCountWithOrg)) {
              this.setState({sdsSellerWaitingCountWithOrg})
            }

            if (JSON.stringify(sdsBuyerWaitingCountWithOrg) !== JSON.stringify(this.state.sdsBuyerWaitingCountWithOrg)) {
              this.setState({sdsBuyerWaitingCountWithOrg})
            }
          })

      sds_managements.where("status", "==", "normal")
          .onSnapshot((snapshot) => {
            let sdsNormalCount = snapshot.size || 0;
            let sdsSellerNormalCountWithOrg = {};
            let sdsBuyerNormalCountWithOrg = {};
            snapshot.docs.map(doc => {
              let sdsManagementData = doc.data();
              if (sdsSellerNormalCountWithOrg[sdsManagementData.fromOrganization]) {
                sdsSellerNormalCountWithOrg[sdsManagementData.fromOrganization] += 1;
              } else {
                sdsSellerNormalCountWithOrg[sdsManagementData.fromOrganization] = 1;
              }

              if (sdsBuyerNormalCountWithOrg[sdsManagementData.toOrganization]) {
                sdsBuyerNormalCountWithOrg[sdsManagementData.toOrganization] += 1;
              } else {
                sdsBuyerNormalCountWithOrg[sdsManagementData.toOrganization] = 1;
              }
            })

            if (sdsNormalCount !== this.state.sdsNormalCount || JSON.stringify(sdsSellerNormalCountWithOrg) !== JSON.stringify(this.state.sdsSellerNormalCountWithOrg)) {
              this.setState({sdsNormalCount, sdsSellerNormalCountWithOrg})
            }

            if (sdsNormalCount !== this.state.sdsNormalCount || JSON.stringify(sdsBuyerNormalCountWithOrg) !== JSON.stringify(this.state.sdsBuyerNormalCountWithOrg)) {
              this.setState({sdsNormalCount, sdsBuyerNormalCountWithOrg})
            }
          })

      sds_managements.where("status", "==", "warning")
          .onSnapshot((snapshot) => {
            let sdsWarningCount = snapshot.size || 0;
            let sdsSellerWarningCountWithOrg = {};
            let sdsBuyerWarningCountWithOrg = {};
            snapshot.docs.map(doc => {
              let sdsManagementData = doc.data();
              if (sdsSellerWarningCountWithOrg[sdsManagementData.fromOrganization]) {
                sdsSellerWarningCountWithOrg[sdsManagementData.fromOrganization] += 1;
              } else {
                sdsSellerWarningCountWithOrg[sdsManagementData.fromOrganization] = 1;
              }

              if (sdsBuyerWarningCountWithOrg[sdsManagementData.toOrganization]) {
                sdsBuyerWarningCountWithOrg[sdsManagementData.toOrganization] += 1;
              } else {
                sdsBuyerWarningCountWithOrg[sdsManagementData.toOrganization] = 1;
              }
            })

            if (sdsWarningCount !== this.state.sdsWarningCount || JSON.stringify(sdsSellerWarningCountWithOrg) !== JSON.stringify(this.state.sdsSellerWarningCountWithOrg)) {
              this.setState({sdsWarningCount, sdsSellerWarningCountWithOrg})
            }

            if (sdsWarningCount !== this.state.sdsWarningCount || JSON.stringify(sdsBuyerWarningCountWithOrg) !== JSON.stringify(this.state.sdsBuyerWarningCountWithOrg)) {
              this.setState({sdsWarningCount, sdsBuyerWarningCountWithOrg})
            }
          })

      sds_managements.where("status", "==", "alert")
          .onSnapshot((snapshot) => {
            let sdsAlertCount = snapshot.size || 0;
            let sdsSellerAlertCountWithOrg = {};
            let sdsBuyerAlertCountWithOrg = {};
            snapshot.docs.map(doc => {
              let sdsManagementData = doc.data();
              if (sdsSellerAlertCountWithOrg[sdsManagementData.fromOrganization]) {
                sdsSellerAlertCountWithOrg[sdsManagementData.fromOrganization] += 1;
              } else {
                sdsSellerAlertCountWithOrg[sdsManagementData.fromOrganization] = 1;
              }

              if (sdsBuyerAlertCountWithOrg[sdsManagementData.toOrganization]) {
                sdsBuyerAlertCountWithOrg[sdsManagementData.toOrganization] += 1;
              } else {
                sdsBuyerAlertCountWithOrg[sdsManagementData.toOrganization] = 1;
              }
            })

            if (sdsAlertCount !== this.state.sdsAlertCount || JSON.stringify(sdsSellerAlertCountWithOrg) !== JSON.stringify(this.state.sdsSellerAlertCountWithOrg)) {
              this.setState({sdsAlertCount, sdsSellerAlertCountWithOrg})
            }

            if (sdsAlertCount !== this.state.sdsAlertCount || JSON.stringify(sdsBuyerAlertCountWithOrg) !== JSON.stringify(this.state.sdsBuyerWarningCountWithOrg)) {
              this.setState({sdsAlertCount, sdsBuyerAlertCountWithOrg})
            }
          })
    } else {
      sds_managements.where("toOrganization", "==", this.state.userRef.organization)
          .where("status", "==", "waiting")
          .onSnapshot((snapshot) => {
            let sdsSellerWaitingCount = snapshot.size || 0;
            let sdsSellerWaitingCountWithOrg = {};
            let sdsBuyerWaitingCountWithOrg = {};
            snapshot.docs.map(doc => {
              let sdsManagementData = doc.data();
              if (sdsSellerWaitingCountWithOrg[sdsManagementData.fromOrganization]) {
                sdsSellerWaitingCountWithOrg[sdsManagementData.fromOrganization] += 1;
              } else {
                sdsSellerWaitingCountWithOrg[sdsManagementData.fromOrganization] = 1;
              }
            })

            if (sdsSellerWaitingCount !== this.state.sdsSellerWaitingCount || JSON.stringify(sdsSellerWaitingCountWithOrg) !== JSON.stringify(this.state.sdsSellerWaitingCountWithOrg)) {
              this.setState({sdsSellerWaitingCount, sdsSellerWaitingCountWithOrg})
            }
          });

      sds_managements.where("fromOrganization", "==", this.state.userRef.organization)
          .where("status", "==", "waiting")
          .onSnapshot((snapshot) => {
            let sdsBuyerWaitingCount = snapshot.size || 0;
            let sdsBuyerWaitingCountWithOrg = {};
            snapshot.docs.map(doc => {
              let sdsManagementData = doc.data();

              if (sdsBuyerWaitingCountWithOrg[sdsManagementData.toOrganization]) {
                sdsBuyerWaitingCountWithOrg[sdsManagementData.toOrganization] += 1;
              } else {
                sdsBuyerWaitingCountWithOrg[sdsManagementData.toOrganization] = 1;
              }
            })

            if (sdsBuyerWaitingCount !== this.state.sdsBuyerWaitingCount || JSON.stringify(sdsBuyerWaitingCountWithOrg) !== JSON.stringify(this.state.sdsBuyerWaitingCountWithOrg)) {
              this.setState({sdsBuyerWaitingCount, sdsBuyerWaitingCountWithOrg})
            }
          })

      sds_managements.where("toOrganization", "==", this.state.userRef.organization)
          .where("status", "==", "normal")
          .onSnapshot((snapshot) => {
            let sdsSellerNormalCount = snapshot.size || 0;
            let sdsSellerNormalCountWithOrg = {};
            snapshot.docs.map(doc => {
              let sdsManagementData = doc.data();
              if (sdsSellerNormalCountWithOrg[sdsManagementData.fromOrganization]) {
                sdsSellerNormalCountWithOrg[sdsManagementData.fromOrganization] += 1;
              } else {
                sdsSellerNormalCountWithOrg[sdsManagementData.fromOrganization] = 1;
              }
            })

            if (sdsSellerNormalCount !== this.state.sdsSellerNormalCount || JSON.stringify(sdsSellerNormalCountWithOrg) !== JSON.stringify(this.state.sdsSellerNormalCountWithOrg)) {
              this.setState({sdsSellerNormalCount, sdsSellerNormalCountWithOrg})
            }
          })

      sds_managements.where("fromOrganization", "==", this.state.userRef.organization)
          .where("status", "==", "normal")
          .onSnapshot((snapshot) => {
            let sdsBuyerNormalCount = snapshot.size || 0;
            let sdsBuyerNormalCountWithOrg = {};
            snapshot.docs.map(doc => {
              let sdsManagementData = doc.data();
              if (sdsBuyerNormalCountWithOrg[sdsManagementData.toOrganization]) {
                sdsBuyerNormalCountWithOrg[sdsManagementData.toOrganization] += 1;
              } else {
                sdsBuyerNormalCountWithOrg[sdsManagementData.toOrganization] = 1;
              }
            })

            if (sdsBuyerNormalCount !== this.state.sdsBuyerNormalCount || JSON.stringify(sdsBuyerNormalCountWithOrg) !== JSON.stringify(this.state.sdsBuyerNormalCountWithOrg)) {
              this.setState({sdsBuyerNormalCount, sdsBuyerNormalCountWithOrg})
            }
          })

      sds_managements.where("toOrganization", "==", this.state.userRef.organization)
          .where("status", "==", "warning")
          .onSnapshot((snapshot) => {
            let sdsSellerWarningCount = snapshot.size || 0;
            let sdsSellerWarningCountWithOrg = {};
            snapshot.docs.map(doc => {
              let sdsManagementData = doc.data();
              if (sdsSellerWarningCountWithOrg[sdsManagementData.fromOrganization]) {
                sdsSellerWarningCountWithOrg[sdsManagementData.fromOrganization] += 1;
              } else {
                sdsSellerWarningCountWithOrg[sdsManagementData.fromOrganization] = 1;
              }
            })

            if (sdsSellerWarningCount !== this.state.sdsSellerWarningCount || JSON.stringify(sdsSellerWarningCountWithOrg) !== JSON.stringify(this.state.sdsSellerWarningCountWithOrg)) {
              this.setState({sdsSellerWarningCount, sdsSellerWarningCountWithOrg})
            }
          })

      sds_managements.where("fromOrganization", "==", this.state.userRef.organization)
          .where("status", "==", "warning")
          .onSnapshot((snapshot) => {
            let sdsBuyerWarningCount = snapshot.size || 0;
            let sdsBuyerWarningCountWithOrg = {};
            snapshot.docs.map(doc => {
              let sdsManagementData = doc.data();
              if (sdsBuyerWarningCountWithOrg[sdsManagementData.toOrganization]) {
                sdsBuyerWarningCountWithOrg[sdsManagementData.toOrganization] += 1;
              } else {
                sdsBuyerWarningCountWithOrg[sdsManagementData.toOrganization] = 1;
              }
            })

            if (sdsBuyerWarningCount !== this.state.sdsBuyerWarningCount || JSON.stringify(sdsBuyerWarningCountWithOrg) !== JSON.stringify(this.state.sdsBuyerWarningCountWithOrg)) {
              this.setState({sdsBuyerWarningCount, sdsBuyerWarningCountWithOrg})
            }
          })

      sds_managements.where("toOrganization", "==", this.state.userRef.organization)
          .where("status", "==", "alert")
          .onSnapshot((snapshot) => {
            let sdsSellerAlertCount = snapshot.size || 0;
            let sdsSellerAlertCountWithOrg = {};
            snapshot.docs.map(doc => {
              let sdsManagementData = doc.data();
              if (sdsSellerAlertCountWithOrg[sdsManagementData.fromOrganization]) {
                sdsSellerAlertCountWithOrg[sdsManagementData.fromOrganization] += 1;
              } else {
                sdsSellerAlertCountWithOrg[sdsManagementData.fromOrganization] = 1;
              }
            })

            if (sdsSellerAlertCount !== this.state.sdsSellerAlertCount || JSON.stringify(sdsSellerAlertCountWithOrg) !== JSON.stringify(this.state.sdsSellerAlertCountWithOrg)) {
              this.setState({sdsSellerAlertCount, sdsSellerAlertCountWithOrg})
            }
          })

      sds_managements.where("fromOrganization", "==", this.state.userRef.organization)
          .where("status", "==", "alert")
          .onSnapshot((snapshot) => {
            let sdsBuyerAlertCount = snapshot.size || 0;
            let sdsBuyerAlertCountWithOrg = {};
            snapshot.docs.map(doc => {
              let sdsManagementData = doc.data();
              if (sdsBuyerAlertCountWithOrg[sdsManagementData.toOrganization]) {
                sdsBuyerAlertCountWithOrg[sdsManagementData.toOrganization] += 1;
              } else {
                sdsBuyerAlertCountWithOrg[sdsManagementData.toOrganization] = 1;
              }
            })

            if (sdsBuyerAlertCount !== this.state.sdsBuyerAlertCount || JSON.stringify(sdsBuyerAlertCountWithOrg) !== JSON.stringify(this.state.sdsBuyerWarningCountWithOrg)) {
              this.setState({sdsBuyerAlertCount, sdsBuyerAlertCountWithOrg})
            }
          })
    }
  }

  sumObjectsByKey = (...objs) => {
    return objs.reduce((a, b) => {
      for (let k in b) {
        if (b.hasOwnProperty(k))
          a[k] = (a[k] || 0) + b[k];
      }
      return a;
    }, {});
  }

  groupByCount = (arr, field) => {
    return arr.reduce((p, c) => {
      let f = c.data();
      let name = f[field];
      if (!p.hasOwnProperty(name)) {
        p[name] = 0;
      }
      p[name]++;
      return p;
    }, {});
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);

    firebase.auth().onAuthStateChanged(user => {
      this.setState({
        loading: false,
        user: user,
        login: false
      });
      this.userCheck(user);
      this.checkRev();
    });

    const targetOrgRef = localStorage.getItem('targetOrg');
    if (targetOrgRef) {
      this.setState({
        targetOrgRef: JSON.parse(targetOrgRef),
      })
    }

    const targetOrgSellerRef = localStorage.getItem('targetOrgSeller');
    if (targetOrgSellerRef) {
      this.setState({
        targetOrgSellerRef: JSON.parse(targetOrgSellerRef)
      })
    }
  }

  setupOrgAndCompany = ( org ) => {
    // console.log('setupOrgAndCompany', org)
    this.setState({
      organization: org
    }, () => {
      // this.billUnread();
    })
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    this.setState({
      windowWidth: window.innerWidth
    });
  }

  shippedOrder = (shippedCount) => {}

  readOtasuke = (readCount) => {}

  readOrder = (readCount) => {}

  readEstimates = (readCount) => {}

  readEstimates2 = (readCount) => {}

  readBill = (readCount) => {}

  readDeliveryNotes = (readCount) => {}

  readReport = (readCount) => {}

  loadBadge = () => {}

  userEmailSetting = (e) => {
    let tempUser = this.state.userRef;
    tempUser.authorizerApproveEmail = e.authorizerApproveEmail;
    tempUser.authorizerRejectEmail = e.authorizerRejectEmail;
    tempUser.shippingEmail = e.shippingEmail;
    tempUser.sellerReceiveEmail = e.sellerReceiveEmail;
    tempUser.receiveAuthorizerEmail = e.receiveAuthorizerEmail;
    tempUser.sellerEstimateApprovalEmail = e.sellerEstimateApprovalEmail;
    tempUser.sellerEstimateRejectedEmail = e.sellerEstimateRejectedEmail;
    tempUser.billRegisterEmail = e.billRegisterEmail;
    tempUser.billDeletedEmail = e.billDeletedEmail;
    tempUser.estimates2RegisterEmail = e.estimates2RegisterEmail;
    tempUser.estimates2DeletedEmail = e.estimates2DeletedEmail;
    tempUser.reportRegisterEmail = e.reportRegisterEmail;
    tempUser.reportDeletedEmail = e.reportDeletedEmail;
    tempUser.estimatesRegisterEmail = e.estimatesRegisterEmail;
    tempUser.estimatesDeletedEmail = e.estimatesDeletedEmail;
    tempUser.deliveryNotesRegisterEmail = e.deliveryNotesRegisterEmail;
    tempUser.deliveryNotesDeletedEmail = e.deliveryNotesDeletedEmail;

    this.setState({userRef: tempUser});
  }

  render() {
    const navStyle = {
      paddingLeft:
        this.state.windowWidth > this.state.breakWidth ? "210px" : "16px"
    };

    const mainStyle = {
      margin: "0 6%",
      paddingTop: "5.5rem",
      paddingLeft:
        this.state.windowWidth > this.state.breakWidth ? "240px" : "0"
    };
    const footStyle = {
      paddingLeft:
        this.state.windowWidth > this.state.breakWidth ? "240px" : "0"
    };

    const specialCaseNavbarStyles = {
      WebkitBoxOrient: "horizontal",
      flexDirection: "row"
    };

    // let sdsWaitingCount = this.state.sdsWaitingCount;
    // let sdsWaitingCountWithOrg = this.state.sdsWaitingCountWithOrg;
    // let sdsNormalCount = this.state.sdsNormalCount;
    // let sdsNormalCountWithOrg = this.state.sdsNormalCountWithOrg;
    // let sdsWarningCount = this.state.sdsWarningCount;
    // let sdsWarningCountWithOrg = this.state.sdsWarningCountWithOrg;
    // let sdsAlertCount = this.state.sdsAlertCount;
    // let sdsAlertCountWithOrg = this.state.sdsAlertCountWithOrg;
    // if (this.state.userRef && !this.state.userRef.admin) {
    let sdsWaitingCount = this.state.sdsSellerWaitingCount + this.state.sdsBuyerWaitingCount;
    let sdsWaitingCountWithOrg = this.sumObjectsByKey(this.state.sdsSellerWaitingCountWithOrg, this.state.sdsBuyerWaitingCountWithOrg);
    let sdsNormalCount = this.state.sdsSellerNormalCount + this.state.sdsBuyerNormalCount;
    let sdsNormalCountWithOrg = this.sumObjectsByKey(this.state.sdsSellerNormalCountWithOrg, this.state.sdsBuyerNormalCountWithOrg);
    let sdsWarningCount = this.state.sdsSellerWarningCount + this.state.sdsBuyerWarningCount;
    let sdsWarningCountWithOrg = this.sumObjectsByKey(this.state.sdsSellerWarningCountWithOrg, this.state.sdsBuyerWarningCountWithOrg);
    let sdsAlertCount = this.state.sdsSellerAlertCount + this.state.sdsBuyerAlertCount;
    let sdsAlertCountWithOrg = this.sumObjectsByKey(this.state.sdsSellerAlertCountWithOrg, this.state.sdsBuyerAlertCountWithOrg);
    // }

    // console.log('sdsWaitingCount', sdsWaitingCount, 'sdsWaitingCountWithOrg', sdsWaitingCountWithOrg)

    if (this.state.loading) return  <div className="d-flex justify-content-center mt-5"><MDBSpinner /></div>;
    return (
      <BrowserRouter>
        <ScrollToTop>
        <RevisionUpMessage />
        <div className="fixed-sn p4cloud-skin">
          {(this.state.user && this.state.login) ?
          (<>
          <P4Header
            user={this.state.userRef}
            org={this.state.targetOrgRef}
            userOrg={this.state.organization}
            orgSeller={this.state.targetOrgSellerRef}
            orgSelectSeller={this.orgSelectSeller}
            orgSelect={this.orgSelect}
            orgSelectData={this.orgSelectData}
            db={db}
            logout={this.logout}
            width={this.state.windowWidth}
            breakWidth={this.state.breakWidth}
            setupOrgAndCompany={this.setupOrgAndCompany}
            unreadOrder={this.state.unreadOrderSeller+this.state.unreadOrderBuyer}
            unreadOrderWithOrg={this.sumObjectsByKey(this.state.unreadOrderSellerWithOrg, this.state.unreadOrderBuyerWithOrg)}
            unreadOCH={this.state.unreadOCH}
            unreadOCHWithOrg={this.state.unreadOCHWithOrg}
            unreadBill={this.state.unreadBillSeller+this.state.unreadBillBuyer}
            unreadBillWithOrg={this.sumObjectsByKey(this.state.unreadBillSellerWithOrg, this.state.unreadBillBuyerWithOrg)}
            unreadDeliveryNotes={this.state.unreadDeliveryNotesSeller+this.state.unreadDeliveryNotesBuyer}
            unreadDeliveryNotesWithOrg={this.sumObjectsByKey(this.state.unreadDeliveryNotesSellerWithOrg, this.state.unreadDeliveryNotesBuyerWithOrg)}
            unreadEstimates2={this.state.unreadEstimates2Seller+this.state.unreadEstimates2Buyer}
            unreadEstimates2WithOrg={this.sumObjectsByKey(this.state.unreadEstimates2SellerWithOrg, this.state.unreadEstimates2BuyerWithOrg)}
            unreadEstimates={this.state.unreadEstimatesSeller+this.state.unreadEstimatesBuyer}
            unreadEstimatesWithOrg={this.sumObjectsByKey(this.state.unreadEstimatesSellerWithOrg, this.state.unreadEstimatesBuyerWithOrg)}
            unreadReport={this.state.unreadReportSeller+this.state.unreadReportBuyer}
            unreadReportWithOrg={this.sumObjectsByKey(this.state.unreadReportSellerWithOrg, this.state.unreadReportBuyerWithOrg)}
            notShippedOrder={this.state.notShippedOrderSeller+this.state.notShippedOrderBuyer}
            notShippedOrderWithOrg={this.sumObjectsByKey(this.state.notShippedOrderSellerWithOrg, this.state.notShippedOrderBuyerWithOrg)}

            sdsSellerWaitingCountWithOrg={this.state.sdsSellerWaitingCountWithOrg}
            sdsBuyerWaitingCountWithOrg={this.state.sdsBuyerWaitingCountWithOrg}

            sdsWaitingCount={sdsWaitingCount}
            sdsWaitingCountWithOrg={sdsWaitingCountWithOrg}
            sdsNormalCount={sdsNormalCount}
            sdsNormalCountWithOrg={sdsNormalCountWithOrg}
            sdsWarningCount={sdsWarningCount}
            sdsWarningCountWithOrg={sdsWarningCountWithOrg}
            sdsAlertCount={sdsAlertCount}
            sdsAlertCountWithOrg={sdsAlertCountWithOrg}
            />
          <main style={mainStyle}>
            <MDBContainer fluid className="mt-5 min-vh-100">
              <Route exact path='/' component={TopPage} />

              <Route exact path='/comapnyinfo' render={() => <CompanyInfo db={db} id={this.state.organization ? this.state.organization.id : '' } mode="comapnyinfo" />} />
              <Route exact path='/sellerinfo' render={() => <CompanyInfo db={db} id={this.state.organization ? this.state.organization.seller : ''} mode="sellerinfo" />} />
              <Route exact path='/buyerinfo' render={() => <CompanyInfo db={db} data={this.state.targetOrgRef} mode="buyerinfo" />} />
              <Route exact path='/makerinfo' render={() => <CompanyInfo db={db} data={this.state.targetOrgRef} mode="makerinfo" />} />

              <Route exact path='/order' forceRefresh={true} render={(props) => <OrderTableList db={db} storage={storage} cProps={props} org={this.state.targetOrgRef} user={this.state.userRef} userOrg={this.state.organization} readOrder={(readCount) => this.readOrder(readCount)} loadBadge={() => this.loadBadge()} dateNow={firebase.firestore.Timestamp.now().toDate().getTime()} />} />
              <Route exact path='/order1' forceRefresh={true} render={(props) => <OrderTableListV2 db={db} storage={storage} cProps={props} org={this.state.targetOrgRef} user={this.state.userRef} userOrg={this.state.organization} readOrder={(readCount) => this.readOrder(readCount)} loadBadge={() => this.loadBadge()} dateNow={firebase.firestore.Timestamp.now().toDate().getTime()} />} />
              <Route exact path='/order/:id' render={(props) => <Order db={db} storage={storage} id={props.match.params.id} user={this.state.userRef} org={this.state.targetOrgRef} userOrg={this.state.organization} readOrder={(readCount) => this.readOrder(readCount)} shippedOrder={(shippedCount) => this.shippedOrder(shippedCount)} dateNow={firebase.firestore.Timestamp.now().toDate().getTime()} />} />

              <Route exact path='/estimates/new' render={() => <Estimates db={db} storage={storage} org={this.state.targetOrgRef} user={this.state.userRef} userOrg={this.state.organization} mode="new" />} />
              <Route exact path='/estimates' forceRefresh={true} render={() => <EstimatesTableList db={db} storage={storage} org={this.state.targetOrgRef} user={this.state.userRef} userOrg={this.state.organization} readEstimates={(readCount) => this.readEstimates(readCount)} loadBadge={() => this.loadBadge()} />} />

              <Route exact path='/estimates2/new' render={() => <Estimates2 db={db} storage={storage} org={this.state.targetOrgRef} user={this.state.userRef} userOrg={this.state.organization} mode="new" />} />
              <Route exact path='/estimates2' forceRefresh={true} render={() => <Estimates2TableList db={db} storage={storage} org={this.state.targetOrgRef} user={this.state.userRef} userOrg={this.state.organization} readEstimates2={(readCount) => this.readEstimates2(readCount)} loadBadge={() => this.loadBadge()} />} />

              <Route exact path='/bills/new' render={() => <Bills db={db} storage={storage} org={this.state.targetOrgRef} user={this.state.userRef} userOrg={this.state.organization} mode="new" />} />
              <Route exact path='/bills' forceRefresh={true} render={() => <BillsTableList db={db} storage={storage} org={this.state.targetOrgRef} user={this.state.userRef} userOrg={this.state.organization} readBill={(readCount) => this.readBill(readCount)} loadBadge={() => this.loadBadge()} />} />

              <Route exact path='/deliveryNotes/new' render={() => <DeliveryNotes db={db} storage={storage} org={this.state.targetOrgRef} user={this.state.userRef} userOrg={this.state.organization} mode="new" />} />
              <Route exact path='/deliveryNotes' forceRefresh={true} render={() => <DeliveryNotesTableList db={db} storage={storage} org={this.state.targetOrgRef} user={this.state.userRef} userOrg={this.state.organization} readDeliveryNotes={(readCount) => this.readDeliveryNotes(readCount)} loadBadge={() => this.loadBadge()} />} />


              <Route exact path='/sds/new' render={() => <SDS db={db} storage={storage} org={this.state.targetOrgRef} user={this.state.userRef} userOrg={this.state.organization} mode="new" />} />
              <Route exact path='/sds' render={() => <SDSTableList db={db} storage={storage} org={this.state.targetOrgRef} user={this.state.userRef} userOrg={this.state.organization} />} />

              <Route exact path='/catalog/new' render={() => <Catalog db={db} storage={storage} org={this.state.targetOrgRef} user={this.state.userRef} userOrg={this.state.organization} mode="new" />} />
              <Route exact path='/catalog' render={() => <CatalogTableList db={db} storage={storage} org={this.state.targetOrgRef} user={this.state.userRef} userOrg={this.state.organization} />} />

              <Route exact path='/manual/new' render={() => <Manual db={db} storage={storage} org={this.state.targetOrgRef} user={this.state.userRef} userOrg={this.state.organization} mode="new" />} />
              <Route exact path='/manual' render={() => <ManualTableList db={db} storage={storage} org={this.state.targetOrgRef} user={this.state.userRef} userOrg={this.state.organization} />} />

              <Route exact path='/report/new' render={() => <Report db={db} storage={storage} org={this.state.targetOrgRef} user={this.state.userRef} userOrg={this.state.organization} mode="new" />} />
              <Route exact path='/report' forceRefresh={true} render={() => <ReportTableList db={db} storage={storage} org={this.state.targetOrgRef} user={this.state.userRef} userOrg={this.state.organization} readReport={(readCount) => this.readReport(readCount)} loadBadge={() => this.loadBadge()} />} />
              <Route exact path='/report1' forceRefresh={true} render={() => <ReportTableListV2 db={db} storage={storage} org={this.state.targetOrgRef} user={this.state.userRef} userOrg={this.state.organization} readReport={(readCount) => this.readReport(readCount)} loadBadge={() => this.loadBadge()} />} />

              <Route exact path='/favorite/cart' render={(props) => <Favorite db={db} storage={storage} cProps={props} org={this.state.targetOrgRef} user={this.state.userRef} userOrg={this.state.organization} dateNow={firebase.firestore.Timestamp.now().toDate().getTime()} />} />
              <Route exact path='/favorite/cart/:id' render={(props) => <FavoriteCart db={db} storage={storage} cProps={props} id={props.match.params.id} user={this.state.userRef} org={this.state.targetOrgRef} userOrg={this.state.organization} dateNow={firebase.firestore.Timestamp.now().toDate().getTime()} />} />

              <Route exact path='/items' render={(props) => <Items db={db} storage={storage} search={props.location.search} cProps={props} user={this.state.userRef} org={this.state.targetOrgRef} userOrg={this.state.organization} dateNow={firebase.firestore.Timestamp.now().toDate().getTime()} loadBadge={() => this.loadBadge()} />} />
              <Route exact path='/items1' render={(props) => <ItemsV2 db={db} storage={storage} search={props.location.search} cProps={props} user={this.state.userRef} org={this.state.targetOrgRef} userOrg={this.state.organization} dateNow={firebase.firestore.Timestamp.now().toDate().getTime()} loadBadge={() => this.loadBadge()} />} />
              <Route exact path='/items/:id' render={(props) => <Item db={db} storage={storage} id={props.match.params.id} user={this.state.userRef} org={this.state.targetOrgRef} userOrg={this.state.organization} dateNow={firebase.firestore.Timestamp.now().toDate().getTime()} />} />
              <Route exact path='/cart/:id' render={(props) => <Cart db={db} storage={storage} id={props.match.params.id} user={this.state.userRef} org={this.state.targetOrgRef} userOrg={this.state.organization} dateNow={firebase.firestore.Timestamp.now().toDate().getTime()} />} />
              <Route exact path='/otasuke/cart/:id' render={(props) => <OtasukeCart db={db} storage={storage} cProps={props} id={props.match.params.id} user={this.state.userRef} org={this.state.targetOrgRef} userOrg={this.state.organization} dateNow={firebase.firestore.Timestamp.now().toDate().getTime()} />} />
              <Route exact path='/otasuke/carts' render={() => <OtasukeCartList db={db} storage={storage} org={this.state.targetOrgRef} user={this.state.userRef} userOrg={this.state.organization} dateNow={firebase.firestore.Timestamp.now().toDate().getTime()} />} />
              <Route exact path='/otasuke/history/carts' render={(props) => <OtasukeCartHistoryList db={db} storage={storage} cProps={props} org={this.state.targetOrgRef} user={this.state.userRef} userOrg={this.state.organization} dateNow={firebase.firestore.Timestamp.now().toDate().getTime()} readOtasuke={(readCount) => this.readOtasuke(readCount)} loadBadge={() => this.loadBadge()} />} />
              <Route exact path='/otasuke/cart/detail/:id' render={(props) => <OtasukeCartDetail db={db} storage={storage} cProps={props} id={props.match.params.id} user={this.state.userRef} org={this.state.targetOrgRef} userOrg={this.state.organization} dateNow={firebase.firestore.Timestamp.now().toDate().getTime()} />} />

              <Route exact path='/p4bills/new' render={() => <P4Bills db={db} storage={storage} org={this.state.targetOrgRef} user={this.state.userRef} userOrg={this.state.organization} mode="new" />} />
              <Route exact path='/p4bills' render={() => <P4BillsTableList db={db} storage={storage} org={this.state.targetOrgRef} user={this.state.userRef} userOrg={this.state.organization} />} />

              <Route exact path={'/emailsettings'} render={() => <EmailSettings db={db} storage={storage} org={this.state.targetOrgRef} user={this.state.userRef} userOrg={this.state.organization} userUID={this.state.user.uid} userEmailSetting={this.userEmailSetting} />} />
              <Route exact path={'/deliveryAddress'} render={(props) => <DeliveryAddressList db={db} storage={storage} org={this.state.targetOrgRef} search={props.location.search} cProps={props} user={this.state.userRef} userOrg={this.state.organization}/>}/>
              <Route exact path={'/deliveryAddressAdd'} render={(props) => <DeliveryAddressAdd db={db} storage={storage} org={this.state.targetOrgRef} search={props.location.search} cProps={props} user={this.state.userRef} userOrg={this.state.organization}/>}/>
              <Route exact path={'/deliveryAddressEdit/:id'} render={(props) => <DeliveryAddressAdd db={db} storage={storage} id={props.match.params.id} org={this.state.targetOrgRef} search={props.location.search} cProps={props} user={this.state.userRef} userOrg={this.state.organization}/>}/>
              <Route exact path={'/deliveryAddress/:id'} render={(props) => <DeliveryAddress db={db} storage={storage} id={props.match.params.id} search={props.location.search} cProps={props} org={this.state.targetOrgRef} user={this.state.userRef} userOrg={this.state.organization}/>}/>
              <Route exact path={'/statistic'} render={(props) => <Statistic db={db} storage={storage} cProps={props} orgSeller={this.state.targetOrgSellerRef} org={this.state.targetOrgRef} user={this.state.userRef} userOrg={this.state.organization}/>}/>

              <Route exact path={'/support'} render={(props) => <Support db={db} storage={storage} data={this.state.support} cProps={props} org={this.state.targetOrgRef} user={this.state.userRef} userOrg={this.state.organization} />} />
              <Route exact path={'/support/:category'} render={(props) => <SupportCategory db={db} storage={storage} data={this.state.support} category={props.match.params.category} org={this.state.targetOrgRef} user={this.state.userRef} userOrg={this.state.organization}/>} />
              <Route exact path={'/support/:category/:id'} render={(props) => <SupportDetail db={db} storage={storage} data={this.state.support} category={props.match.params.category} id={props.match.params.id} org={this.state.targetOrgRef} user={this.state.userRef} userOrg={this.state.organization}/>} />
              <Route exact path={'/supportFilter/:search'} render={(props) => <SupportFilter db={db} storage={storage} data={this.state.support} search={props.match.params.search} cProps={props} org={this.state.targetOrgRef} user={this.state.userRef} userOrg={this.state.organization}/>} />
              <Route exact path={'/supportUpdate'} render={(props) => <SupportUpdate db={db} storage={storage} data={this.state.support} search={props.match.params.search} cProps={props} org={this.state.targetOrgRef} user={this.state.userRef} userOrg={this.state.organization}/>} />
              <Route exact path={'/supportUpdate/:id'} render={(props) => <SupportUpdateDetail db={db} storage={storage} data={this.state.support} search={props.match.params.search} id={props.match.params.id} cProps={props} org={this.state.targetOrgRef} user={this.state.userRef} userOrg={this.state.organization}/>} />
              <Route exact path={'/supportUpdateGroup/:id'} render={(props) => <SupportUpdateGroup db={db} storage={storage} data={this.state.support} search={props.match.params.search} id={props.match.params.id} cProps={props} org={this.state.targetOrgRef} user={this.state.userRef} userOrg={this.state.organization}/>} />
              <Route exact path={'/supportUpdateGroupYear/:id'} render={(props) => <SupportUpdateGroupYear db={db} storage={storage} data={this.state.support} search={props.match.params.search} id={props.match.params.id} cProps={props} org={this.state.targetOrgRef} user={this.state.userRef} userOrg={this.state.organization}/>} />

              <Route exact path={'/sdsManagement'} render={(props) => <SDSManagementTableList db={db} storage={storage} cProps={props} orgSeller={this.state.targetOrgSellerRef} org={this.state.targetOrgRef} user={this.state.userRef} userOrg={this.state.organization} loadBadge={() => this.loadBadge()} />} />
              <Route exact path={'/sdsManagementHistory/:id'} render={(props) => <SDSManagementHistory db={db} storage={storage} cProps={props} id={props.match.params.id} orgSeller={this.state.targetOrgSellerRef} org={this.state.targetOrgRef} user={this.state.userRef} userOrg={this.state.organization} />} />
              <Route exact path={'/sdsManagementDate/:id'} render={(props) => <SDSManagementDate db={db} storage={storage} cProps={props} id={props.match.params.id} orgSeller={this.state.targetOrgSellerRef} org={this.state.targetOrgRef} user={this.state.userRef} userOrg={this.state.organization} />}/>
              <Route exact path={'/sdsManagementRisk/:id'} render={(props) => <SDSManagementRisk db={db} storage={storage} cProps={props} id={props.match.params.id} orgSeller={this.state.targetOrgSellerRef} org={this.state.targetOrgRef} user={this.state.userRef} userOrg={this.state.organization} />}/>

              <Route exact path={'/measurement'} render={(props) => <Measurement db={db} storage={storage} cProps={props} orgSeller={this.state.targetOrgSellerRef} org={this.state.targetOrgRef} user={this.state.userRef} userOrg={this.state.organization}/>}/>
              <Route exact path={'/measurement/new'} render={(props) => <CreateNewMeasurement db={db} storage={storage} cProps={props} orgSeller={this.state.targetOrgSellerRef} org={this.state.targetOrgRef} user={this.state.userRef} userOrg={this.state.organization} edit = {false}/>}/>
              <Route exact path={'/measurement/edit/:id'} render={(props) => <CreateNewMeasurement db={db} storage={storage} cProps={props} orgSeller={this.state.targetOrgSellerRef} org={this.state.targetOrgRef} user={this.state.userRef} userOrg={this.state.organization} edit = {true}/>}/>
              <Route exact path={'/measurement/preview/:id'} render={(props) => <PreviewMeasurement db={db} storage={storage} cProps={props} orgSeller={this.state.targetOrgSellerRef} org={this.state.targetOrgRef} user={this.state.userRef} userOrg={this.state.organization} />}/>
              <Route exact path={'/measurement/logs/:measurementId/:nestIndex/:itemId/:index/:itemName'} render={(props) => <MeasurementLogs db={db} storage={storage} cProps={props} orgSeller={this.state.targetOrgSellerRef} org={this.state.targetOrgRef} user={this.state.userRef} userOrg={this.state.organization} />}/>

              <ToastContainer
                  hideProgressBar={true}
                  newestOnTop={true}
                  autoClose={5000}
              />
            </MDBContainer>
          </main>
          <P4Footer style={footStyle} />
          </>)
          :
          <SignInScreen />
          }
        </div>
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}

export default App;
